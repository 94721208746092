import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import UserList from "../User";
import Statistics from "./statistics";
import DonutChart from "./donut_chart";
import Dot from "@material-ui/icons/FiberManualRecordSharp";
import RawStats from "./raw_stats";
import AutoManualStats from "./auto_manual_stats";

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: '6%',
        width: '95%',
        margin: '0 auto',
        paddingBottom: '10%'
    },
    pageCount: {
        fontSize: '40px',
        fontWeight: 'bolder'
    }
}))

function Dashboard(props) {

    const classes = useStyles();
    const [pages, setPages] = useState(0);
    const [refresh, setRefresh] = useState(0);

    useEffect(() => {
        const stats = JSON.parse(sessionStorage.getItem('stats'));
        if (stats?.[3]?.count) {
            setPages(+stats?.[3]?.pages);
        }
    }, [refresh])
    const userRole = JSON.parse(sessionStorage.getItem('user'))?.role;
    return (
        <div className={classes.root}>
            <Grid container alignItems={'center'} justify={'center'} spacing={4}>
                <Grid item md={12}>
                    {userRole === 'SUPERADMIN' && <Statistics setRefresh={setRefresh}/>}
                </Grid>
                <Grid item md={7}>
                    <UserList history={props.history}/>
                </Grid>
                <Grid item md={5} style={{height: '100%'}}>
                    <DonutChart refresh={refresh}/>
                    <div className={"center"} style={{marginTop: '15px'}}>
                        <div className={"center"}><Dot className={"positive"}/>&nbsp;Processed</div>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <div className={"center"}><Dot className={"negative"}/>&nbsp;Rejected</div>
                    </div>
                </Grid>
                <Grid item md={12} style={{textAlign: 'center'}}>
                    {userRole === 'SUPERADMIN' && <RawStats/>}
                </Grid>
                <Grid item md={12}>
                    {userRole === 'SUPERADMIN' && <AutoManualStats/>}
                </Grid>
            </Grid>
        </div>
    );
}

export default Dashboard
