import React, {useContext} from "react";
import {TransactionFilterContext} from "../../../../lib/contexts/transaction_filter_context";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import {unique} from "../../../../lib/utils/common_utils";

function AccountNumberFilter(props) {
    const {categoryData} = props;
    const accountNumberInfo = categoryData?.account_number_info;

    const TxnData = useContext(TransactionFilterContext);

    let filterOptions = [{label: "All", value: "All", type: "ALL"}];
    if (accountNumberInfo?.FULL?.length > 0) {
        const checkingAccounts = accountNumberInfo?.FULL?.map((ac) => {
                const prefix = ac?.account_type === "CHECKING" ? `${ac?.account_class}:` : "";
                if(ac?.account_class?.toUpperCase() !== "EXCLUDE"){
                    return {
                        "label": `${prefix} ${ac?.captured_account_number}`,
                        "value": ac?.account_number,
                        "type": ac?.account_type
                    }
                }
            }
        )
        filterOptions = filterOptions.concat(unique(checkingAccounts));
    }

    const accountTypeMap = ["ALL", "CHECKING", "SAVINGS"];

    return (
        <div style={{marginTop: '10px'}}>
            <Typography variant="subtitle2" color="textSecondary" gutterBottom>Account Number: &nbsp;</Typography>
            {filterOptions?.map((op, i) => (
                <Chip
                    variant={(TxnData?.filters?.account_number === null && accountTypeMap[TxnData?.filters?.account_type] === op.type)? "default" : (TxnData?.filters?.account_number === op.value ? "default" : "outlined")}
                    key={op.label}
                    size="small"
                    disabled={i !== 0 && accountTypeMap[TxnData?.filters?.account_type] !== op.type && TxnData?.filters?.account_type !== 0}
                    style={{marginRight: '5px', marginBottom: '12px'}}
                    onClick={() => TxnData?.setFilters({...TxnData.filters, account_number: op.value})}
                    clickable
                    color={(TxnData?.filters?.account_number === null && accountTypeMap[TxnData?.filters?.account_type] === op.type)? "primary" : (TxnData?.filters?.account_number === op.value ? "primary" : "default")}
                    label={op.label}
                />
            ))
            }
        < /div>
    );
}

export default AccountNumberFilter;
