import React, {useContext, useEffect} from "react";
import {TransactionFilterContext} from "../../../../lib/contexts/transaction_filter_context";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";

function AccountTypeFilter(props) {
    const {categoryData} = props;
    const TxnData = useContext(TransactionFilterContext);

    const filterOptions = ["All", "Checking", "Savings"];

    useEffect(() => {
        if (categoryData?.flag_ac_type) {
            const accType = (categoryData?.flag_ac_type === "SAVINGS" || categoryData?.flag_ac_type === "savings") ? 1 : ((categoryData.flag_ac_type === 'CHECKING' || categoryData.flag_ac_type === 'checking') ? 2 : 0);
            TxnData.setFilters({...TxnData.filters, account_type: accType});
        }
    }, [categoryData?.flag_ac_type])


    return (
        <div style={{marginTop: '10px'}}>
            <Typography variant="subtitle2" color="textSecondary" gutterBottom>Account Type: &nbsp;</Typography>
            {filterOptions?.map((op, i) => (
                <Chip
                    variant={TxnData?.filters?.account_type === i ? "default" : "outlined"}
                    key={op}
                    size="small"
                    style={{marginRight: '5px'}}
                    onClick={() => TxnData?.setFilters({...TxnData.filters, account_type: i})}
                    clickable
                    color={TxnData?.filters?.account_type === i ? "primary" : "default"}
                    label={op}
                />
            ))
            }
        < /div>
    );
}

export default AccountTypeFilter;
