import React, {useContext, useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import LockIcon from "@material-ui/icons/LockOutlined";
import PasswordVisible from "@material-ui/icons/Visibility";
import PasswordHide from "@material-ui/icons/VisibilityOff";
import CircularProgress from "@material-ui/core/CircularProgress";
import useAuthStyles from "./auth_styles";
import AuthClient from "../../lib/services/api/auth";
import {ToastMessageContext} from "../../lib/contexts/message_context";

function ChangePasswordDialog(props) {

    const classes = useAuthStyles();
    const [password, handlePasswordChange] = useState();
    const [passwordConfirmation, handleConfirmPassword] = useState();
    const [message, setMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [mask,setMask] = useState(true);

    const Toast = useContext(ToastMessageContext);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (password !== passwordConfirmation) {
            setError(true);
        } else {
            setLoading(true);
            const formData = new FormData(e.target);
            const newFormData = new FormData();
            newFormData.append("current_password",formData.get("current_password")?.trim())
            newFormData.append("new_password",formData.get("new_password")?.trim());
            AuthClient.updatePassword(newFormData).then((res) => {
                setLoading(false);
                Toast.showSuccess("Password changed successfully");
                props.onClose();
            }).catch((e) => {
                setLoading(false);
                setMessage(e.message || "Something went wrong. Please try again!");
            })
        }
    };


    return <Dialog
        maxWidth="xs"
        fullWidth
        open={props.open}
        onClose={props.onClose}
    >
        <DialogContent style={{padding: "24px 32px"}}>
            <Typography variant={"subtitle2"} style={{fontSize: "16px"}} gutterBottom>
                Change Password
            </Typography>
            <form className={classes.form} onSubmit={handleSubmit} autoComplete="custom-form">
                <FormControl fullWidth className={classes.formField}>
                    <OutlinedInput
                        required
                        name="current_password"
                        type={mask?"password":"text"}
                        autoComplete="new-password"
                        placeholder="Current Password"
                        autoFocus
                        startAdornment={<InputAdornment position="start">
                            <LockIcon/>
                        </InputAdornment>
                        }
                        endAdornment={
                            <InputAdornment position="end">
                                {!mask ? <PasswordVisible className={"clickable"} onClick={()=>setMask(!mask)}/> :
                                    <PasswordHide className={"clickable"} onClick={()=>setMask(!mask)}/>}
                            </InputAdornment>
                        }
                    />
                </FormControl>
                <FormControl fullWidth className={classes.formField}>
                    <OutlinedInput
                        required
                        name="new_password"
                        type={mask?"password":"text"}
                        autoComplete="new-password"
                        placeholder="New Password"
                        onChange={(e) => {
                            handlePasswordChange(e.target.value)
                        }}
                        startAdornment={<InputAdornment position="start">
                            <LockIcon/>
                        </InputAdornment>
                        }
                        endAdornment={
                            <InputAdornment position="end">
                                {!mask ? <PasswordVisible className={"clickable"} onClick={()=>setMask(!mask)}/> :
                                    <PasswordHide className={"clickable"} onClick={()=>setMask(!mask)}/>}
                            </InputAdornment>
                        }
                    />
                </FormControl>
                <FormControl fullWidth className={classes.formField}>
                    <OutlinedInput
                        required
                        name="password_confirmation"
                        autoComplete="new-password"
                        placeholder="Password confirmation"
                        type={mask?"password":"text"}
                        id="password-confirmation"
                        error={error}
                        helperText={error && "Passwords do not match"}
                        onChange={(e) => {
                            handleConfirmPassword(e.target.value)
                        }}
                        startAdornment={<InputAdornment position="start">
                            <LockIcon/>
                        </InputAdornment>
                        }
                    />
                </FormControl>
                <div style={{margin: '20px 10px',textAlign:'center'}} className={"negative"}>
                    {message}
                </div>
                <div style={{margin: '20px 0'}}>
                    <Button
                        type="submit"
                        className={classes.submit}
                        disabled={loading}
                        fullWidth
                        color={"primary"}
                        variant={"contained"}
                        startIcon={loading && <CircularProgress size={15}/>}
                    >
                        Change Password
                    </Button>
                </div>
            </form>
        </DialogContent>
    </Dialog>;
}

export default ChangePasswordDialog;
