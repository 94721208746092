import request from "./common/api_client";

function getCustomerDetail(batchId) {
    return request({
        url: `/batch/${batchId}/analysis/customer-details`,
        method: 'GET',
    });
}

function getAccountWiseCustomerData(batchId) {
    return request({
        url: `/batch/${batchId}/get_customer_data`,
        method: 'GET'
    })
}

function updateAccountWiseCustomerData(batchId, formData) {
    return request({
        url: `/batch/${batchId}/update_customer_data`,
        method: 'PUT',
        data: formData
    });
}

function getAnalysis(batchId) {
    return request({
        url: `/batch/${batchId}/analysis/general-analysis`,
        method: 'GET'
    });
}

function getFullAnalysis(batchId) {
    return request({
        url: `/batch/${batchId}/analysis/data`,
        method: 'GET'
    });
}

function getTransactionList(batchId) {
    return request({
        url: `/batch/${batchId}/analysis/transactions`,
        method: 'GET'
    });
}

function getBatchAnalysisMeta(batchId) {
    return request({
        url: `/batch/${batchId}/analysis/batch-meta`,
        method: 'GET'
    });
}

function updateAnalysis(batchId, formData) {
    return request({
        url: `/batch/${batchId}/analysis/general-analysis`,
        method: 'POST',
        data: formData,
    });
}

function updateTransactions(batchId, formData) {
    return request({
        url: `/batch/${batchId}/analysis/transactions`,
        method: 'POST',
        data: formData,
    });
}

function getGraphData(batchId) {
    return request({
        url: `/batch/${batchId}/analysis/graph-data`,
        method: 'GET'
    });
}

function getResultFileURL(batchId, params) {
    return request({
        url: `/batch/${batchId}/analysis/download`,
        method: 'GET',
        params: params
    })
}

function renameCategories(batchId, formData) {
    return request({
        url: `/batch/${batchId}/analysis/transactions`,
        method: 'POST',
        data: formData
    })
}

function getActivityStream(batchId) {
    return request({
        url: `/batch/${batchId}/analysis/logs`,
        method: 'GET'
    })
}

function saveAnalysisNotes(batchId, formData) {
    return request({
        url: `/batch/${batchId}/analysis/update-batch-comments`,
        method: 'PUT',
        data: formData
    })
}

function updateThresholdValues(batchId, formData) {
    return request({
        url: `/batch/${batchId}/analysis/general-analysis`,
        method: 'PATCH',
        data: formData,
    });
}

function getRiskSummary(batchId) {
    return request({
        url: `/batch/${batchId}/risk-summary`,
        method: 'GET'
    })
}

function getRiskHighlightedFile(batchId, formData) {
    return request({
        url: `/batch/${batchId}/view-risk-file`,
        method: 'POST',
        data: formData
    })
}

function getQuickAnalysis(batchId) {
    return request({
        url: `/batch/${batchId}/quick-analysis`,
        method: 'GET',
    })
}

const AnalyticsClient = {
    getCustomerDetail,
    getAnalysis,
    getTransactionList,
    getBatchAnalysisMeta,
    updateAnalysis,
    updateTransactions,
    getGraphData,
    getResultFileURL,
    renameCategories,
    getActivityStream,
    saveAnalysisNotes,
    getFullAnalysis,
    updateThresholdValues,
    getRiskSummary,
    getRiskHighlightedFile,
    getQuickAnalysis,
    getAccountWiseCustomerData,
    updateAccountWiseCustomerData
}

export default AnalyticsClient;
