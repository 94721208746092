import React from 'react';
import Typography from "@material-ui/core/Typography/Typography";
import Grid from "@material-ui/core/Grid";
import WarningIcon from "@material-ui/icons/Warning";
import RequestManualProcessing from "../../Batches/request_manual_processing";

function PartialAnalysisWarning(props) {
    const {batchId, failedFiles, totalFiles, manualFiles, setRefresh, status, canRequestManualFiles} = props;

    return (
        failedFiles?.length > 0 && <Grid container alignItems={"center"} justify={"space-between"}
              style={{backgroundColor: 'rgb(255, 244, 229, 0.75)', width: '100%', margin: 'auto', padding: '1% 2%'}}>
            <Grid item md={12}>
                <Typography component="div" variant="subtitle2" className="error"
                            style={{display: 'flex', alignItems: 'center'}}>
                    <span><WarningIcon htmlColor="orange"/></span>
                    &nbsp;
                    Warning. Not all files processed.</Typography>
            </Grid>
            <Grid item md={12}>
                <Grid container alignItems="center" justify={"space-between"}>
                    <Grid item md={9}>
                        {failedFiles?.length > 0 && (<Typography variant="body1" component={"div"} gutterBottom>
                            <b>Total: {totalFiles};&nbsp;&nbsp;&nbsp;
                                Rejected Files: {failedFiles?.length}</b> &nbsp; : List of files Rejected -
                            {failedFiles.map((file, i) => (
                                <Typography key={file.name} component="div" variant="body2" style={{
                                    fontStyle: 'italic',
                                    fontWeight: 'bold'
                                }}>
                                    {`${i + 1}. ${file.name}`}
                                </Typography>))}
                        </Typography>)}
                        {manualFiles?.length > 0 && (<Typography variant="body1" component={"div"} gutterBottom>
                            <b>Total: {totalFiles};&nbsp;&nbsp;&nbsp;
                                Awaiting Manual : {manualFiles?.length}</b> &nbsp; : List of files pending processing -
                            <Typography component="span" variant="body2" style={{
                                fontStyle: 'italic',
                                fontWeight: 'bold'
                            }}> {manualFiles.map((file, i) => `${i + 1}. ${file.name}`)?.join(", ")}</Typography>
                        </Typography>)}
                        <Typography variant="subtitle2" className="negative" component="div">
                            Caution: Any changes made will reset once all files are processed and analysis is
                            regenerated.
                        </Typography>
                    </Grid>
                    <Grid item md={3} style={{textAlign: "center"}}>
                        {canRequestManualFiles?.length > 0 &&
                        <RequestManualProcessing batchId={batchId} refresh={setRefresh}/>}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default PartialAnalysisWarning;