import React from 'react';
import Grid from "@material-ui/core/Grid";
import MoneyIcon from "@material-ui/icons/AttachMoney";
import StatsIcon from "@material-ui/icons/ShowChart";
import AverageIcon from "@material-ui/icons/Money";
import {getCurrencyFormat} from "../../../lib/utils/common_utils";
import MiniStatsCard from "../../../components/universal/mini_stats_card";

function BasicStats(props) {
    const {counts, data, loading} = props;

    const stats = [
        {
            name: "Total Deposits",
            value: getCurrencyFormat(data?.total_deposits),
            infoText: `# ${counts?.deposit_count || 0} deposits in ${data?.count_of_months || 0} months`,
            color: "#0dbd0d",
            icon: <MoneyIcon/>
        },
        {
            name: "Total Withdrawals",
            value: getCurrencyFormat(data?.total_withdrawals),
            infoText: `# ${counts?.withdrawal_count || 0} withdrawals in ${data?.count_of_months || 0} months`,
            color: "#ff6347",
            icon: <MoneyIcon/>
        },
        {
            name: "NSF/OD",
            value: `${data?.nsf_count || 0} NSF and ${data?.od_count || 0} ODs`,
            color: "#3e3c9f",
            icon: <StatsIcon/>
        },
        {
            name: "Average Deposit",
            value: getCurrencyFormat(data?.average_deposits),
            color: "#fe983c",
            icon: <AverageIcon/>
        }
    ]
    return (
        <Grid container spacing={1} alignItems={"center"} justify={"space-between"}>
            {stats.map((stat, i) => {
                return (
                    <Grid item md={3} key={`stat-${i}`}>
                        <MiniStatsCard stat={stat} loading={loading}/>
                    </Grid>
                )
            })
            }
        </Grid>
    )
}

export default BasicStats;
