import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import ProgressBar from "./progress_bar";

function ProgressDialog(props) {

    const {total, completed, message, content} = props;

    return (
        <Dialog
            maxWidth="xs"
            fullWidth
            open={props.open}
            onClose={props.onClose}
        >
            <DialogContent style={{padding: "24px 32px", minHeight: '150px'}}>
                <Typography variant={"subtitle2"} style={{fontSize: "16px"}} gutterBottom>File Upload in
                    Progress</Typography>
                <Typography variant={"subtitle1"}>
                    Please do not close your window during this process.
                </Typography>
                {(!message && !content) ? <div style={{marginTop: '25px'}}>
                    <ProgressBar total={total} completed={completed}/>
                </div> : <div>
                    {message?.type === "success" ? <div className={"center"}>
                        <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                            <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                            <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                        </svg>
                    </div> : <div style={{height: '50px'}}/>}
                    <div className={"center"} style={{paddingBottom: 25}}>
                        <Typography className={message?.type === "error" ? "negative center" : "center"}
                                    variant={"subtitle2"}>{message.content}</Typography><br/>
                    </div>
                </div>}
            </DialogContent>
        </Dialog>
    );
}

export default ProgressDialog;
