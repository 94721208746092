import React, {useContext, useEffect, useState} from "react";
import List from "@material-ui/core/List";
import Drawer from "@material-ui/core/Drawer";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Switch from "@material-ui/core/Switch";
import Tooltip from "@material-ui/core/Tooltip";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import Button from "@material-ui/core/Button";
import {ToastMessageContext} from "../../../lib/contexts/message_context";
import BatchFilesClient from "../../../lib/services/api/files";
import UpdateBatch from "../../Batches/update_batch";
import {makeStyles} from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import BackIcon from "@material-ui/icons/ArrowBackIos";
import FileIcon from "@material-ui/icons/Description";
import Toolbar from "@material-ui/core/Toolbar";
import Divider from "@material-ui/core/Divider";
import AddIcon from "@material-ui/icons/Add";
import ViewIcon from "@material-ui/icons/Visibility";
import HideViewIcon from "@material-ui/icons/VisibilityOff";
import {fileStatusMap} from "../../Batches/file_status_info";

export const useStyles = makeStyles(theme => ({
    wrapper: {
        overflowY: 'scroll',
        overflowX: 'hidden'
    },
    noOverflow: {
        overflow: 'hidden'
    },
    listWrapper: {
        width: '27vw',
        overflow: 'hidden'
    },
    title: {
        fontSize: '18px'
    },
    sectionTitle: {
        paddingLeft: '20px',
        paddingTop: '8px',
        width: '100%'
    }
}))

const PositiveSwitch = withStyles({
    switchBase: {
        '&$checked': {
            color: "green",
        },
        '&$checked + $track': {
            backgroundColor: "green",
        },
    },
    checked: {},
    track: {},
})(Switch);

function FilesList(props) {
    const classes = useStyles();
    const {title, data, handleFileExclusion, batchId, processing} = props;
    const ToastMessage = useContext(ToastMessageContext);

    const downloadUploadedFile = (fileId) => {
        ToastMessage.showSuccess("File download initiated");
        BatchFilesClient.downloadBatchFile(batchId, fileId).then((res) => {
            if (res.success) {
                window.location.href = res?.data?.uri;
            }
        }).catch((e) => {
            ToastMessage.showError("Something went wrong. Try again later");
        })
    }

    return (
        data?.length > 0 && <div className={classes.listWrapper}>
            <Typography variant={"subtitle2"} style={{display: 'flex', alignItems: 'center', fontSize: '14px'}}>
                <FileIcon size="small" color="primary"/>&nbsp;
                {title} (&nbsp;{data?.length || 0}&nbsp;)
            </Typography>
            <List>
                {data.map((file, i) => <ListItem key={file.name} style={{
                    border: '1px solid #d5d5d565',
                    borderRadius: '5px',
                    margin: '8px 0'
                }}>
                    <ListItemText>
                        <Typography variant={"body2"} component={"div"}>{i + 1}.&nbsp;
                            {((file?.status?.indexOf('PROCESS') > -1 && file.statement_date?.length > 4) ? (
                                <Typography component="span" color="secondary" variant={"body2"}>
                                    {moment(file.statement_date, "MM-DD-YYYY").format("MMM-YYYY")}
                                </Typography>) : (<Typography component="span" color="secondary" variant={"body2"}>
                                {fileStatusMap[file?.status]?.text}
                            </Typography>))}
                            <Typography component="span" variant={"body2"}
                                        color={"error"}>&nbsp; {(file?.is_removed || file?.is_duplicate) ? "Potential Duplicate" : ""}</Typography>
                        </Typography>
                        <Typography variant={"caption"} color={'textSecondary'}
                                    style={{wordBreak: 'break-all'}} className={"clickable"}
                                    onClick={() => downloadUploadedFile(file.id)}>
                            {file.name}
                        </Typography>
                    </ListItemText>
                    {(file?.status?.indexOf('PROCESSED') > -1) && (file?.is_duplicate === false || file?.is_removed === false) && (
                        <Tooltip
                            arrow
                            title={!!file.includes_analysis ? "Click to Exclude. Currently Included" : "Click to Include. Currently Excluded"}
                        >
                            <PositiveSwitch
                                disabled={processing}
                                size="small"
                                checked={!!file.includes_analysis}
                                onChange={() => handleFileExclusion(file.id, file.includes_analysis)}
                            />
                        </Tooltip>)}
                </ListItem>)
                }
            </List>
        </div>
    )
}


function FileSelection(props) {
    const classes = useStyles()
    const {batchFiles, batchId, setRefresh, loading} = props;
    const [showFiles, setShowFiles] = useState(false);
    const [showUpdate, setShowUpdate] = useState(false);
    const [processing, setProcessing] = useState(false);
    let sortedFiles = batchFiles;
    const Toast = useContext(ToastMessageContext);

    useEffect(() => {
        if (processing) {
            Toast.showToastMessage({message: "Please wait. File Exclusion in Progress...", variant: "info"});
        }
    }, [processing]);

    const handleFileExclusion = (fileId, currentStatus) => {
        const formData = {
            "includes_analysis": !currentStatus
        }
        setProcessing(true);
        BatchFilesClient.includeExcludeAnalysis(batchId, fileId, formData).then((res) => {
            if (res.success) {
                setRefresh();
                Toast.showSuccess("File status updated successfully");
            }
        }).catch((e) => {
            Toast.showError(e?.message || "Something went wrong while updating file status.")
        }).finally(() => {
            setProcessing(false);
        })
    }

    let includedFiles = sortedFiles.filter(f => f.includes_analysis === true && f.status?.indexOf('PROCESSED') > -1);
    let excludedFiles = sortedFiles.filter(f => f.includes_analysis === false || f.status?.indexOf('PROCESSED') < 0);
    const duplicateFiles = sortedFiles.filter(f => f?.is_removed === true || f?.is_duplicate === true);
    const combinedStatements = sortedFiles.filter(f => f.status === 'SPLIT_FILE');
    const rejectedFiles = sortedFiles.filter(f => f.status?.indexOf('PROCESSED') < 0 && f.status !== 'SPLIT_FILE');
    includedFiles = includedFiles.sort(function (a, b) {
        return moment(b.statement_date, "MM-DD-YYYY") - moment(a.statement_date, "MM-DD-YYYY");
    });
    excludedFiles = excludedFiles.sort(function (a, b) {
        return moment(b.statement_date, "MM-DD-YYYY") - moment(a.statement_date, "MM-DD-YYYY");
    });
    return (
        <div style={{padding: '0 12px'}}>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <Typography component={"div"}>
                    <Typography color="secondary" variant="subtitle2" component={"span"} style={{fontSize: '18px'}}>
                        {includedFiles?.length || 0}
                    </Typography>
                    &nbsp;of&nbsp;
                    <Typography variant="subtitle2" component="span" style={{fontSize: '18px'}}>
                        {batchFiles.length}&nbsp;
                    </Typography>
                    files used for analysis.
                    {(duplicateFiles.length > 0 || combinedStatements?.length > 0 || rejectedFiles?.length > 0) && (
                        <>
                            &nbsp;&nbsp;(&nbsp;
                            {duplicateFiles?.length > 0 && (
                                <>
                                    <Typography color="secondary" variant="subtitle2" component={"span"}
                                                style={{fontSize: '18px'}}>
                                        {duplicateFiles?.length}&nbsp;</Typography>
                                    duplicate files,&nbsp;
                                </>)}
                            {combinedStatements?.length > 0 && (
                                <>
                                    <Typography color="secondary" variant="subtitle2" component={"span"}
                                                style={{fontSize: '18px'}}>{combinedStatements?.length}&nbsp;</Typography>
                                    combined statements,&nbsp;
                                </>
                            )}
                            {rejectedFiles?.length > 0 && (
                                <>
                                    <Typography color="secondary" variant="subtitle2" component={"span"}
                                                style={{fontSize: '18px'}}>{rejectedFiles?.length}&nbsp;</Typography> unprocessed
                                    files
                                </>
                            )}
                            &nbsp;excluded from analysis&nbsp;)
                        </>
                    )}
                </Typography>
                <div>
                    <Button size={"small"} variant={"outlined"} color="primary"
                            onClick={() => setShowFiles(!showFiles)}
                            startIcon={showFiles ? <HideViewIcon/> : <ViewIcon/>}>
                        {showFiles ? "Hide File list" : "View Files of this batch"}
                    </Button>
                    {!showUpdate &&
                    <Button style={{marginLeft: '12px'}} variant={"contained"} color="primary" size={"small"}
                            onClick={() => setShowUpdate(true)} startIcon={<AddIcon/>}>
                        Add New Files
                    </Button>}
                </div>
            </div>
            <div>
                <UpdateBatch
                    show={showUpdate}
                    existingFiles={batchFiles}
                    batchId={batchId}
                    handleCancel={() => setShowUpdate(false)}
                />
            </div>

            <Drawer className={classes.noOverflow} anchor={'right'} open={showFiles}
                    onClose={() => setShowFiles(false)}>
                <div className={`${classes.wrapper}`}>
                    <Toolbar style={{
                        position: 'absolute',
                        top: '0px',
                        width: '25vw',
                        background: '#fff',
                        zIndex: 375
                    }}>
                        <div style={{textAlign: 'left', background: '#fff', display: 'flex', alignItems: 'center'}}>
                            <IconButton disableFocusRipple disableRipple onClick={() => setShowFiles(false)}>
                                <BackIcon color={"primary"}/>
                            </IconButton>
                            <Typography className={classes.sectionTitle} variant={"h6"} component="div" gutterBottom>Files
                                in Batch</Typography>
                        </div>
                    </Toolbar>
                    <div style={{padding: '65px 35px 75px 35px'}}>
                        <Typography variant={"subtitle2"} gutterBottom style={{marginRight: '16px'}}>
                            <Typography variant={"h6"} component={"span"}
                                        color="secondary"> {batchFiles.length}&nbsp;</Typography> files in this Batch
                        </Typography>
                        <FilesList processing={processing} batchId={batchId} title={"Not included for Analysis"}
                                   data={excludedFiles}
                                   handleFileExclusion={handleFileExclusion}/>
                        <Divider variant={"middle"} style={{margin: '12px 0'}}/>
                        <FilesList processing={processing} title={"Included for Analysis"} data={includedFiles}
                                   batchId={batchId}
                                   handleFileExclusion={handleFileExclusion}/>
                    </div>
                </div>
            </Drawer>
        </div>
    );
}

export default FileSelection;