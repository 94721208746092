import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AnalyticsClient from "../../../lib/services/api/analytics";
import WarningIcon from "@material-ui/icons/Warning";
import ErrorIcon from "@material-ui/icons/Cancel";
import PinIcon from "@material-ui/icons/Room";
import Grid from "@material-ui/core/Grid";
import CategoryWiseRiskItems from "./category_wise_risk_summary";
import FileWiseRiskItems from "./file_wise_risk_summary";
import CheckIcon from "@material-ui/icons/CheckCircle";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import LoadingIndicator from "../../../components/universal/loading_indicator";

export const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    listItemCard: {
        padding: '24px 32px',
        width: '100%',
        boxShadow: 'inset 0 0 2px 0px #e5e5e5, 0 0 2px 1px #e5e5e5',
        borderRadius: '10px',
        "&.active": {
            backgroundColor: '#e5e5ff'
        }
    },
    riskSummaryWrapper: {
        padding: '12px 0',
        boxShadow: '0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05)',
        borderRadius: '16px',
        border: '1px solid #EDF2F7',
        marginBottom: '24px'
    },
    riskScore: {
        alignItems: 'center',
        minHeight: '150px',
        padding: '2em 4em'
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: 600,
        display: 'flex',
        alignItems: 'center'
    },
    description: {
        fontSize: '14px',
        color: '#16192C',
        padding: '10px',
        backgroundColor: '#F6F8FF',
        display: 'flex',
        alignItems: 'center'
    },
    bar: {
        height: '12px',
        width: '100%',
        marginBottom: '8px'
    },
    critical: {
        backgroundColor: '#E25950'
    },
    warn: {
        backgroundColor: '#F5C163'
    },
    pass: {
        backgroundColor: '#6EB268'
    },
    riskDetail: {
        width: '100%',
        paddingTop: '12px'
    },
    accordionTitle: {
        backgroundColor: "#FAFAFB",
        "&.Mui-expanded": {
            color: "#000",
            "&.critical": {
                backgroundColor: "#FFE6E4",
                borderTop: "1px solid #E25950"
            },
            "&.warn": {
                backgroundColor: "#F5C16320",
                borderTop: "1px solid #F5C163"
            }
        },
        "& .MuiAccordionSummary-content": {
            display: 'block'
        }
    }
}));

function RiskSummary(props) {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [riskSummary, setRiskSummary] = useState([]);
    const [fileWiseRiskSummary, setFileWiseRiskSummary] = useState([]);
    const [riskScore, setRiskScore] = useState(null);
    const [view, setView] = useState('category');
    const [error, setError] = useState(false);

    useEffect(() => {
        if (props?.batchId) {
            setLoading(true);
            AnalyticsClient.getRiskSummary(props?.batchId).then((res) => {
                if (res?.success) {
                    setRiskSummary(res?.data?.risk_details);
                    setRiskScore(res.data?.risk_summary);
                    setFileWiseRiskSummary(res.data?.file_wise_summary);
                }
                setLoading(false);
            }).catch((e) => {
                setError(true);
                setLoading(false);
            })
        }
    }, [props?.batchId, props?.refresh])

    const errors = riskSummary?.filter(d => d.type === 'critical' && d.test_status === "FLAGGED");
    const warnings = riskSummary?.filter(d => d.type === 'warn' && d.test_status === "FLAGGED");
    const overall = errors.concat(warnings);
    const topObservations = overall?.slice(0, 4);
    const nextObservations = overall?.slice(4, 9);

    return (
        <>
            {!loading && !error && <div className={classes.root}>
                <div key={riskScore} className={classes.riskSummaryWrapper}>
                    <Grid container alignItems={"flex-start"} justify={"center"}>
                        <Grid className={classes.riskScore} item md={6} style={{padding: '12px 32px'}}>
                            <Typography style={{fontSize: '16px'}} variant={"subtitle2"} gutterBottom>Overall Risk
                                Rating</Typography>
                            <Grid container alignItems={"center"} style={{padding: '12px 0'}}>
                                <Grid xs={4}>{riskScore === 'red' &&
                                <div className={"center"}><PinIcon fontSize={"large"} htmlColor="#E25950"/>
                                </div>}</Grid>
                                <Grid xs={4}>{riskScore === 'yellow' &&
                                <div className={"center"}><PinIcon fontSize={"large"} htmlColor="#F5C163"/>
                                </div>}</Grid>
                                <Grid xs={4}>{riskScore === 'green' &&
                                <div className={"center"}><PinIcon fontSize={"large"} htmlColor="#6EB268"/>
                                </div>}</Grid>

                                <Grid xs={4}>
                                    <div className={`${classes.critical} ${classes.bar}`}/>
                                    <Typography style={{fontSize: '11px'}} variant="subtitle2"
                                                align={"center"}>Warning</Typography>
                                </Grid>
                                <Grid xs={4}>
                                    <div className={`${classes.warn} ${classes.bar}`}/>
                                    <Typography style={{fontSize: '11px'}} variant="subtitle2" align={"center"}>Proceed
                                        with
                                        warning</Typography>
                                </Grid>
                                <Grid xs={4}>
                                    <div className={`${classes.pass} ${classes.bar}`}/>
                                    <Typography style={{fontSize: '11px'}} variant="subtitle2" align={"center"}>Good to
                                        go</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid className={classes.riskScore} item md={6} style={{padding: '12px 32px'}}>
                            <Typography style={{fontSize: '16px', paddingLeft: '16px'}} variant={"subtitle2"}
                                        gutterBottom>Observations</Typography>
                            <Grid container spacing={4}
                                  style={{borderLeft: '1px solid #d5d5d5', padding: "12px 16px", minHeight: '170px'}}>
                                {topObservations?.length > 0 && <Grid item md={6}>
                                    {topObservations?.map(e => <Typography style={{justifyContent: "flex-start"}}
                                                                           className={"center"} color="primary"
                                                                           variant={"subtitle1"} key={e.test_name}
                                                                           gutterBottom>
                                        {e?.type === "critical" ?
                                            <ErrorIcon htmlColor="#E25950"/> :
                                            <WarningIcon htmlColor="#F5C163"/>}&nbsp;{e.test_name}
                                    </Typography>)}
                                </Grid>}
                                {nextObservations?.length > 0 && <Grid item md={6}>
                                    {nextObservations?.map(e => <Typography style={{justifyContent: "flex-start"}}
                                                                            className={"center"} color="primary"
                                                                            variant={"subtitle1"} key={e.test_name}
                                                                            gutterBottom>
                                        {e?.type === "critical" ?
                                            <ErrorIcon htmlColor="#E25950"/> :
                                            <WarningIcon htmlColor="#F5C163"/>}&nbsp;{e.test_name}
                                    </Typography>)}
                                </Grid>}
                                {overall?.length === 0 && <Grid item md={6}>
                                    <Typography style={{justifyContent: "flex-start"}}
                                                className={"center"} color="primary"
                                                variant={"subtitle1"}
                                                gutterBottom>
                                        <CheckIcon fontSize={"20px"} htmlColor="#6EB268"/>&nbsp; None
                                    </Typography>
                                </Grid>}
                            </Grid>

                        </Grid>
                    </Grid>
                </div>
                <div className={"corner-aligned"} style={{width: '45%', paddingBottom: '24px'}}>
                    <Typography component={"div"} variant={"subtitle2"} className={classes.heading}>
                        Fraud Checks
                    </Typography>
                    <div className={"center"}>
                        <Typography variant={"subtitle1"} color={"textSecondary"}>
                            &nbsp;&nbsp;View By:&nbsp;&nbsp;&nbsp;&nbsp;
                        </Typography>
                        <Tabs id={"riskSummaryTabs"} value={view} onChange={(e, tab) => setView(tab)}>
                            <Tab value={"category"} label="Category" style={{textTransform: 'capitalize'}}/>
                            <Tab value={"statement"} label="Statement" style={{textTransform: 'capitalize'}}/>
                        </Tabs>
                    </div>

                </div>
                {view === 'category' &&
                <div>
                    {riskSummary?.map((risk) =>
                        <CategoryWiseRiskItems
                            batchId={props?.batchId}
                            key={risk?.test_name}
                            risk={risk}
                            classes={classes}
                        />)}
                </div>}
                {view === 'statement' &&
                <div>
                    {fileWiseRiskSummary?.map((risk) =>
                        <FileWiseRiskItems
                            key={risk?.filename}
                            risk={risk}
                            classes={classes}
                        />)}
                </div>}
            </div>}
            {loading && <div style={{height: '300px'}}>
                <LoadingIndicator variant={"block"}>
                    <Typography variant={"body2"} style={{fontSize: '12px'}} color={"primary"}>Fetching risk
                        summary...</Typography>
                </LoadingIndicator>
            </div>}
            {!loading && error && (<div style={{height: '120px'}}>
                <Typography align={"center"} variant={"body2"} style={{fontSize: '12px'}} color={"secondary"}>
                    Risk Summary is unavailable for this batch
                </Typography>
            </div>)}
        </>)
}

export default RiskSummary;
