import React, {useContext, useEffect, useState} from 'react';
import HalfWideRightDrawer from "../../components/universal/halfwide_right_drawer";
import Typography from "@material-ui/core/Typography";
import tableIcons, {tableOptions} from "../../components/universal/table_attributes";
import MaterialTable from "material-table";
import {MTableToolbar} from "material-table";
import CheckIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Cancel";
import DownloadIcon from "@material-ui/icons/GetApp";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import BatchFilesClient from "../../lib/services/api/files";
import {ToastMessageContext} from "../../lib/contexts/message_context";
import moment from "moment";
import InfoIcon from "@material-ui/icons/Info";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {getCurrencyFormat, sleepFor} from "../../lib/utils/common_utils";
import Tooltip from "@material-ui/core/Tooltip";
import {EventsContext} from "../../lib/contexts/events_context";
import FileStatus from "./file_status_info";
import BatchClient from "../../lib/services/api/batches";
import Alert from "@material-ui/lab/Alert";
import ReportBug from "../BugReport";
import ClockIcon from "@material-ui/icons/AccessAlarm";
import RequestManualProcessing from "./request_manual_processing";
import UpdateBatch from "./update_batch";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import {DeleteConfirmDialog} from "../../components/universal/confirm_dialog";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import BatchFileManualErrorWarning from "./batch_file_manual_error_warnings";


const useStyles = makeStyles(theme => ({
    fileStatusText: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '0.8em'
    },
    tableWrapper: {
        padding: '20px',
        [theme.breakpoints.down('md')]: {
            padding: '25px 5px'
        }
    },
    selectOptionsWrapper: {
        padding: '16px',
        color: '#fff',
        fontWeight: 'bold',
        width: '97%',
        background: theme.palette.primary.dark
    },
    isMobile: {
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    },
    mobileBtnPadding: {
        [theme.breakpoints.down('md')]: {
            paddingLeft: '3px',
            paddingRight: '3px',
            minWidth: '75px !important',
            marginRight: '20px'
        }
    },
    note: {
        padding: '12px',
        margin: '20px',
        background: '#6e6cfb',
        color: '#fff',
        border: '1px solid #6e6cfb'
    },
    attentionNote: {
        padding: '12px',
        margin: '15px',
        border: '1px solid #6e6cfb',
        textAlign: 'center'
    }
}))


function BatchDetails(props) {

    const classes = useStyles();
    const download = false;
    const {open, handleClose, currentSelection} = props;
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [showUpload, setShowUpload] = useState(false);
    const [files, setFiles] = useState([]);
    const [batchDetail, setBatchDetail] = useState({});
    const ToastMessage = useContext(ToastMessageContext);
    const [totalDeposits, setTotalDeposits] = useState(null);
    const user = JSON.parse(sessionStorage.getItem('user'));
    const [quality, setQuality] = useState();
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

    const Event = useContext(EventsContext);

    useEffect(() => {
        if (currentSelection.id) {
            Event.recordPageView("/users/batch/details");
            setTotalDeposits(null);
            setLoading(true);
            BatchFilesClient.getBatchDetails(currentSelection.id).then((res) => {
                if (res?.data?.files?.length > 0) {
                    setFiles(res?.data?.files);
                    setBatchDetail(res?.data?.batch);
                    let totalDeposit = 0;
                    let overallQuality = {
                        'vision_overall_word_confidence': 0,
                        'vision_overall_numbers_confidence': 0
                    }
                    for (let i = 0; i < res?.data?.files?.length; i++) {
                        const file = res?.data?.files[i];
                        totalDeposit = totalDeposit + !isNaN(file['total_additions']) ? parseFloat(file['total_additions']) : 0;
                        overallQuality['vision_overall_word_confidence'] += !isNaN(+file['quality_indices']?.['vision_overall_word_confidence']) ? +file['quality_indices']?.['vision_overall_word_confidence'] : 0;
                        overallQuality['vision_overall_numbers_confidence'] += !isNaN(+file['quality_indices']?.['vision_overall_numbers_confidence']) ? +file['quality_indices']?.['vision_overall_numbers_confidence'] : 0;
                    }
                    setTotalDeposits(totalDeposit);
                    setQuality(overallQuality);
                }
            }).catch((e) => {
                ToastMessage.showError("Something went wrong while fetching files");
            }).finally(() => {
                setLoading(false);
            })
        } else {
            setFiles([]);
        }

        return (() => {
            setFiles([]);
            setBatchDetail({});
        })
    }, [currentSelection, refresh]);


    const MITableOptions = {
        ...tableOptions,
        showTitle: true,
        paging: true,
        filter: true,
        selectableRowsOnClick: false,
        pageSize: 30,
        selection: true,
        pageSizeOptions: [10, 20, 30, 40, 50],
        searchFieldStyle: {
            width: '350px'
        },
    }


    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        handleClose(open);
    };

    const reProcessBatch = (batchId) => {
        const formData = new FormData();
        formData.append('forced_flag', true);
        setLoading(true);
        BatchClient.reProcessBatch(batchId, formData).then((res) => {
            if (res.success) {
                ToastMessage.showSuccess("Batch Reprocess without QC initiated.");
            }
        }).catch((e) => {
            ToastMessage.showError("Something went wrong. Try again!");
        }).finally(() => {
            setLoading(false);
        })
    }

    const downloadBatchResultFile = (batchId, params) => {
        ToastMessage.showSuccess("File(s) download initiated.");
        Event.recordEvent("Batch", "Download", "Download Batch Result", batchId);

        BatchFilesClient.downloadBatchResultFile(batchId, params).then((res) => {
            if (res.success) {
                openDownloadWindow(res).then((res) => {
                    if (res) {
                        ToastMessage.showSuccess("File(s) downloaded successfully");
                    }
                });
            }
        }).catch((e) => {
            ToastMessage.showError("Something went wrong. Try again!");
        })
    }

    const openDownloadWindow = async (res) => {
        if (res.data) {
            for (let i = 0; i < res.data?.length; i++) {
                try {
                    window.location.href = res?.data?.[i]?.uri;
                } catch (e) {
                    continue;
                }
                await sleepFor(2000);
            }
        }
        return true;
    }

    const downloadUploadedFile = (fileId) => {
        ToastMessage.showSuccess("File download initiated");
        BatchFilesClient.downloadBatchFile(currentSelection?.id, fileId).then((res) => {
            if (res.success) {
                window.location.href = res?.data?.uri;
            }
        }).catch((e) => {
            ToastMessage.showError("Something went wrong. Try again later");
        })
    }

    const downloadJsonFile = async (fileId, type, bulk = false) => {
        if (user.role !== 'USER') {
            if (!bulk) {
                ToastMessage.showSuccess("JSON File download initiated.");
            }
            const params = {
                engine: type
            }
            BatchFilesClient.downloadJsonFile(currentSelection.id, fileId, params).then((res) => {
                if (res.success) {
                    openDownloadWindow(res).then((res) => {
                        if (res && !bulk) {
                            ToastMessage.showSuccess("File downloaded successfully");
                        }
                    });
                }
            }).catch((e) => {
                if (!bulk) {
                    ToastMessage.showError("Something went wrong. Try again!");
                }
            })
        }
    }

    const downloadJsonFileBulk = async (e, engine) => {
        const fileIds = files.map(file => file.id);
        if (user.role !== 'USER') {
            for (let i = 0; i < fileIds.length; i++) {
                await downloadJsonFile(fileIds[i], engine, true)
                await sleepFor(2000);
            }
        }
    }

    const downloadFilesBulk = async () => {
        const fileIds = files.map(file => file.id);
        for (let i = 0; i < fileIds.length; i++) {
            await downloadUploadedFile(fileIds[i])
            await sleepFor(2000);
        }
        return true;
    }

    const handleDelete = (e) => {
        e.preventDefault();
        setLoading(true);
        BatchFilesClient.deleteFiles(batchDetail?.id, showDeleteConfirm).then((res) => {
            if (res?.success) {
                ToastMessage.showSuccess("Files Deleted Successfully");
                setShowDeleteConfirm(false);
            }
        }).catch((e) => {
            ToastMessage.showError(e.message || "Something went wrong. Try again");
            setShowDeleteConfirm(false);
        }).finally((e) => {
            setLoading(false);
        })
    }

    const shouldDisableAnalysis = (passedBatchDetail) => {
        const batchDetails = passedBatchDetail || batchDetail;
        const status = batchDetails?.status;
        const totalFiles = batchDetails?.file_count;
        const failedFiles = batchDetails?.invalid_files;
        const validFiles = batchDetails?.valid_files;
        const difFiles = +totalFiles - +validFiles;
        const tenant = JSON.parse(sessionStorage.getItem('user'))?.['tenant_name'];
        if (status === 'PROCESSING') {
            return true
        } else {
            if (tenant?.toUpperCase() === 'LENDSURE') {
                if ((failedFiles > 2 || validFiles < 1 || difFiles > 2) && status !== "PROCESSING_MANUALLY") {
                    return true;
                }
                if (difFiles > 2) {
                    return true;
                }
            } else {
                if (status?.indexOf('PROCESSED') < 0) {
                    return true;
                }
            }
        }

        return false;
    }

    const columns = [
        {
            title: 'Statement',
            field: 'name',
            width: '40vw',
            headerStyle: {
                textAlign: 'left'
            },
            cellStyle: {
                textAlign: 'left',
                paddingLeft: '5px !important'
            },
            defaultSort: 'desc',
            customSort: (a, b) => moment(a.statement_date) - moment(b.statement_date),
            render: rowData => {
                return <Tooltip
                    title={<div style={{padding: '8px'}}>
                        Download - {rowData.name}
                        <br/>
                        <div>
                            <List>
                                <ListItem>Word Quality
                                    Vision: &nbsp;{rowData?.quality_indices?.vision_overall_word_confidence || "NA"}</ListItem>
                                <ListItem>Number Quality
                                    Vision: &nbsp;{rowData?.quality_indices?.vision_overall_numbers_confidence || "NA"}</ListItem>
                                <ListItem>Word Quality
                                    Textract: &nbsp;{rowData?.quality_indices?.textract_overall_word_confidence || "NA"}</ListItem>
                                <ListItem>Number Quality
                                    Textract: &nbsp;{rowData?.quality_indices?.textract_overall_numbers_confidence || "NA"}</ListItem>
                            </List>
                        </div>
                    </div>}
                    arrow>
                    <div>
                        <Typography className={"clickable"} variant={"body1"}
                                    onClick={() => downloadUploadedFile(rowData.id)}
                        >
                            {rowData.name}
                        </Typography>
                        {rowData.statement_date?.length > 4 && (<>
                            <Typography variant={"body2"} component={"span"}>
                                {moment(rowData.statement_date, "MM-DD-YYYY").format("MMM-YYYY")}
                            </Typography></>)}&nbsp;
                        {(rowData?.is_removed || rowData?.is_duplicate) && (<Typography variant={"subtitle2"} className={"negative"}>
                            Potential Duplicate
                        </Typography>)}
                        {rowData?.bank_config_hash && <div>
                            <Typography variant={"caption"} component={"div"} color={"textSecondary"}>
                                {rowData?.bank_config_hash}
                            </Typography>
                            <Typography variant={"caption"} component={"div"} color={"textSecondary"}>
                                {rowData?.bank_config_version}
                            </Typography>
                        </div>}
                    </div>
                </Tooltip>
            }
        },
        {
            title: 'Deposit Amount',
            field: 'total_additions',
            headerStyle: {
                textAlign: 'left'
            },
            cellStyle: {
                textAlign: 'left',
                paddingLeft: '10px !important'
            },
            render: rowData => {
                return (<Typography style={{color: '#000'}} variant="subtitle2"
                                    onDoubleClick={() => downloadJsonFile(rowData.id, "TEXTRACT")}>
                    {isNaN(+rowData?.total_additions) ? "N/A" :
                        <strong>{getCurrencyFormat(rowData.total_additions, 2)}</strong>}
                </Typography>)
            }
        },
        {
            title: 'Bank Name',
            field: 'expanded_bank_name',
            headerStyle: {
                textAlign: 'center'
            },
            emptyValue: "N/A",
            cellStyle: {
                textAlign: 'center',
                paddingLeft: '10px !important',
                textTransform: 'capitalize'
            },
            render: rowData => <Typography variant="body1" component="span"
                                           onDoubleClick={() => downloadJsonFile(rowData.id)}
            >{rowData.expanded_bank_name || rowData.bank_name}</Typography>
        },
        {
            title: 'Status',
            field: 'status',
            width: '35%',
            headerStyle: {
                textAlign: 'left',
            },
            render: rowData => {
                return (
                    <div onClick={()=>downloadJsonFile(rowData.id, "PDFREADER")}>
                        <FileStatus data={rowData}/>
                    </div>
                )
            }
        },
    ];

    const all_banks = batchDetail?.expanded_bank_name?.split(',') || []
    const banks = all_banks?.filter(bnk => bnk.toLowerCase() !== 'bank to be configured');
    const duplicateFiles = files.filter(f => f?.is_removed || f?.is_duplicate).length;
    const corruptedFiles = files.filter(f => f.status === 'FILE_0_BYTES');
    const badScanFiles = files.filter(f => f.status === 'BAD_SCAN');
    const unsupportedLangFiles = files.filter(f => f.status === 'UNSUPPORTED_LANGUAGE');
    return (
        <>
            <HalfWideRightDrawer open={open} onClose={toggleDrawer(false)}>
                <div style={{padding: '3%'}}>
                    <div style={{textAlign: 'right', marginBottom: '-30px'}}>
                        <Button size={"small"} onClick={() => handleClose(false)}>Close</Button>
                    </div>
                    <Typography variant={"subtitle2"} style={{fontSize: '1.2em', paddingLeft: '20px'}}
                                className={"capitalize"}>{batchDetail?.name}</Typography>
                    <Grid container style={{paddingLeft: '20px'}} alignItems={"center"}>
                        <Grid item xs={6}>
                            <Typography variant={"subtitle1"} color={"textSecondary"} style={{fontSize: '0.9em'}}
                                        gutterBottom>
                                Created at: {moment(batchDetail?.created_at).format("YYYY-MM-DD HH:mm A")}</Typography>
                            {user.role === 'SUPERADMIN' &&
                            <Typography variant={"subtitle1"} color={"textSecondary"} style={{fontSize: '0.9em'}}
                                        gutterBottom>
                                Created by: {batchDetail?.created_by?.user?.username}</Typography>}
                            <Typography variant={"subtitle1"} color={"textSecondary"} style={{fontSize: '0.9em'}}
                                        gutterBottom>
                                Bank : <Typography component={"span"} color={"primary"} variant={"subtitle2"}
                                                   display={"inline"}>{batchDetail?.expanded_bank_name || batchDetail?.bank_name || "-"}</Typography>
                            </Typography>
                            <Typography variant={"subtitle1"} color={"textSecondary"} style={{fontSize: '0.9em'}}
                                        gutterBottom>
                                Total Deposits: <Typography component={"span"} color={"primary"} variant={"subtitle2"}
                                                            display={"inline"}>{getCurrencyFormat(+totalDeposits, 2)}</Typography>
                            </Typography>
                            <Typography variant={"subtitle1"} color={"textSecondary"} style={{fontSize: '0.9em'}}
                                        gutterBottom>
                                Batch Id: {batchDetail.id}
                            </Typography>
                            <Typography variant={"subtitle1"} color={"textSecondary"} style={{fontSize: '0.9em'}}
                                        gutterBottom>
                                Word
                                Quality: {parseInt(+quality?.vision_overall_word_confidence / +batchDetail.file_count)}<br/>
                                Numbers
                                Quality: {parseInt(+quality?.vision_overall_numbers_confidence / +batchDetail.file_count)}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant={"subtitle2"} gutterBottom> Total processed
                                : {batchDetail.file_count}&nbsp;{batchDetail?.processed_pages ? `(${batchDetail?.processed_pages} pages)` : null}</Typography>
                            <Typography className={classes.fileStatusText}
                                        gutterBottom><CheckIcon className={"positive"}/>&nbsp;
                                <span>{batchDetail.valid_files || 0} Processed</span></Typography>
                            <Typography className={classes.fileStatusText} gutterBottom><ErrorIcon
                                className={"negative"}/>&nbsp;
                                <span> {batchDetail.invalid_files || 0} Rejected</span></Typography>
                            {batchDetail?.new_bank_files > 0 &&
                            <Typography className={classes.fileStatusText} gutterBottom><InfoIcon
                                className={"warning"}/>&nbsp;
                                <span> {batchDetail?.new_bank_files} New Bank</span></Typography>}
                            {user.role === "SUPERADMIN" && (
                                <Button size="small" variant="outlined" onClick={() => setShowUpload(!showUpload)}>Update
                                    Files</Button>)}
                        </Grid>
                        <Grid item xs={12}>
                            <UpdateBatch
                                show={showUpload}
                                existingFiles={files}
                                batchId={batchDetail.id}
                                handleCancel={() => {
                                    setShowUpload(false);
                                    setRefresh(true);
                                }}
                            />
                        </Grid>
                        {(banks?.length > 1) && (batchDetail?.status?.indexOf("PROCESSED") !== -1) && (
                            <Grid item xs={12} style={{padding: '12px'}}>
                                <Alert severity="warning" variant={"filled"}>
                                    Batch has more than 1 Bank Account. Found ({banks?.length}) Accounts</Alert>
                            </Grid>)}
                        <>
                            {!loading && duplicateFiles > 0 && (<>
                                <Grid item xs={12}>
                                    <Alert severity="error" variant={"filled"}
                                           style={{marginBottom: '12px'}}>{`${duplicateFiles} potential duplicate statement period found`}</Alert>
                                </Grid>
                            </>)}
                            {Boolean(corruptedFiles?.length || badScanFiles?.length || unsupportedLangFiles?.length) && <Grid item xs={12} style={{padding: '12px'}}>
                                <Alert severity="warning" variant={"filled"}>
                                    {corruptedFiles?.length > 0 && <div>Batch has ({corruptedFiles?.length}) corrupted files which can't be opened.</div>}
                                    {badScanFiles?.length > 0 && <div>Batch has ({badScanFiles?.length}) files which are bad scans.</div>}
                                    {unsupportedLangFiles?.length > 0 && <div>Batch has ({unsupportedLangFiles?.length}) files that are not in english.</div>}
                                </Alert>
                            </Grid>}
                            {!loading && (<><Grid item xs={4}>
                                    <Tooltip arrow
                                             title={shouldDisableAnalysis() ? "Some files are still being processed analysis will be available after successful processing of all files" : ""}>
                                        <span>
                                        <Button
                                            size="small"
                                            variant="contained"
                                            color="primary"
                                            disabled={shouldDisableAnalysis()}
                                            onClick={() => props.history.push(`/user/batches/${batchDetail?.id}/analysis`)}
                                        >
                                            View Analysis
                                        </Button>
                                        </span>
                                    </Tooltip>
                                </Grid>
                                    <Grid item xs={4}>
                                        {(batchDetail?.id && (batchDetail?.invalid_files !== 0 || batchDetail?.new_bank_files > 0)) &&
                                        <div>
                                            <RequestManualProcessing batchId={batchDetail.id}
                                                                     refresh={() => setRefresh(!refresh)}/>
                                        </div>}
                                        <div>
                                            {batchDetail?.status === "PROCESSING_MANUALLY" &&
                                            <Typography className={"warning"} style={{
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}><ClockIcon/> Awaiting manual output</Typography>}
                                        </div>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <ReportBug batchDetails={batchDetail} files={files}/>
                                    </Grid>
                                    <Grid item md={12} style={{marginTop:'10px'}}>
                                        <Alert severity="info" variant={"outlined"} style={{marginBottom: '12px'}}>{batchDetail?.additional_info?.description}</Alert>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <BatchFileManualErrorWarning errorFlags={batchDetail?.error_flags} />
                                    </Grid>

                                </>
                            )}
                            {user.role === "SUPERADMIN" && (<>
                                    <Grid item xs={1} className="center">
                                        <Button startIcon={<DownloadIcon/>} style={{marginTop: '16px'}}
                                                onClick={downloadFilesBulk} color={"primary"}
                                                size="small"
                                                variant="contained">PDF</Button>
                                    </Grid>
                                    <Grid item xs={4} className="center">
                                        <ButtonGroup disableElevation variant="contained" color="primary" style={{marginTop: '16px', borderRadius:'16px'}}>
                                            <Button size="small" onClick={(e) => downloadJsonFileBulk(e)}>Json E0</Button>
                                            <Button size="small" onClick={(e) => downloadJsonFileBulk(e, 'TEXTRACT')}>Json E1</Button>
                                            <Button size="small" onClick={(e) => downloadJsonFileBulk(e, 'PDFREADER')}>Json E2</Button>
                                        </ButtonGroup>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <ButtonGroup disableElevation variant="contained" color="primary" style={{marginTop: '16px', borderRadius:'16px'}}>
                                            <Button size="small" onClick={() => downloadBatchResultFile(batchDetail.id)}>NoQC</Button>
                                            <Button size="small" onClick={() => downloadBatchResultFile(batchDetail.id, {output: "UPDATE"})}>Manual Update</Button>
                                        </ButtonGroup>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Button style={{marginTop: '16px'}}
                                                onClick={() => reProcessBatch(batchDetail.id)}
                                                color={"primary"}
                                                size="small"
                                                variant="outlined"
                                        >
                                            Quick Reprocess Batch
                                        </Button>
                                    </Grid>
                                </>
                            )}
                        </>
                    </Grid>
                    <div className={classes.tableWrapper}>
                        <MaterialTable
                            title={download && !showUpload && ((batchDetail?.status?.indexOf("PROCESSED") !== -1) || user.role !== "USER") ?
                                <Button className={classes.mobileBtnPadding} variant={"outlined"}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            downloadBatchResultFile(batchDetail.id);
                                        }}
                                        size={"small"}
                                        color={"primary"} startIcon={<DownloadIcon/>}><span
                                    className={classes.isMobile}>Download</span>.xlsx</Button> : ""}
                            style={{boxShadow: 'unset'}}
                            columns={columns}
                            data={files || []}
                            isLoading={loading}
                            options={MITableOptions}
                            icons={tableIcons}
                            localization={{
                                body: {
                                    emptyDataSourceMessage: 'No Statements found',
                                }
                            }}
                            components={{
                                Toolbar: (props) => (
                                    <>
                                    {!props?.selectedRows?.length > 0 && <MTableToolbar {...props} />}
                                        {props?.selectedRows?.length > 0 && (<div className={classes.selectOptionsWrapper}>
                                            <div className={"corner-aligned"}>
                                                <Typography variant="subtitle2" color="inherit">
                                                    (&nbsp;{props.selectedRows?.length}&nbsp;) Files selected.
                                                </Typography>
                                                <div>
                                                    <Button focusRipple size="small" variant="contained"
                                                            color="secondary"
                                                            className={classes.actionButton}
                                                            onClick={() => setShowDeleteConfirm(props.selectedRows?.map(i => i.id))}>
                                                        Delete Selected
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    </>
                                )
                            }}
                        />
                    </div>
                </div>
            </HalfWideRightDrawer>
            <DeleteConfirmDialog loading={loading} open={Boolean(showDeleteConfirm)} content={"Delete selected files for sure?"}
                                 onClose={() => {
                                     setShowDeleteConfirm(false)
                                 }} onConfirm={handleDelete}/>
        </>
    );
}

export default BatchDetails;
