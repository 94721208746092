import CreateReducer from "../lib/utils/create_reducer";
import {BATCH_ACTIONS} from "../actions/batch_actions";

export const INITIAL_BATCH_STATE = {
    loading: false,
    errors: null,
    data: [],
    page: 0,
    pageSize: 25,
    searchText:"",
    total: 0,
    action: undefined,
    currentSelection: {},
    progress: false
}

const batchReducer = CreateReducer(INITIAL_BATCH_STATE, {
    [BATCH_ACTIONS.TOGGLE_LOADING_STATE](state, action) {
        return {
            ...state,
            loading: action.payload || false,
        };
    },
    [BATCH_ACTIONS.TOGGLE_PROGRESS](state, action) {
        return {
            ...state,
            progress: action.payload || false,
        };
    },
    [BATCH_ACTIONS.CREATE_BATCH](state, action) {
        return {
            ...state,
            loading: false,
            action: 'create',
            currentSelection: action.payload
        }
    },
    [BATCH_ACTIONS.SET_LIST](state, action) {
        return {
            ...state,
            loading: false,
            progress: false,
            data: action.payload?.batch || [],
            page: parseInt(action.payload?.page_info?.current_page > 0 ? action.payload?.page_info?.current_page - 1 : 0),
            pageSize: parseInt(action.payload?.page_info?.page_size || 3),
            total: parseInt(action.payload?.page_info?.total_batches || 3)
        };
    },
    [BATCH_ACTIONS.SET_PAGE](state, action) {
        return {
            ...state,
            page: action.payload
        };
    },
    [BATCH_ACTIONS.SET_PAGE_SIZE](state, action) {
        return {
            ...state,
            pageSize: action.payload
        }
    },
    [BATCH_ACTIONS.SET_SEARCH](state, action) {
        return {
            ...state,
            searchText: action.payload
        }
    },
    [BATCH_ACTIONS.CURRENT_ACTION](state, action) {
        return {
            ...state,
            action: 'processed',
            loading: false,
            progress: false
        }
    },
    [BATCH_ACTIONS.DELETE_BATCH](state, action) {
        const newData = state.data.filter(d => d.id !== action.payload.id)
        return {
            ...state,
            action: 'delete',
            data: newData,
            loading: false,
            progress: false
        }
    }
});

export default batchReducer
