import BatchFilesClient from "../lib/services/api/files";

export const BATCH_FILE_ACTIONS = {
    TOGGLE_LOADING_STATE: 'toggle_loading_state',
    SET_ERRORS: 'set_error',
    SET_LIST: 'set_list_data',
    SET_PAGE_SIZE: 'set_no_of_result_per_page',
    SET_PAGE: 'set_page_to_get_data',
    UPLOAD_SUCCESS: 'increment_uploaded_count',
    CLEAR: 'clear_data'
}

function createBatchFile(dispatch, formData, batchId, successCallback, Toast) {
    dispatch({type: BATCH_FILE_ACTIONS.TOGGLE_LOADING_STATE, payload: true});
    BatchFilesClient.createBatchFile(formData, batchId).then((res) => {
        if (res?.data?.file) {
            successCallback && successCallback(res);
        }
    }).catch((err) => {
        dispatch({type: BATCH_FILE_ACTIONS.TOGGLE_LOADING_STATE, payload: false});
        Toast && Toast.showError("Error uploading file. Try again!");
    });
}

function getFiles(dispatch, params) {
    dispatch({type: BATCH_FILE_ACTIONS.TOGGLE_LOADING_STATE, payload: true});
    BatchFilesClient.getBatchDetails(params).then((res) => {
        dispatch({type: BATCH_FILE_ACTIONS.SET_LIST, payload: res.data})
    }).catch((e) => {
            dispatch({type: BATCH_FILE_ACTIONS.TOGGLE_LOADING_STATE, payload: false});
        }
    ).finally(()=>{
        dispatch({type: BATCH_FILE_ACTIONS.TOGGLE_LOADING_STATE, payload: false});
    })
}

function setPage(dispatch, page = 1) {
    dispatch({type: BATCH_FILE_ACTIONS.SET_PAGE, payload: page});
}

function setPageSize(dispatch, pageSize = 25) {
    dispatch({type: BATCH_FILE_ACTIONS.SET_PAGE_SIZE, payload: pageSize});
}

export const ListingAction = {
    getFiles, setPage, setPageSize, createBatchFile
}

export default ListingAction;