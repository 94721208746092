import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import CreateBatch from "./create_batch";
import BatchesList from "./batches_list";
import RefreshStateProvider from "../../lib/contexts/refresh_context";
import Statistics from "../Dashboard/statistics";
import BatchProvider from "../../lib/contexts/batch_context";

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: '6%',
        width: '95%',
        margin: '0 auto',
        paddingBottom: '10%'
    }
}));

function Batches(props) {

    const classes = useStyles();
    const [allowBatchCreate, setAllowBatchCreate] = useState(true);
    const userId = props.match.params.userId;
    const batchId = props.match.params.batchId;
    const user = JSON.parse(sessionStorage.getItem('user'));

    useEffect(() => {
        if (user?.role === "ADMIN") {
            props.history.push('/admin');
        }
    }, [])

    return (
        <BatchProvider>
            <RefreshStateProvider>
                <div className={classes.root}>
                    <Statistics {...props} userId={userId}/>
                    <Grid container alignItems={'flex-start'} justify={'space-between'} spacing={4}>
                        <Grid item md={12}>
                            <BatchesList batchId={batchId} userId={userId} role={user?.role}
                                         setAllowCreate={setAllowBatchCreate} {...props}/>
                        </Grid>
                    </Grid>
                </div>
            </RefreshStateProvider>
        </BatchProvider>
    );
}

export default Batches
