import React, {useContext, useRef, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import {getCurrencyFormat} from "../../../lib/utils/common_utils";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import DownloadIcon from "@material-ui/icons/GetApp";
import AnalyticsClient from "../../../lib/services/api/analytics";
import {ToastMessageContext} from "../../../lib/contexts/message_context";
import CircularProgress from "@material-ui/core/CircularProgress";
import AnalysisNotes from "./analysis_notes";
import MissingFilesInfo from "./missing_month_and_files";
import moment from "moment";
import WarningIcon from "@material-ui/icons/Warning";
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import scrollToTargetAdjusted from "../../../lib/utils/scroll";

function QualifiedIncome(props) {
    const {batchId, changes, txnChanges, metaData, batchFiles, failedFiles, batchMeta, setRefresh, refresh, transactions} = props;
    let income = metaData?.calculated_income;
    let yoyTrend = metaData?.year_over_trend;
    const incomePeriod = metaData?.considered_income_time;
    const [showFilesList, setShowFilesList] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);

    const Toast = useContext(ToastMessageContext);

    const handleDownload = () => {
        setLoading(true);
        AnalyticsClient.getResultFileURL(batchId).then((res) => {
            if (res?.success) {
                window.open(res?.data?.uri);
            }
        }).catch((e) => {
            Toast.showError("Something went wrong.Try again");
        }).finally(() => {
            setLoading(false);
        })
    }

    const handleQIIncomePeriodChange = () => {
        setLoading(true);
        let data = {
            "qi_income_period": new_qi?.amount ? "Default" : "MONTHS_6"
        }
        AnalyticsClient.setQIIncomePeriod(batchId, data).then((res) => {
            setLoading(false);
            setRefresh(true);
        }).catch((e) => {
            Toast.showError(e?.message || "Something went wrong.Try again");
        }).finally(() => {
            setLoading(false);
        })
    }

    const handleDownloadNoExpense = () => {
        const params = {
            result_type:'no-expense'
        };
        AnalyticsClient.getResultFileURL(batchId, params).then((res) => {
            if (res?.success) {
                window.open(res?.data?.uri);
            }
        }).catch((e) => {
            Toast.showError("Something went wrong.Try again");
        }).finally(() => {
            setLoading(false);
        })
    }
    const functions = [handleDownload, handleDownloadNoExpense]

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    const handleMenuItemClick = (event, index) => {
        setLoading(true);
        setSelectedIndex(index)
        functions[index]()
        setOpen(false);
    };
    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
          return;
        }

        setOpen(false);
    };
    let accountWiseIncome = metaData?.qualified_income_split;

    if (metaData?.flag_ac_type?.toLowerCase() === "checking") {
        accountWiseIncome = accountWiseIncome?.filter((ai) => ai.account_type?.toLowerCase() === "savings")
    }
    if (metaData?.flag_ac_type?.toLowerCase() === "savings") {
        accountWiseIncome = accountWiseIncome?.filter((ai) => ai.account_type?.toLowerCase() === "checking")
    }

    const hasMissingMonths = ((Array.isArray(metaData?.missing_months) ? metaData?.missing_months?.length > 0 : metaData?.missing_months?.length > 3));
    let missingMonths = Array.isArray(metaData?.missing_months) ? (metaData?.missing_months?.length > 0 ? metaData?.missing_months : []) : (metaData?.missing_months?.length > 1 ? metaData?.missing_months?.split(',') : [])
    missingMonths = missingMonths?.map((m, i) => moment(m, "YYYY-MMM").format("MMM-YYYY"));
    const missingPages = batchFiles?.filter((f) => f.status === 'PAGES_MISSING');
    const accountWiseMissing = metaData?.qualified_income_split?.filter((f) => f?.missing_months?.length > 0);
    const passedFiles = batchFiles?.filter((f) => (f.status?.indexOf('PROCESSED') > -1 && f.includes_analysis === true));
    const tenantName = JSON.parse(sessionStorage.getItem('user'))?.tenant_name?.toUpperCase();
    const options = ["Expense Factor Output", "No Expense Factor Output"];
    const smb = ["SMB"].includes(tenantName);
    income = smb ? metaData?.creditworthiness :metaData?.calculated_income;
    const quick_income = smb ? metaData?.quick_income : null;
    const show_net_income = metaData?.is_withdrawal_updated || !smb;
    const new_qi = metaData?.new_qi || undefined;
    let incomePeriodLabelMonths = `${incomePeriod === 'overall'? `${passedFiles.length}m` : incomePeriod?.replace("_", " ")} statements`;
    let warningMessage;
    const checkingAccsCount =metaData?.account_number_info?.["CHECKING"]?.length;
    if(["ANGELOAK"].includes(tenantName)) {
        if(new_qi?.income_trend) {
            yoyTrend = new_qi.income_trend;
        }
        if(new_qi?.amount > 0) {
            income = new_qi.amount;
            incomePeriodLabelMonths = "recent 6m statements";
        }
    }

    const showQIIncomePeriod = metaData?.considered_income_time === "recent_12m" && !hasMissingMonths && checkingAccsCount === 1 && tenantName === "ANGELOAK";

    return (
        <>
            <Grid style={{minHeight: '100px'}} container spacing={4} alignItems={"center"} justify={"center"}>
                <Grid item>
                    <Typography component={"div"}>Income Trend : {yoyTrend === "N/A" ? yoyTrend :
                        <Typography variant="h6" component={"span"}
                                    className={yoyTrend > 0 ? "positive" : "negative"}>{`${yoyTrend}%`}</Typography>}
                        {yoyTrend !== "N/A" && tenantName === "ANGELOAK" && (<Typography variant={"caption"} color={"textSecondary"} component={"div"}>
                            {metaData?.income_trend_months_label?.replace("vs", "m vs ")}m
                        </Typography>)}
                    </Typography>
                    {showQIIncomePeriod && (
                        <Button disabled={new_qi?.exception || loading} style={{fontSize: "10px", borderRadius:'8px'}} size={"small"} variant={"outlined"} color={"primary"} onClick={() => handleQIIncomePeriodChange()}>
                        {new_qi?.message && new_qi.amount? "Reset to default" : "Use Recent 6m for QI"}
                    </Button>)}
                </Grid>
                {smb && <>
                    <Grid item>
                    <Typography variant="h6">Quick Income:</Typography>
                    <Typography variant={"caption"}>(<Typography variant={"caption"} component={"span"} color={"primary"}>{incomePeriod === 'overall'? `${passedFiles.length}m` : incomePeriod?.replace("_", " ")}</Typography>&nbsp; at 50% expense)</Typography>
                    </Grid>
                <Grid item>
                    <Typography variant="h4">
                        <span className="blink">{getCurrencyFormat(quick_income)}</span>
                        <span style={{fontSize: '12px'}}>/month</span>
                    </Typography>
                </Grid>
                </>}
                <Grid item style={{paddingBottom:1}}>
                    <Typography variant="h6">{smb? "Net Income" : "Qualified Income"}:</Typography>
                    <Typography variant={"caption"}>(based on&nbsp;<Typography variant={"caption"} component={"span"} color={"primary"}>{incomePeriodLabelMonths}</Typography>)</Typography>
                    {warningMessage && (<Typography style={{fontSize:10}} variant={"caption"} component={"div"} className={"warning center"}><WarningIcon fontSize={"small"} className={"warning"}/> &nbsp;{warningMessage}</Typography>)}
                </Grid>
                <Grid item>
                    <Typography variant="h4">
                        <span className="blink">{ show_net_income ? getCurrencyFormat(income) : "TBC"}</span>
                        <span style={{fontSize: '12px'}}>/month</span>
                    </Typography>
                </Grid>
                {["STG","SIERRA","CITYLENDING","VISIONARY_LENDING_DEFAULT_EXPENSE"].includes(tenantName) ?
                <Grid item>
                    <ButtonGroup class="MuiButtonGroup-root MuiButtonGroup" variant={"contained"} color="primary" ref={anchorRef}>
                      <Button
                        disabled={!income || loading || Boolean(changes) || txnChanges?.flag_txn?.length > 0 || txnChanges.flag_nsf?.length > 0 || txnChanges.flag_od?.length > 0}
                        variant={"contained"}
                        color="primary"
                        size="small"
                        onClick={(event) => handleMenuItemClick(event, selectedIndex)}
                        startIcon={loading ? <CircularProgress size={10}/> : <DownloadIcon/>}
                        >{options[selectedIndex]}
                      </Button>
                      <Button
                        color="primary"
                        size="small"
                        aria-haspopup="menu"
                        onClick={handleToggle}
                      >
                        <ArrowDropDownIcon />
                      </Button>
                    </ButtonGroup>
                    <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                          }}
                        >
                          <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                              <MenuList id="split-button-menu">
                                {options.map((option, index) => (
                                  <MenuItem
                                    key={option}
                                    selected={index === selectedIndex}
                                    onClick={(event) => handleMenuItemClick(event, index)}
                                  >
                                    {option}
                                  </MenuItem>
                                ))}
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                </Grid>
                :
                    <Grid item>
                        <Button
                            disabled={!income || loading || Boolean(changes) || txnChanges?.flag_txn?.length > 0 || txnChanges.flag_nsf?.length > 0 || txnChanges.flag_od?.length > 0}
                            onClick={handleDownload}
                            variant={"contained"}
                            color="primary"
                            size="small"
                            startIcon={loading ? <CircularProgress size={10}/> : <DownloadIcon/>}>
                            Download Analysis
                        </Button>
                    </Grid>}
                <Grid item>
                    <AnalysisNotes setRefresh={setRefresh} refresh={refresh} loading={loading} batchId={batchId} batchComments={batchMeta?.batch_comments} tenant={tenantName} batchMeta={metaData} transactions={transactions}/>
                </Grid>
                <Grid item xs={12} style={{paddingTop: 0, paddingBottom: '24px'}}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'row',
                        flexWrap: 'wrap'
                    }}>
                        {accountWiseIncome?.map((acc_income, i) => (
                            <Typography key={`${acc_income?.account_number}-${i}`} variant={"subtitle1"}
                                        component={"div"} style={{marginRight: '16px'}}
                                        className={"center"}>
                                {acc_income?.missing_months?.length > 0 && <WarningIcon className={"warning"}/>}&nbsp;
                                {`${acc_income?.account_class}: ${acc_income?.captured_account_number ? acc_income?.captured_account_number :acc_income?.account_number}`} :
                                <Typography variant={"subtitle2"} component={"span"} className={"positive"}>
                                    {getCurrencyFormat(smb? acc_income?.creditworthiness :acc_income?.income)}
                                </Typography>
                            </Typography>
                        ))}
                        {( hasMissingMonths || accountWiseMissing?.length > 0 || failedFiles?.length > 0) &&
                        <Button startIcon={<WarningIcon className={"warning"}/>} variant={"outlined"} size={"small"}
                                color={"primary"}
                                onClick={() => {tenantName !== "NEWFI" ? setShowFilesList(true): scrollToTargetAdjusted("risk-summary")}}>
                            View Warnings
                        </Button>}
                    </div>
                </Grid>
            </Grid>
            <MissingFilesInfo
                batchFiles={batchFiles}
                batchId={batchId}
                missingMonths={missingMonths}
                missingPages={missingPages}
                metaData={metaData}
                showFilesList={showFilesList}
                handleClose={() => setShowFilesList(false)}
            />
        </>
    )
}

export default QualifiedIncome;
