import React from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {CircularProgress} from "@material-ui/core";

function SaveConfirmation(props) {
    const {changes, saveChanges, saving, header} = props;
    const tenant = JSON.parse(sessionStorage.getItem('user'))['tenant_name']?.toUpperCase();

    return (
        <div style={{
            marginTop: '10px',
            marginBottom: '10px',
            padding: '15px',
            background: "#f5f5f5",
            width: '100%',
            display: 'flex',
            alignItems: 'center'
        }}>
            <div style={{width: '80%', display: 'inline-block'}}>
                <Typography variant="subtitle2">
                    {header?header:'You have made below changes'}.
                </Typography>
                {changes?.income_account_type && <Typography variant="body1" gutterBottom>Consider Income Account Type: {changes?.income_account_type}</Typography>}
                {changes?.income_acc_number && <Typography variant="body1" gutterBottom>Consider Income Account Number: {changes?.income_acc_number}</Typography>}
                {changes?.categories && <Typography variant="body1" gutterBottom>Re-classified categories: {changes?.categories}</Typography>}
                {changes?.income && <Typography variant="body1" gutterBottom>Net-income amount updated: {changes?.income}</Typography>}
                {changes?.income_time && <Typography variant="body1" style={{ textTransform: 'capitalize'}} gutterBottom>{["ANGELOAK","ARCHOME"].includes(tenant)? "Loan program " :"Considered income duration "} updated: {changes?.income_time?.replaceAll("_"," ")}</Typography>}
                {changes?.ownership && <Typography variant="body1" gutterBottom>Ownership % updated: {changes?.ownership}</Typography>}
                {changes?.expenseFactor && <Typography variant="body1" gutterBottom>Expense factor updated: {changes?.expenseFactor}</Typography>}
                {changes?.atypicalThreshold && <Typography variant="body1" gutterBottom>Atypical Threshold updated to: {changes?.atypicalThreshold}</Typography>}
                {changes?.largeDepThreshold && <Typography variant="body1" gutterBottom>Large Deposit Threshold updated to: {changes?.largeDepThreshold}</Typography>}
            </div>
            <div style={{width: '20%', display: 'inline-block', textAlign: 'right'}}>
                <Button disabled={saving} startIcon={saving && <CircularProgress size={15}/>} color="primary" variant="contained"
                        onClick={saveChanges}>Save and Recalculate</Button>
            </div>
        </div>
    );
}

export default SaveConfirmation;
