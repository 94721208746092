import React, {createContext, useContext, useReducer} from "react";
import {ToastMessageContext} from "./message_context";
import BatchActions from "../../actions/batch_actions";
import batchReducer, {INITIAL_BATCH_STATE} from "../../reducers/batch_reducer";
import batchFileReducer, {INITIAL_BATCH_FILE_STATE} from "../../reducers/batch_file_reducer";
import BatchFileActions from "../../actions/file_actions";
import {EventsContext} from "./events_context";

export const BatchContext = createContext({});

export default function BatchProvider({children}) {
    const [batchData, batchDispatch] = useReducer(batchReducer, INITIAL_BATCH_STATE);
    const [batchFileData, batchFileDispatch] = useReducer(batchFileReducer, INITIAL_BATCH_FILE_STATE);
    const Toast = useContext(ToastMessageContext);
    const Event = useContext(EventsContext);

    const createBatch = (formData) => {
        BatchActions.createBatch(batchDispatch, formData, Toast, Event)
    }

    const getBatchList = (params) => {
        let dataParams = params;
        if (!params) {
            dataParams = {
                page_size: batchData.pageSize,
                page: batchData.page + 1,
            }
        }
        if (!batchData.loading) {
            BatchActions.getBatchList(batchDispatch, dataParams);
        }
    }

    const createBatchFile = (formData, batchId, callback) => {
        BatchFileActions.createBatchFile(batchFileDispatch, formData, batchId, callback, Toast);
    }

    const processBatch = (batchId) => {
        BatchActions.processBatch(batchDispatch, batchId, Toast, Event);
    }

    const deleteBatch = (batchId) => {
        BatchActions.deleteBatch(batchDispatch, batchId, Toast, Event);
    }

    const setPage = (page) => {
        if (!batchData.loading) {
            BatchActions.setPage(batchDispatch, page);
        }
    }

    const setPageSize = (pageSize) => {
        if (!batchData.loading) {
            BatchActions.setPageSize(batchDispatch, pageSize);
        }
    }

    const setSearch = (searchText) => {
        if (!batchData.loading) {
            BatchActions.setSearch(batchDispatch, searchText);
        }
    }

    const ACTIONS = {
        createBatch,
        createBatchFile,
        getBatchList,
        setPage,
        setPageSize,
        processBatch,
        deleteBatch,
        setSearch
    }

    let batch = {data: batchData, ...ACTIONS}
    return (
        <BatchContext.Provider value={batch}>
            {children}
        </BatchContext.Provider>
    );
}
