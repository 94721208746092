import React, {useContext, useRef, useState} from 'react';
import Button from "@material-ui/core/Button";
import UploadIcon from "@material-ui/icons/CloudUpload";
import BatchClient from "../../lib/services/api/batches";
import {ToastMessageContext} from "../../lib/contexts/message_context";
import {ConfirmCancelDialog} from "../../components/universal/confirm_dialog";


function UploadManualOutputFile(props) {
    const {batchId, setRefresh, cancelManualRequest} = props;
    const [loading, setLoading] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const ToastMessage = useContext(ToastMessageContext);
    const [file, setFile] = useState();
    const formRef = useRef();

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        const formData = new FormData();
        formData.append("file", file);
        BatchClient.uploadManualOutput(batchId, formData).then((res) => {
            if (!res?.success) {
                ToastMessage.showError(`Error uploading file - ${file}. Try again`);
            } else {
                ToastMessage.showSuccess("File Uploaded successfully!");
                setRefresh(true)
            }
        }).catch((err) => {
            formRef.reset();
            ToastMessage.showError(err?.message || "Something went wrong. Try again later")
        }).finally(() => {
            setLoading(false);
        })
    }

    return (
        <div>
            <form ref={formRef} onSubmit={handleSubmit}>
                <input
                    name={"output_file"}
                    accept=".xlsx"
                    id="contained-button-file"
                    type="file"
                    onChange={(e) => setFile(e.target.files[0])}
                />
                <div style={{marginTop: "25px", textAlign:"left"}}>
                    <Button size={"small"} type={"submit"} disableElevation
                            disabled={loading}
                            variant={"contained"} color={"primary"} startIcon={<UploadIcon/>}>
                        Submit Manual Output
                    </Button>
                    <Button color="primary" style={{marginLeft:'12px'}} size="small" variant="outlined"
                            onClick={() => setShowConfirm(true)}>Cancel Request</Button>
                </div>
            </form>
            <ConfirmCancelDialog open={showConfirm} onConfirm={(val) => cancelManualRequest(val)}
                                 onClose={() => setShowConfirm(false)}/>
        </div>
    )
}

export default UploadManualOutputFile;
