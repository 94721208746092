import React from "react";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";

export function MainBrandingWidget(props){
    const internal = true;
    let logo_url = "/prudent_logo.svg";
    if(!internal){
        logo_url = "/client_logo.png";
    }
    return(
        <div className={"center pad-10"}>
            <img src={logo_url} height={"35px"} alt={"logo"} />
            <Chip style={{marginLeft: '10px', color:'#fff', backgroundColor:'red'}} label="Internal"/>
        </div>
    )
}

export function PoweredByWidget(props){
    let logo_url = "/prudent_logo_full.svg";
    const enabled = false;

    return (
        enabled ? <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Typography
                style={{ fontSize: "14px" }}
                component={"div"}
                color={"textSecondary"}
                align={"center"}
            >
                Powered by &nbsp;
            </Typography>
            <img src={logo_url} height={"20px"} alt={"logo"} />
        </div> : null
    )
}

