import React from "react";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import {makeStyles} from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";


const useStyles = makeStyles(theme => ({
    statCard: {
        borderRadius: '5px',
        boxShadow: 'inset 0 0 2px 0px #f5f5f5, 0 0 2px 1px #f5f5f5',
        height: '100px',
        [theme.breakpoints.down('md')]: {
            minHeight: '100px'
        }
    },
    statName: {
        fontSize: '14px',
        letterSpacing: '-0.05px'
    },
    value: {
        fontSize: '18px',
        fontWeight: 'bold'
    },
    info: {
        paddingTop: '5px',
        fontSize: '12px'
    },
    icon: {
        height: 56,
        width: 56
    }
}));


function MiniStatsCard(props) {
    const classes = useStyles();
    const { stat, loading, variant } = props;

    return (
        <Card variant={variant} elevation={0} className={classes.statCard}>
            <CardContent>
                <Grid
                    container
                    spacing={3}
                    justify={"space-between"}
                >
                    <Grid item>
                        <Typography className={classes.statName} variant={"subtitle2"}
                                    color={"textSecondary"} gutterBottom>
                            {stat.name}
                        </Typography>
                        {loading ? <Skeleton variant="text"/> : (<Typography
                            color="textPrimary"
                            variant="h3"
                            className={classes.value}
                        >
                            {stat.value}
                        </Typography>)}
                        <Typography
                            color="textSecondary"
                            variant="subtitle1"
                            component={"div"}
                            className={classes.info}
                        >
                            {stat.infoText}
                        </Typography>
                    </Grid>
                    {stat.icon && <Grid item>
                        <Avatar className={classes.icon} style={{backgroundColor: stat.color}}>
                            {stat.icon}
                        </Avatar>
                    </Grid> }
                </Grid>
            </CardContent>
        </Card>
    );
}

export default MiniStatsCard;
