import React, {useContext, useState} from "react";
import {Dialog, TextField} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import AnalyticsClient from "../../../lib/services/api/analytics";
import {ToastMessageContext} from "../../../lib/contexts/message_context";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';


const useStyles = makeStyles(theme => ({
    dialogContent: {
        minHeight: '30vh',
        padding: '16px'
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;

function RenameCategory(props) {
    const classes = useStyles();
    const {showRenameDialog, txnCategories, handleClose, selectedTransactions, batchId, loading, txnTypeContext} = props;
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [updating, setUpdating] = useState(false);
    const [renameTo, setRenameTo] = useState();
    const Toast = useContext(ToastMessageContext);
    const tenant = JSON.parse(sessionStorage.getItem('user'))?.tenant_name?.toUpperCase();
    const smb = ["SMB"].includes(tenant);
    const categories = txnCategories?.split(',');

    const handleReset = (type) => {
        setSelectedCategories([]);
        setRenameTo(null);
        setUpdating(false);
        if (type === 'close') {
            handleClose();
        }
    }
    const disableAction = loading || updating;
    const handleSave = (type) => {
        const formData = {
            "rename_category": {
                "old_categories": selectedCategories,
                "new_category_name": renameTo,
                "type": selectedTransactions?.length > 0 ? "transaction" : "category",
                "txn_ids": selectedTransactions || []
            }
        }
        if (smb){
            formData["rename_category"]["txn_type"] = txnTypeContext === "expense" ? "withdrawal" :"deposit"
        }
        setUpdating(true);
        AnalyticsClient.renameCategories(batchId, formData).then((res) => {
            if (res.success) {
                Toast.showSuccess('Update saved successfully');
            }
        }).catch((e) => {
            Toast.showError("Something went wrong while renaming categories. Try Again");
        }).finally(() => {
            handleReset(type);
        })
    }

    return (
        <Dialog
            maxWidth="sm"
            fullWidth
            open={showRenameDialog}
            scroll={"paper"}
            disableBackdropClick
            disableEscapeKeyDown
        >
            <DialogContent style={{padding: "24px 32px"}}>
                <Typography variant={"h5"} gutterBottom>
                    Rename Categories
                </Typography>
                <div className={classes.dialogContent}>
                    {(!selectedTransactions || selectedTransactions?.length === 0) && (<div style={{marginTop: '10px'}}>
                        <Typography variant="subtitle2" color="textSecondary">
                            Current Categories:
                        </Typography>
                        <FormControl fullWidth>
                            <Autocomplete
                                fullWidth
                                multiple
                                options={categories}
                                disableCloseOnSelect
                                onChange={(event, value) => setSelectedCategories(value)}
                                renderOption={(option, {selected}) => (
                                    <React.Fragment>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{marginRight: 8}}
                                            checked={selected}
                                        />
                                        {option}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" placeholder="select current category"/>
                                )}
                            />
                        </FormControl>
                    </div>)}
                    {selectedTransactions?.length > 0 && (<div>
                        <Typography variant={"subtitle2"} color="textSecondary">
                            <strong>{selectedTransactions?.length}</strong> Transaction selected for re-classification
                        </Typography>
                    </div>)}
                    <div style={{marginTop: '10px'}}>
                        <Typography variant="subtitle2" color="textSecondary">
                            {selectedTransactions?.length < 1 ? "Rename Selected Categories as" : "Reclassify selected transactions as"}:
                        </Typography>
                        <FormControl fullWidth>
                            <Autocomplete
                                freeSolo
                                fullWidth
                                options={categories}
                                autoSelect
                                onChange={(e, value) => setRenameTo(value)}
                                renderInput={
                                    (params) => (
                                        <TextField {...params} variant="outlined" placeholder="New name"/>
                                    )
                                }
                                renderOption={(option) => (
                                    <React.Fragment>
                                        <ListItemText>
                                            <Typography variant={"subtitle2"}>{option}</Typography>
                                        </ListItemText>
                                    </React.Fragment>
                                )}
                            />
                        </FormControl>
                    </div>
                </div>
                {updating && <div className="center">
                    <Typography color="primary" variant="subtitle2" style={{padding: '12px'}}>
                        Please wait, Saving changes and refreshing data...
                    </Typography>
                </div>}
            </DialogContent>
            <DialogActions className={"center"} style={{padding: "32px"}}>
                <Button disabled={disableAction} size={"small"} disableElevation
                        onClick={() => handleClose('no-refresh')}
                        variant={"contained"}>
                    Cancel
                </Button>
                <Button disabled={disableAction} size={"small"} onClick={() => handleSave('close')} color="primary"
                        variant={"contained"}>
                    Save Changes
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default RenameCategory;