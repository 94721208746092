import React from 'react';

function ProgressBar(props) {

    const {total, completed} = props;
    const value = (+(+completed / +total) * 100)?.toFixed(2);
    return (
        <div>
            <div className="container striped active">
                <div className="progressbar green" style={{width: `${value || 1}%`}}/>
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <div>{completed || 0}  files uploaded.</div>
                <div>{`${+(+total - +completed) || 0} remaining`}</div>
            </div>
        </div>
    )
}

export default ProgressBar;