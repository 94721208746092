import React from "react";
import MaterialTable from "material-table";
import tableIcons, {tableOptions} from "../../../components/universal/table_attributes";
import Typography from "@material-ui/core/Typography";
import {getCurrencyFormat} from "../../../lib/utils/common_utils";
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from "@material-ui/icons/Help";
import {useStyles} from "../TransactionList";

export function TimePeriodAggregateInfo(props) {
    return (
        <div style={{display: 'flex', alignItems: 'center'}}>
            <Typography variant="h6" display="inline">Time Period Aggregates</Typography>
            <Tooltip arrow title={
                <div style={{padding: '14px'}}>
                    <Typography variant={"body2"}>
                        Time period aggregates are calculated based on the number of statements included for
                        analysis.<br/>
                        <br/>
                        i.e. if 23 files are included for analysis in a batch of 24 - only Recent 6m, Previous 6m and
                        Recent 12m can be computed.
                    </Typography>
                </div>
            }>
                <HelpIcon color={"disabled"}/>
            </Tooltip>
        </div>
    )
}

function StatsByTimePeriod(props) {
    const classes = useStyles();
    const {loading, incomePeriod, allFilesProcessed, data} = props;
    const tenant = JSON.parse(sessionStorage.getItem('user'))?.['tenant_name']?.toUpperCase();
    const MITableOptions = {
        ...tableOptions,
        paging: false,
        filter: false,
        search: false,
        showTitle: false,
        toolbar: false
    }

    const getViewString = (rowData, key) => {
        if (rowData[key]?.toString()?.toLowerCase() === "n/a") {
            return "NA"
        }
        if (rowData?.is_count === "currency" || rowData?.is_count === false) {
            return getCurrencyFormat(rowData[key]);
        }
        if (rowData?.is_count === "count" || rowData?.is_count === true) {
            return rowData[key];
        }
        if (rowData?.is_count === "percent") {
            return `${rowData[key]}%`
        }

    }
    const getDescription = (val) => {
        if (val === "NSF Count") {
            return "Total NSF Count"
        }
        if (val === "OD Count") {
            return "Total OD Count"
        }
        return ` — ${val}`
    }

    const columns = [
        {
            title: 'Description',
            field: 'description',
            headerStyle: {
                textAlign: 'left'
            },
            cellStyle: {
                textAlign: 'left',
            },
            width: '20%',
            render: rowData => <Typography variant="body2">{getDescription(rowData?.description)}</Typography>
        },
        {
            title: 'Recent 12m',
            field: 'recent_12m',
            headerStyle: {
                textAlign: 'right',
                backgroundColor: incomePeriod === 'recent_12m' ? "#00ff0015" : ""
            },
            cellStyle: {
                textAlign: 'right',
                backgroundColor: incomePeriod === 'recent_12m' ? "#00ff0015" : ""
            },
            render: rowData => <Typography style={{paddingRight: '32px', letterSpacing: '1px'}}
                                           variant={incomePeriod === 'recent_12m' ? "body2" : "body1"}>
                {getViewString(rowData, 'recent_12m')}
            </Typography>
        },
        {
            title: 'Recent 24m',
            field: 'recent_24m',
            headerStyle: {
                textAlign: 'right',
                backgroundColor: incomePeriod === 'recent_24m' ? "#00ff0015" : ""
            },
            cellStyle: {
                textAlign: 'right',
                backgroundColor: incomePeriod === 'recent_24m' ? "#00ff0015" : ""
            },
            render: rowData => <Typography style={{paddingRight: '32px', letterSpacing: '1px'}}
                                           variant={incomePeriod === 'recent_24m' ? "body2" : "body1"}>
                {getViewString(rowData, 'recent_24m')}
            </Typography>
        },
        {
            title: 'All Available statements',
            field: 'overall',
            headerStyle: {
                textAlign: 'right',
                backgroundColor: incomePeriod === 'overall' ? "#00ff0015" : ""
            },
            cellStyle: {
                textAlign: 'right',
                backgroundColor: incomePeriod === 'overall' ? "#00ff0015" : ""
            },
            render: rowData => <Typography style={{paddingRight: '28px', letterSpacing: '1px'}}
                                           variant={incomePeriod === 'overall' ? "body2" : "body1"}>
                {getViewString(rowData, 'overall')}
            </Typography>
        },
    ];

    const filteredData = data?.filter((mtb) => mtb.is_count === 'count');
    return (
        <div className={classes.root}>
            <div style={{textAlign: 'left', width: '80%', padding: '24px 0 0 28px'}}>
                <Typography variant="h6">Count of NSF / OD Transactions</Typography>
            </div>
            <div className={classes.tableWrapper}>
                <MaterialTable
                    style={{
                        boxShadow: 'unset',
                        minHeight: '350px'
                    }}
                    title={""}
                    columns={columns}
                    isLoading={loading}
                    data={filteredData || []}
                    options={MITableOptions}
                    icons={tableIcons}
                    localization={{
                        body: {
                            emptyDataSourceMessage: 'No Data found',
                        }
                    }}
                />
            </div>
        </div>
    );
}

export default StatsByTimePeriod;
