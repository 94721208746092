import axios from "axios";

const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

export const request = function (options) {
    let accessToken = sessionStorage.getItem('access_token');
    let tenant = localStorage.getItem('currentTenant');

    const authHeaders = {
        Authorization: `Bearer ${accessToken}`
    }
    if(tenant) {
        authHeaders['tenant'] = tenant;
    }

    const client = axios.create({
        baseURL: BASE_API_URL,
        headers: options.headers || authHeaders,
        withCredentials: true
    });

    const onSuccess = function (response) {
        if (options.handleHeaders === 1) {
            if (response && response.data && response.data.data) {
                if(response.data.data.access_token){
                    sessionStorage.setItem('access_token', response.data.data.access_token);
                    sessionStorage.setItem('refresh_token', response.data.data.refresh_token);
                }
            }
        }
        if (options.handleHeaders === 0) {
            sessionStorage.clear();
            window.location.href = '/auth/signin';
        }
        const resp = response.data;
        if (resp.hasOwnProperty("success")) {
            return resp;
        } else {
            return {...resp, success: true}
        }
    };

    const onError = function (error) {
        if (options.handleHeaders === 0) {
            sessionStorage.clear();
        }
        if (error.response) {
            if ((error.response.status === 401 || error.response.status === 302) && options.handleHeaders !== 1) {
                // return refreshToken().then(_ => {
                //     const accessToken = sessionStorage.getItem('access_token');
                //     error.config.headers['Authorization'] = 'Bearer ' + accessToken;
                //     error.config.baseURL = undefined;
                //     return axios.request(error.config);
                // }).catch((e) => {
                //     window.location.href = '/auth/signin';
                // });
                const currentLocation = window.location.href;
                if( currentLocation.indexOf('auth') < 0){
                    localStorage.setItem('redirect_url', currentLocation);
                }
                window.location.href = '/auth/signin';
            }
        }

        //Check API and return response in accepted state with error data and success equals false flag to avoid catch block in all api requests.
        return Promise.reject(
            error.response && error.response.data ? {
                message: Array.isArray(error.response.data?.errors)? `Error: ${error.response.data?.errors?.join(", ")}` : error.response.data?.errors,
                success: false
            } : {
                message: "something went wrong",
                success: false
            });
    };

    return client(options)
        .then(onSuccess)
        .catch(onError);
};

export default request;


