import React, {useContext, useEffect, useState} from 'react';
import TextField from "@material-ui/core/TextField";
import AnalyticsClient from "../../../lib/services/api/analytics";
import {ToastMessageContext} from "../../../lib/contexts/message_context";
import LoadingIndicator from "../../../components/universal/loading_indicator";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import BackIcon from "@material-ui/icons/ArrowBackIos";
import Drawer from "@material-ui/core/Drawer";
import {useStyles} from "./file_selection";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Badge from "@material-ui/core/Badge";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import {getCurrencyFormat} from "../../../lib/utils/common_utils";

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;

const notesTemplates = [
    {
        key: "Income Subject to review",
        value: "NOTE: FINAL INCOME SUBJECT TO UNDERWRITING REVIEW\n"},
    {
        key: "Personal Review",
        value: "(Personal Review): Keep in mind that personal bank statements require the borrowers most recent (2) months business bank statements to be considered for our personal bank statement income documentation option. Business Bank statements must evidence activity to support business operations and transfers to the personal account being used.\n"
    },
    {
        key: "Business Review",
        value: "(Business Review): With the business bank statement review, it assumes 100% ownership, NSF’s need to be explained, wire transfers must be explained and consistent with the business operations in the business narrative form, large and/or unusual deposits may require additional documentation including letter of explanation, third party documentation, etc\n"
    },
    {
        key: "Removed Categories",
        value: "Removed the following:\n\n"},
    {
        key: "Allowed Deposits",
        value: "Allowed: All other deposits under the extraordinary assumption that deposits are S/E income.\n"
    },
    {
        key: "Large Deposit Documentation",
        value: "NOTE: Included large deposit(s) of [#LARGE_DEPOSIT_AMOUNTS#] – must be documented as business derived in order to be considered as eligible deposits."
    }
]

function AnalysisNotes(props) {
    const classes = useStyles();
    const {batchId, batchComments, loading, tenant, batchMeta, setRefresh, refresh, transactions} = props;
    const [showComments, setShowComments] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [comments, setComments] = useState("");
    const [selectedNotesTemplate, setSelectedNotesTemplate] = useState([]);
    const Toast = useContext(ToastMessageContext);

    useEffect(() => {
        setComments(batchComments);
        if (tenant === "DEEPHAVEN" && batchComments?.length > 3) {
            let selTemp = [];
            notesTemplates?.forEach((n) => {
                if(batchComments?.indexOf(n.value?.slice(0, 12)) > -1){
                    selTemp.push(n.key);
                }
            });
            setSelectedNotesTemplate(selTemp);
        }
        return (() => {
            setComments("");
            setSelectedNotesTemplate([]);
        })
    }, [batchId, batchComments, showComments]);

    const handleCommentsSave = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        setUpdating(true);
        formData.append('batch_id', batchId);
        AnalyticsClient.saveAnalysisNotes(batchId, formData).then((res) => {
            if (res.success) {
                Toast.showSuccess("Notes Saved Successfully");
                setRefresh(!refresh);
            }
        }).catch((e) => {
            Toast.showError(e?.message || "Something went wrong");
        }).finally(() => {
            setUpdating(false);
        })
    }

    const checkIfAlreadyExists = (comment, content) => {
        let flag;
        const op = content?.[0]['key'];
        switch (op) {
            case "Income Subject to review":
                flag = comment?.indexOf("NOTE: FINAL INCOME SUBJECT") > -1;
                break;
            case "Large Deposit Documentation":
                flag = comment?.indexOf("NOTE: Included large deposit") > -1;
                break;
            default:
                flag = comments?.indexOf(content?.[0]?.['value']?.slice(0, comments.indexOf(":"))) > -1;
                break;
        }
        return flag;
    }

    const handleNotesTypesSelection = (value, action) => {
        setSelectedNotesTemplate(typeof value === 'string' ? value.split(',') : value);
        const lastOpt = value.slice(-1);
        const content = notesTemplates.filter(t => t.key === lastOpt[0]);

        if(action === "select-option" && content?.[0]?.['value'] && !checkIfAlreadyExists(comments, content)){
            let newNotes = comments + "\n\n" + content[0]['value'];
            if(lastOpt[0] === "Removed Categories") {
                newNotes = newNotes + batchMeta?.excluded_categories?.replace(",", ", ")
            }
            if(lastOpt[0] === "Large Deposit Documentation") {
                const largeDeposits = transactions?.filter(txn => (txn['other_flag']?.length > 1 && !txn['flag'] && txn['account_class'] !== "Exclude"))?.map((txn) => `$${getCurrencyFormat(txn['amount'])}`);
                if(largeDeposits?.length > 1){
                    let largeDepString = largeDeposits?.slice(0, largeDeposits?.length - 1).join(", ");
                    if(largeDeposits?.length > 1) {
                        largeDepString = largeDepString + " and " + largeDeposits.slice(-1)[0];
                    }
                    newNotes = newNotes?.replace("[#LARGE_DEPOSIT_AMOUNTS#]", largeDepString);
                }
                else if(largeDeposits?.length === 1) {
                    newNotes = newNotes?.replace("[#LARGE_DEPOSIT_AMOUNTS#]", largeDeposits[0]);
                }
                else {
                    newNotes = newNotes?.replace("[#LARGE_DEPOSIT_AMOUNTS#]","[No Included Large deposits found]");
                }
            }
            newNotes = newNotes + "\n\n"
            setComments(newNotes);
        }
    }

    const notesTemplateOptions = notesTemplates?.map(t => t.key);

    return (
        <div>
            <Button size={"small"} color={"primary"} type="text" onClick={() => setShowComments(true)}>
                <Badge color={"secondary"} variant={"dot"} invisible={!!comments?.length < 2}>
                    {comments?.length > 2 ? "View" : "Add"} Notes
                </Badge>
            </Button>
            <Drawer className={classes.noOverflow} anchor={'right'} open={showComments}
                    onClose={() => setShowComments(false)}>
                <div className={`${classes.wrapper}`}>
                    <Toolbar style={{
                        position: 'absolute',
                        top: '0px',
                        width: '25vw',
                        background: '#fff',
                        zIndex: 375
                    }}>
                        <div style={{textAlign: 'left', background: '#fff', display: 'flex', alignItems: 'center'}}>
                            <IconButton disableFocusRipple disableRipple onClick={() => setShowComments(false)}>
                                <BackIcon color={"primary"}/>
                            </IconButton>
                            <Typography className={classes.sectionTitle} variant={"h6"} component="div" gutterBottom>
                                Analysis Notes
                            </Typography>
                        </div>
                    </Toolbar>
                    <div style={{padding: '65px 35px 75px 35px'}}>
                        <div style={{width: '25vw'}}/>
                        {tenant === "DEEPHAVEN" && (
                            <div>
                                <Typography variant="subtitle2" color="textSecondary">
                                    Notes Options
                                </Typography>
                                <FormControl fullWidth style={{marginTop: 0, maxWidth: '24vw'}}>
                                    <Autocomplete
                                        fullWidth
                                        multiple
                                        options={notesTemplateOptions}
                                        disableCloseOnSelect
                                        disableClearable
                                        size="small"
                                        style={{width: "24vw"}}
                                        value={selectedNotesTemplate}
                                        onChange={(event, value, action) => handleNotesTypesSelection(value, action)}
                                        renderOption={(option, {selected}) => (
                                            <React.Fragment>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{marginRight: 8}}
                                                    checked={selected}
                                                />
                                                {option}
                                            </React.Fragment>
                                        )}
                                        renderInput={(params) => (
                                            <TextField {...params} variant="outlined" size="small"/>
                                        )}
                                    />
                                    <Typography color={"secondary"} variant={"caption"} component="div" gutterBottom
                                                style={{fontSize: 10, fontWeight: 400}}>
                                        *Removing templates from here will not remove from notes
                                    </Typography>
                                </FormControl>
                            </div>
                        )}
                        {loading || updating && <div><LoadingIndicator variant={"block"}/></div>}
                        {!loading && !updating && <form onSubmit={handleCommentsSave}>
                            <TextField
                                fullWidth
                                label="Detailed Notes"
                                multiline
                                rows={20}
                                required
                                name={"comments"}
                                value={comments}
                                onChange={(e) => setComments(e.target.value)}
                                variant="outlined"
                                InputLabelProps={{shrink: true, style: {fontWeight: 'bold'}}}
                            />
                            <Typography color={"secondary"} variant={"caption"} component="div" gutterBottom
                                        style={{fontSize: 10, fontWeight: 400, marginBottom: '20px', maxWidth: '25vw'}}>
                                *Special characters, copied linebreaks would appear as plain strings.
                                {tenant === "DEEPHAVEN" && (<><br/>*Updates made to analysis post notes addition will not update notes</>)}
                            </Typography>
                            <Button
                                fullWidth
                                type="submit"
                                disabled={updating}
                                color={"primary"}
                                variant={"contained"}
                                startIcon={updating && <CircularProgress size={15}/>}
                            >
                                {comments?.length > 3 ? 'Save' : 'Add'} Notes
                            </Button>
                        </form>}
                    </div>
                </div>
            </Drawer>
        </div>
    )
}

export default AnalysisNotes;