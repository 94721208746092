import React, {useContext, useEffect, useState} from "react";
import AnalyticsClient from "../../../lib/services/api/analytics";
import {ToastMessageContext} from "../../../lib/contexts/message_context";
import Typography from "@material-ui/core/Typography";
import {unique} from "../../../lib/utils/common_utils";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import CashFlowChart from "../AnalysisCharts/cashflow_chart";
import MonthlyDepositsChart from "../AnalysisCharts/monthly_deposits_chart";
import TopCategoriesChart from "../AnalysisCharts/top_categories_chart";
import AverageBalanceChart from "../AnalysisCharts/average_balance_chart";
import CashflowRatioCharts from "../AnalysisCharts/cashflow_ratio_charts";

function AccountSummaryDetail(props) {
    const {batchId, qualifiedIncome, refresh, txnTypeContext} = props;
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [chartData, setChartData] = useState({});
    const [filteredData, setFilteredData] = useState([]);
    const [accountNumbers, setAccountNumbers] = useState([]);
    const [currentAcc, setCurrentAcc] = useState('All');
    const Toast = useContext(ToastMessageContext);

    useEffect(() => {
        setLoading(true);
        AnalyticsClient.getAccountSummary(batchId).then((res) => {
            if (res.success) {
                setData(res['data']);
                setFilteredData(res['data']);
                const accNum = res['data']?.map(d => d.account_class?.toUpperCase() !== "EXCLUDE" ? d.account_number : null);
                setAccountNumbers(unique(accNum));
            }
            setLoading(false);
        }).catch((e) => {
            Toast.showError(e.message || "Something went wrong");
            setLoading(false);
        });
        setLoading(true);
        AnalyticsClient.getCashFlowChartData(batchId).then((res) => {
            if (res?.data) {
                setChartData(res?.data);
                setLoading(false);
            }
        }).catch((e) => {
            setLoading(false);
        });
    }, [batchId, refresh]);

    useEffect(() => {
        if(currentAcc !== "All") {
            const filtered = data?.filter(d => d['account_number'] === currentAcc);
            setFilteredData(filtered);
        }
        else {
            setFilteredData(data);
        }
    }, [currentAcc]);

    const allAccNums = ["All"].concat(accountNumbers);
    return (
        <div>
            <div className={"center"} style={{justifyContent: 'flex-start', alignItems: 'flex-start', padding: '0 16px 16px 10px'}}>
                <Typography variant="subtitle2" color="textSecondary">
                    Filter Account Number: &nbsp;
                </Typography>
                <div>
                    {allAccNums?.map((op, i) => (
                        <Chip
                            variant={currentAcc === op  ? "default" : "outlined"}
                            key={op}
                            size="small"
                            style={{marginRight: '5px', marginBottom: '12px'}}
                            onClick={() => setCurrentAcc(op)}
                            clickable
                            color={currentAcc === op ? "primary" : "default"}
                            label={op}
                        />
                    ))
                    }
                </div>
            </div>
            {chartData?.cash_flow && <Grid container spacing={2} style={{marginBottom: '64px'}}>
                <Grid item md={12}>
                    <MonthlyDepositsChart loading={loading} title={`Deposits and Withdrawals (${currentAcc})`} selectedAccount={currentAcc} data={chartData?.monthly_transaction}/>
                </Grid>
                <Grid item md={12}>
                    <TopCategoriesChart loading={loading} title={`Top Deposit Categories (${currentAcc})`} selectedAccount={currentAcc} data={chartData?.top_categories}/>
                </Grid>
            </Grid>}
        </div>
    )
}

export default AccountSummaryDetail;