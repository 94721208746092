import React from 'react';
import tableIcons, {tableOptions} from "../../components/universal/table_attributes";
import MaterialTable from "material-table";
import Typography from "@material-ui/core/Typography";
import {ListItem, ListItemText} from "@material-ui/core";
import List from "@material-ui/core/List";

function BatchFileManualErrorWarning (props) {

    const {errorFlags} = props;
    const fileWiseErrors = errorFlags?.file_wise_error?.filter(f => f.errors?.length) || [];
    const batchWiseErrors = errorFlags?.batch_error?.filter(e => e.description) || [];
    const MITableOptions = {
        ...tableOptions,
        toolbar: false,
        showTitle: true,
        paging: false,
        filter: false,
        search: false,
        selectableRowsOnClick: false,
    }
    const columns = [
        {
            title: 'Filename',
            field: 'filename',
            width: '350px',
            headerStyle: {
                textAlign: 'left',
                padding: 'auto 0'
            },
            cellStyle: {
                textAlign: 'left',
            }
        },
        {
            title: 'Flagged Items',
            field: 'errors',
            headerStyle: {
                textAlign: 'left',
                padding: 'auto 0'
            },
            cellStyle: {
                textAlign: 'left',
            },
            render: rowData =>
                    <Typography component={"div"} variant={'body1'} gutterBottom>
                        {rowData.errors?.join(",")}
                    </Typography>
        }
    ];
    return (
        <div style={{border:' 2px solid #ffcaa2', margin:'10px 0'}}>
            {batchWiseErrors?.length > 0 && <><Typography variant={"subtitle2"} style={{margin:'8px 0 0 16px'}}> Batch level Warnings</Typography>
            <List>
                {batchWiseErrors?.map((e, i) => <ListItem style={{marginLeft:'24px', display:'block'}}>
                    <Typography component={"div"} variant={"subtitle1"}>{i+1}. {e.description}</Typography>
                    <Typography component={"div"} variant={"body1"} style={{marginLeft:'24px'}}>{e.errors?.join(", ")}</Typography>
                </ListItem>)}
            </List>
            </>}
            {fileWiseErrors?.length > 0 && <><Typography variant={"subtitle2"} style={{margin:'8px 0 0 16px'}}> File wise Warnings</Typography>
            <MaterialTable
                style={{boxShadow: 'unset', fontSize: '12px'}}
                columns={columns}
                data={fileWiseErrors || []}
                options={MITableOptions}
                icons={tableIcons}
            /></>}
        </div>
    )
}

export default BatchFileManualErrorWarning;