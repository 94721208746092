import {makeStyles} from "@material-ui/core/styles";

const useAuthStyles = makeStyles(theme => ({
    wrapper: {
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background:'rgba(2,124,71,0.19)'
    },
    formWrapper: {
        zIndex: 99,
        padding: 25,
        boxShadow: '7px 7px 80px 25px rgb(51 51 51 / 6%)',
        background: '#fff',
        borderRadius: 20
    },
    form: {
        maxWidth: '375px',
        '& >div.center > img':{
            paddingBottom:'20px'
        },
        margin: 'auto'
    },
    formField: {
        margin: '20px 0',
        '& .MuiFormHelperText-root': {
            color: '#f96161'
        }
    },

}));

export default useAuthStyles;
