import React, {useContext, useEffect, useState} from 'react';
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import Popper from "@material-ui/core/Popper";
import AnalyticsClient from "../../../lib/services/api/analytics";
import {ToastMessageContext} from "../../../lib/contexts/message_context";
import LoadingIndicator from "../../../components/universal/loading_indicator";
import List from "@material-ui/core/List";
import Grid from "@material-ui/core/Grid";
import {getCurrencyFormat} from "../../../lib/utils/common_utils";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";

function FileUpdated(props) {
    const {fileName, detail, field} = props;
    const updatedValue = detail?.updated_values?.[field]?.['new_value'];
    const message = updatedValue ? <><b style={{color: 'rgb(220, 0, 78)'}}>&nbsp;Included</b> for
        Analysis</> : <><b style={{color: 'rgb(220, 0, 78)'}}>&nbsp;Excluded</b> from Analysis</>
    return (
        field === 'includes_analysis' && <Typography style={{padding: '15px'}}>
            File <i style={{color: 'rgb(220, 0, 78)'}}>{fileName}</i> {message}
        </Typography>
    )
}

function NewFilesAdded(props) {
    const {detail, field} = props;
    const updatedValue = detail?.updated_values?.[field]?.['new_value'];
    return (
        <List>
            {updatedValue?.map((f, i) => <ListItem key={f}>{i + 1}.&nbsp; <i style={{color: 'rgb(220, 0, 78)'}}>{f}</i></ListItem>)}
        </List>
    )
}


function AccountClassUpdated(props) {
    const {detail, field} = props;
    let oldValue = detail?.[field]?.['old_value']?.toString().replace(/_/g, ' ');
    let newValue = detail?.[field]?.['new_value']?.toString().replace(/_/g, ' ');
    return (
        <ul>
            <li style={{marginTop: '10px'}} key={field}>
                <Typography variant={"body1"} component={"span"}>
                    {field?.replace(/_/g, ' ')}'s
                </Typography>
                <Typography variant={"body1"} component={"span"}>
                    &nbsp; account class updated from &nbsp;
                </Typography>
                <Typography className={"capitalize"} variant={"subtitle2"} component={"span"}
                            style={{color: 'rgb(220, 0, 78)'}}>
                    {oldValue}
                </Typography>
                <Typography variant={"body1"} component={"span"}>
                    &nbsp;to &nbsp;
                </Typography>
                <Typography className={"capitalize"} variant={"subtitle2"} component={"span"} color="primary">
                    {newValue}
                </Typography>
            </li>
        </ul>
    )
}

function QIIncomePeriodUpdated(props) {
    const {field, fieldDetail} = props;
    let oldValue = fieldDetail?.[field]?.['old_value']?.toString().replace(/_/g, ' ') || "default";
    let newValue = fieldDetail?.[field]?.['new_value']?.toString().replace(/_/g, ' ');
    return (
        <ul>
            <li style={{marginTop: '10px'}} key={field}>
                <Typography variant={"body1"} component={"span"} className={"capitalize"}>
                    {field?.replace(/_/g, ' ')}
                </Typography>
                <Typography variant={"body1"} component={"span"}>
                    &nbsp;updated from &nbsp;
                </Typography>
                <Typography className={"capitalize"} variant={"subtitle2"} component={"span"}
                            style={{color: 'rgb(220, 0, 78)'}}>
                    {oldValue}
                </Typography>
                <Typography variant={"body1"} component={"span"}>
                    &nbsp;to &nbsp;
                </Typography>
                <Typography className={"capitalize"} variant={"subtitle2"} component={"span"} color="primary">
                    {newValue}
                </Typography>
            </li>
        </ul>
    )
}


function IncomeFactorsUpdated(props) {
    const {field, fieldDetail} = props;
    let oldValue = fieldDetail?.[field]?.['old_value']?.toString().replace(/_/g, ' ');
    let newValue = fieldDetail?.[field]?.['new_value']?.toString().replace(/_/g, ' ');
    if (field?.toLowerCase()?.indexOf('net_income') > -1) {
        oldValue = getCurrencyFormat(oldValue);
        newValue = getCurrencyFormat(newValue);
    }
    if ((field?.toLowerCase()?.indexOf('expense_factor') > -1) || (field?.toLowerCase()?.indexOf('ownership') > -1)) {
        oldValue = `${oldValue}%`;
        newValue = `${newValue}%`;
    }
    return (
        <ul>
            <li style={{marginTop: '10px'}} key={field}>
                <Typography variant={"body1"} component={"span"} className={"capitalize"}>
                    {field?.replace(/_/g, ' ')}
                </Typography>
                <Typography variant={"body1"} component={"span"}>
                    &nbsp;updated from &nbsp;
                </Typography>
                <Typography className={"capitalize"} variant={"subtitle2"} component={"span"}
                            style={{color: 'rgb(220, 0, 78)'}}>
                    {oldValue}
                </Typography>
                <Typography variant={"body1"} component={"span"}>
                    &nbsp;to &nbsp;
                </Typography>
                <Typography className={"capitalize"} variant={"subtitle2"} component={"span"} color="primary">
                    {newValue}
                </Typography>
            </li>
        </ul>
    )
}

function AccountLargeDepositThresholdUpdated(props) {
    const {fieldDetail} = props;
    let oldValue = fieldDetail?.['account_large_deposit_thresholds']?.['old_value'];
    let newValue = fieldDetail?.['account_large_deposit_thresholds']?.['new_value'];

    const accountNums = Object.keys(oldValue);

    return (
        <ul>
            {accountNums?.map((acc, i) =>(
                oldValue[acc] !== newValue[acc] && <li style={{marginTop: '10px'}} key={acc}>
                    <Typography variant={"body1"} component={"span"} className={"capitalize"}>
                        {acc}&nbsp; Large Deposit Threshold
                    </Typography>
                    <Typography variant={"body1"} component={"span"}>
                        &nbsp;updated from &nbsp;
                    </Typography>
                    <Typography className={"capitalize"} variant={"subtitle2"} component={"span"}
                                style={{color: 'rgb(220, 0, 78)'}}>
                        {oldValue[acc]}
                    </Typography>
                    <Typography variant={"body1"} component={"span"}>
                        &nbsp;to &nbsp;
                    </Typography>
                    <Typography className={"capitalize"} variant={"subtitle2"} component={"span"} color="primary">
                        {newValue[acc]}
                    </Typography>
                </li>
            ))}
        </ul>
    )
}

function QualifiedIncomeUpdated(props) {
    const {field, fieldDetail} = props;
    return (
        <div style={{paddingLeft: '20px'}}>
            <Typography variant={"body1"} component={"span"} className={"capitalize"} gutterBottom>
                Qualified Income after updates changed from
            </Typography>
            <Typography variant={"subtitle2"} component={"span"} style={{color: 'rgb(220, 0, 78)'}} gutterBottom>
                &nbsp;{getCurrencyFormat(fieldDetail?.[field]?.['old_value'])}
            </Typography>
            <Typography variant={"body1"} component={"span"} gutterBottom>
                &nbsp;to &nbsp;
            </Typography>
            <Typography variant={"subtitle2"} component={"span"} color="primary" gutterBottom>
                {getCurrencyFormat(fieldDetail?.[field]?.['new_value'])}
            </Typography>
        </div>
    )
}

function TransactionDetail(props) {
    const {transactions, batchId, setShowDetails} = props;
    const [loading, setLoading] = useState();
    const [deposits, setDeposits] = useState([]);
    const Toast = useContext(ToastMessageContext);

    useEffect(() => {
        setLoading(true);
        AnalyticsClient.getTransactionList(batchId).then((res) => {
            if (res?.success) {
                let txnList = [];
                if (res?.data?.deposits?.length > 0) {
                    txnList = txnList.concat(res?.data?.deposits);
                }
                setDeposits(txnList);
            }
        }).catch((e) => {
            Toast.showError("Something went wrong.");
        }).finally(() => {
            setLoading(false);
        })
    }, [batchId]);

    const filteredTxn = deposits.filter((f) => transactions.indexOf(f.hash_key) >= 0 || transactions.indexOf(f.txn_id) >= 0);

    return (
        <Paper style={{padding: '3%'}}>
            <div className={"corner-aligned"}>
                <Typography variant={"h6"}>
                    Transaction Details
                </Typography>
                <div>
                    <Button size={"small"} onClick={() => setShowDetails(null)}>close</Button>
                </div>
            </div>

            {loading &&
            <div className={"transaction-detail-loader"}><LoadingIndicator
                variant={"loadingWrapper"}/>
            </div>}
            {!loading && <div className={"transaction-detail-list"}>
                <div>
                    <List>
                        <ListItem>
                            <Grid container>
                                <Grid item xs={3}>
                                    <Typography variant={"subtitle2"} color={"primary"}>Date</Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography variant={"subtitle2"} color={"primary"}>Short Description</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant={"subtitle2"} color={"primary"}>Amount</Typography>
                                </Grid>
                            </Grid>
                        </ListItem>
                        <Divider/>
                        {filteredTxn.map((txn) => (
                            <ListItem>
                                <Grid container>
                                    <Grid item xs={3}>
                                        <Typography variant={"subtitle1"}>{txn.date}</Typography>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Typography variant={"subtitle1"}>{txn.short_description}</Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant={"subtitle2"}>{getCurrencyFormat(txn.amount)}</Typography>
                                    </Grid>
                                </Grid>
                            </ListItem>
                        ))}
                    </List>
                </div>
            </div>}
        </Paper>
    )
}

function TransactionUpdated(props) {
    const {transactions, message, newValue, batchId, open, handleOpen} = props;
    const [showDetails, setShowDetails] = useState(false);
    const numOfTxns = transactions.filter((e) => e !== 'qualified_income');
    return (
        <div>
            <Typography style={{padding: '15px'}} gutterBottom>
                <b style={{fontSize: '16px'}}>
                    {numOfTxns?.length} &nbsp;
                </b>
                number of {message}
                <b style={{color: 'rgb(220, 0, 78)'}}>{newValue}</b>
            </Typography>
            <Typography className={"clickable"} style={{marginLeft: '15px'}} variant={"text"} size={"small"}
                        color={Boolean(showDetails) ? "secondary" : "primary"}
                        onClick={(e) => {
                            Boolean(showDetails) ? setShowDetails(null) : setShowDetails(e.currentTarget);
                        }}>
                {Boolean(showDetails) ? "<< Details on left (click to minimize)" : "View more details"}
            </Typography>
            <Popper
                style={{position: 'fixed', top: 0, right: '35vw', bottom: '0', left: '30vw'}}
                open={Boolean(showDetails)}
                placement="left"
                disablePortal={true}
                modifiers={{
                    flip: {
                        enabled: true,
                    },
                    preventOverflow: {
                        enabled: true,
                        boundariesElement: 'disabled',
                    },
                    arrow: {
                        enabled: true,
                        element: showDetails,
                    },
                }}
            >
                <TransactionDetail transactions={transactions} batchId={batchId} setShowDetails={setShowDetails}/>
            </Popper>
        </div>
    )
}

function CategoryRenamed(props) {
    const {field, fieldDetail, batchId} = props;
    return (
        field !== 'transaction' ? (<div style={{paddingLeft: '20px'}}>
            <Typography variant={"body1"} component={"span"} className={"capitalize"} gutterBottom>
                Category &nbsp;
            </Typography>
            <Typography variant={"subtitle2"} component={"span"} style={{color: 'rgb(220, 0, 78)'}} gutterBottom>
                {fieldDetail?.[field]?.['old_value']?.join(',')}
            </Typography>
            <Typography variant={"body1"} component={"span"} gutterBottom>
                &nbsp;renamed as &nbsp;
            </Typography>
            <Typography variant={"subtitle2"} component={"span"} color="primary" gutterBottom>
                {fieldDetail?.[field]?.['new_value']}
            </Typography>
        </div>) : (
            <TransactionUpdated batchId={batchId} transactions={fieldDetail?.[field]?.['old_value']}
                                message={"Transaction category renamed"}
                                newValue={`to ${fieldDetail?.[field]?.['new_value']}`}/>
        )
    )
}

function ActivityDetail(props) {
    const {activityDetail, batchId} = props;
    const updatedFields = activityDetail?.updated_values?.updated_fields || [];
    const fieldDetail = activityDetail?.updated_values;
    const [open, setOpen] = useState(false);
    return (
        <div style={{padding: '15px', marginLeft: '10px', borderLeft: '1px solid #d5d5d5'}}
             onClick={() => setOpen(false)}>
            <Typography variant={"subtitle1"} display={"inline"} gutterBottom>
                {activityDetail?.message} by&nbsp;
            </Typography>
            <Typography variant={"subtitle1"} display={"inline"} color={"primary"} gutterBottom>
                {activityDetail?.requested_by?.replace("SYSTEM", "Batch Owner")}
            </Typography>
            {activityDetail?.message?.indexOf('Transaction') !== -1 &&
            <TransactionUpdated transactions={updatedFields} message={activityDetail?.message} batchId={batchId}
                                open={open} handleOpen={(v) => setOpen(v)}/>}
            {updatedFields.map((field, i) => {
                return (
                    <React.Fragment key={field}>
                        {field === 'new_files_added' &&
                        <NewFilesAdded field={field} detail={activityDetail}/>}
                        {(field?.indexOf(":") > -1 || field?.indexOf("**") > -1) &&
                        <AccountClassUpdated field={field} detail={fieldDetail}/>}
                        {activityDetail?.message?.indexOf('File') !== -1 &&
                        <FileUpdated fileName={activityDetail.file_name} field={field} detail={activityDetail}/>}
                        {activityDetail?.message?.indexOf('Category') !== -1 &&
                        <CategoryRenamed field={field} fieldDetail={fieldDetail} batchId={batchId}/>}
                        {activityDetail?.message?.indexOf('factors') !== -1 && field !== "" && field !== "qualified_income" &&
                        <IncomeFactorsUpdated field={field} fieldDetail={fieldDetail}/>}
                        {field === 'income_period' &&
                        <QIIncomePeriodUpdated field={field} fieldDetail={fieldDetail}/>}
                        {activityDetail?.message?.indexOf('threshold') !== -1 && field !== "qualified_income" &&
                        <AccountLargeDepositThresholdUpdated fieldDetail={fieldDetail}/>}
                        {field === 'qualified_income' &&
                        <QualifiedIncomeUpdated field={field} fieldDetail={fieldDetail}/>}
                    </React.Fragment>
                )
            })}
        </div>
    )
}

export default ActivityDetail;
