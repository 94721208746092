import request from "./common/api_client";

function getOverallStats(params) {
    return request({
        url: '/stats',
        params: params
    })
}

function getBatchStats(params) {
    return request({
        url: '/batch/stats',
        params: params
    })
}

function getBatchReportDownloadUrl(params) {
    return request({
        url: '/batch/report',
        params: params
    })
}

function getReportDownloadURL(params) {
    return request({
        url: '/v1/admin/report/download',
        params: params
    })
}

function getAutoRunStats(formData) {
    return request({
        url: '/v1/admin/internal-stats/auto-vs-manual',
        data: formData,
        method: 'POST'
    })
}

const StatsClient = {
    getOverallStats, getBatchStats, getBatchReportDownloadUrl, getReportDownloadURL,getAutoRunStats
}

export default StatsClient;
