import React, {useContext, useEffect, useReducer, useState} from "react";
import Button from "@material-ui/core/Button";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import PasswordVisible from "@material-ui/icons/Visibility";
import PasswordHide from "@material-ui/icons/VisibilityOff";
import {checkInternalEmails, validateEmail} from "../../lib/utils/common_utils";
import UserIcon from "@material-ui/icons/PersonOutlined";
import LockIcon from "@material-ui/icons/LockOutlined";
import useAuthStyles from "./auth_styles";
import authReducer, {INITIAL_STATE} from "../../reducers/auth_reducer";
import AuthActions from "../../actions/auth_actions";
import CircularProgress from "@material-ui/core/CircularProgress";
import {MainBrandingWidget, PoweredByWidget} from "./branding_widgets";
import AuthenticationHelpDialog from "../../components/universal/auth_help";
import {msalInstance} from "../../index";
import {loginRequest} from "../../config/msal";
import AuthClient from "../../lib/services/api/auth";
import {ToastMessageContext} from "../../lib/contexts/message_context";


export default function SignIn(props) {
  const classes = useAuthStyles();
  const [authState, dispatch] = useReducer(authReducer, INITIAL_STATE);
  const { loading, error, mask, message } = authState;
  const [signingIn, setSigningIn] = useState(false);
  const [open, setOpen] = useState(false);
  const Toast = useContext(ToastMessageContext);

  const exchangeMSTokenForAccessToken = (headers) => {
    AuthClient.exchangeToken(headers).then((res) => {
      sessionStorage.setItem('access_token', res.data?.access_token);
      AuthActions.getUserDetails(dispatch);
      setSigningIn(false);
    }).catch((e) => {
      AuthActions.setError(dispatch, {
        msal: "Error verifying credentials. Close the browser and try again",
      });
      setSigningIn(false);
    })
  }

  const handleMSSignIn = () => {
    localStorage.setItem("login_type", 'sso');
    try {
      setSigningIn(true);
      msalInstance.acquireTokenSilent(loginRequest).then((res) => {
        const headers = {'Authorization': `Bearer ${res.accessToken}`};
        exchangeMSTokenForAccessToken(headers);
      }).catch((e) => {
        msalInstance.loginPopup(loginRequest).then((res) => {
          msalInstance.acquireTokenSilent(loginRequest).then((res) => {
            const headers = {'Authorization': `Bearer ${res.accessToken}`};
            exchangeMSTokenForAccessToken(headers);
          }).catch((e) => {
            AuthActions.setError(dispatch, {
              msal: "Couldn't Login. Please close the browser and try again",
            });
            setSigningIn(false);
          })
        }).catch((e) => {
          AuthActions.setError(dispatch, {
            msal: "Error Logging in. Please close the browser and try again",
          });
          setSigningIn(false);
        });
      })
    } catch (error) {
      AuthActions.setError(dispatch, {
        msal: "Something went wrong. Try again",
      });
      setSigningIn(false);
    }
  };

  const handleSubmit = (e) => {
    localStorage.setItem("login_type", 'username');
    e.preventDefault();
    const formData = new FormData(e.target);
    const email = formData.get("eid")?.trim()?.toLowerCase();
    const password = formData.get("secret_phrase");
    if (!validateEmail(email)) {
      AuthActions.setError(dispatch, {
        email: "invalid format, provide valid email",
      });
      return;
    }
    if(!checkInternalEmails(email)){
      AuthActions.setError(dispatch, {
        email: "Not a valid user account for this application",
      });
      return;
    }
    const newFormData = new FormData();
    newFormData.append("username", email);
    newFormData.append("password", password?.trim());
    AuthActions.userSignIn(dispatch, newFormData, props.history);

  };

  useEffect(() => {
    AuthActions.userSignOut(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearError = () => {
    AuthActions.setError(dispatch, { email: false });
  };
  const handleMasked = () => {
    AuthActions.changeFieldMask(dispatch);
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.formWrapper}>
        <form
          className={classes.form}
          onSubmit={handleSubmit}
          autoComplete="custom-form"
        >
          <MainBrandingWidget/>
          <Typography
            align="center"
            component={"h6"}
            color={"primary"}
            variant={"h6"}
          >
            Login
          </Typography>
          <div>
            <Button startIcon={signingIn && <CircularProgress size={14} />} disabled={signingIn || loading} style={{border:'1px solid #d5d5d5', marginTop: '8px'}} fullWidth variant={"text"} onClick={handleMSSignIn}> Sign in using Microsoft</Button>
            <Typography style={{marginTop:'12px'}} align={"center"} color={"textSecondary"} variant={"caption"} component={"div"}>OR</Typography>
          </div>
          <FormControl fullWidth className={classes.formField}>
            <OutlinedInput
              required
              name="eid"
              autoComplete="new-password"
              placeholder="Username or Email"
              autoFocus
              error={!!error.email}
              onFocus={clearError}
              startAdornment={
                <InputAdornment position="start">
                  <UserIcon />
                </InputAdornment>
              }
            />
            {!!error.email && (
              <FormHelperText>
                {error.email ? error.email : null}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl fullWidth className={classes.formField}>
            <OutlinedInput
              required
              startAdornment={
                <InputAdornment position="start">
                  <LockIcon />
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  {!mask ? (
                    <PasswordVisible
                      className={"clickable"}
                      onClick={handleMasked}
                    />
                  ) : (
                      <PasswordHide
                        className={"clickable"}
                        onClick={handleMasked}
                      />
                    )}
                </InputAdornment>
              }
              name="secret_phrase"
              type={mask ? "password" : "text"}
              placeholder={"Password"}
              autoComplete="new-password"
              onFocus={clearError}
            />
          </FormControl>
          <div style={{ marginBottom: "20px" }}>
            <Typography
              component={"span"}
              className={"clickable"}
              color={"textSecondary"}
              onClick={() => props.history.push("/auth/forgot-password")}
            >
              Forgot password?
            </Typography>
          </div>
          <Button
            fullWidth
            type="submit"
            className={classes.submit}
            disabled={loading || signingIn}
            color={"primary"}
            variant={"contained"}
            startIcon={loading && <CircularProgress size={15} />}
          >
            Sign In
          </Button>
          <div style={{ textAlign: "center", margin: "15px 0" }}>
            <span style={{ marginTop: "20px", color: "red" }}>
              {error.message || error.msal || null}
            </span>
          </div>
        </form>
        <Typography
            align={"center"}
            className={"clickable"}
            color={"textSecondary"}
            style={{textDecoration:"underline"}}
            onClick={() => setOpen(true) }
        >
          Trouble signing-in?
        </Typography>
        <AuthenticationHelpDialog open={open} setOpen={setOpen}/>
        <PoweredByWidget/>
      </div>
    </div>
  );
}
