import React, {useContext, useEffect, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import AnalyticsClient from "../../../lib/services/api/analytics";
import LoadingIndicator from "../../../components/universal/loading_indicator";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import {ListItem, Typography} from "@material-ui/core";
import {ToastMessageContext} from "../../../lib/contexts/message_context";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Accordion from "@material-ui/core/Accordion";
import {useStyles} from "./index";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function HighlightedPdfViewer(props) {
    const classes = useStyles();
    const {handleClose, batchId, data, total} = props;
    const [pdfLink, setPdfLink] = useState(null);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [currentlyViewing, setCurrentlyViewing] = useState(null);
    const Toast = useContext(ToastMessageContext);

    const issues = data?.additional_data;
    const categorisedIssues = {};

    issues.forEach((val, i, arr)=>{
        if(val?.type in categorisedIssues){
            categorisedIssues[val?.type] = categorisedIssues[val?.type].concat([val]);
        }
        else {
            categorisedIssues[val?.type] = [val];
        }
    });

    useEffect(() => {
        if (batchId) {
            setLoading(true);
            const formData = {
                "filename": data?.filename
            }
            AnalyticsClient.getRiskHighlightedFile(batchId, formData).then((res) => {
                setLoading(false);
                if (res?.success) {
                    setPdfLink(res.data?.[0]?.uri);
                }
            }).catch((e) => {
                setLoading(false);
                Toast.showError(e.message || "Something went wrong");
            })
        }
    }, [batchId]);

    const handlePageChange = (p, i) => {
        setLoading(true);
        setCurrentlyViewing(i);
        setTimeout(()=>{
            setPage(p);
            setLoading(false);
        }, 1000);
    }

    return (
        <Dialog fullScreen open={true} onClose={handleClose} TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <div className={"corner-aligned"} style={{width:'100%'}}>
                        <Typography style={{color:"#fff"}} component={"div"} variant={"subtitle2"}>
                            {data?.filename}
                        </Typography>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon/>
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            <Grid container spacing={3}>
                <Grid item md={3}>
                    <Typography variant={"subtitle2"} style={{fontSize:'18px', padding:"24px 0 0 24px", marginBottom:'24px'}}>
                        Identified Issues &nbsp;{`(${total})`}
                    </Typography>
                    {Object.entries(categorisedIssues)?.map(([key, value]) => (<Accordion
                            className={`${classes.accordionTitle}`}
                            style={{marginBottom: "16px", boxShadow: "unset", padding:'0 16px'}}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                <Typography className={classes.heading}>{key} ({value?.length})</Typography>
                            </AccordionSummary>
                            <AccordionDetails style={{padding:0}}>
                                <List style={{height: '600px', overflowY:'scroll', width:'100%'}}>
                                    {value?.map((issue, i) => (<ListItem button key={i} onClick={()=> handlePageChange(issue?.page, i)}>
                                        <div className={`${classes.listItemCard} ${currentlyViewing === i ? "active": ""}`}>
                                            <Typography variant={"subtitle1"}>Page: {issue?.page}</Typography>
                                            <Typography color={"error"} className={"wordWrap"}>{issue?.word}</Typography>
                                        </div>
                                    </ListItem>))}
                                </List>
                            </AccordionDetails>
                        </Accordion>))
                    }
                </Grid>
                <Grid item md={9} style={{backgroundColor:"#6b6b6b", minHeight:'700px'}}>
                    {!loading && pdfLink && <div>
                        <object data={`${pdfLink}#navpanes=0&scrollbar=0&statusbar=0&messages=0&scrollbar=0&view=FitH&page=${page}`} type="application/pdf" width="100%" height="675px">
                            <Typography variant={"body1"}>Unable to display PDF file. <a href={pdfLink}>View in Browser</a> instead.</Typography>
                        </object>
                    </div>}
                    {loading && <LoadingIndicator variant={"contained"}/>}
                </Grid>
            </Grid>
        </Dialog>
    );
}

export default HighlightedPdfViewer;