import {createMuiTheme} from "@material-ui/core/styles";

const MainTheme = createMuiTheme({
        palette: {
            primary: {
                main: "#00874f",
                light: "#f3fbff",
                dark: "#016737"
            },
            secondary: {
                main: "#e91e63",
                light: "#2b32b2"
            },
            textPrimary: {
                main: "#6e6cfb"
            },
            textSecondary: {
                main: "#3e3e3e"
            }
        },
        typography: {
            fontFamily: [
                'Montserrat',
                'sans-serif',
                'Helvetica',
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
            subtitle2: {
                fontWeight: 'bold',
                color: '#073149'
            },
            subtitle1: {
                fontFamily: 'Open Sans, sans-serif',
                fontSize: '0.85rem',
                color: '#073149',
                fontWeight: 600
            },
            info: {
                fontFamily: 'Open Sans, sans-serif',
                fontSize: '0.5em',
                color: '#d5d5d5'
            },
            body1: {
                fontFamily: 'Open Sans, sans-serif',
                fontSize: '0.85rem'
            }
        },
        overrides: {
            MuiButton: {
                root: {
                    textTransform: "none",
                    fontWeight: 600,
                    fontSize: '1rem',
                    borderRadius: "30px",
                    boxShadow: 'unset !important',
                },
                outlined: {
                    color: '#1870d6',
                },
                containedPrimary: {
                    color: '#fff',
                    '&:hover': {
                        backgroundColor: 'rgba(0,146,85,0.87)',
                    },
                    '&.Mui-disabled': {
                        background: '#fff',
                        border: '1px solid #d5d5d5',
                        backgroundImage: 'unset'
                    }
                }
            },
            MuiMenuItem: {
                root: {
                    '&.Mui-selected': {
                        backgroundColor: '#fff'
                    }
                }
            },
            MuiSelect: {
                root: {
                    fontSize: "14px"
                }
            },
            MuiOutlinedInput: {
                inputMultiline: {
                    padding: '0 !important'
                },
                root: {
                    fontSize: '1rem'
                }
            },
            MuiFormControl: {
                root: {
                    margin: '10px 0'
                }
            },
            MuiInputLabel: {
                root: {
                    fontSize: '1.2rem'
                }
            },
            MuiTableCell: {
                head: {
                    fontWeight: 'bold',
                    zIndex: 99
                },
                root: {
                    fontFamily: 'OpenSans, sans-serif !important'
                }
            },
            MuiPaper: {
                root: {
                    zIndex: 0
                }
            },
            MuiTableRow: {
                hover: {
                    '&:hover': {
                        backgroundColor: '#d3d3f735 !important'
                    }
                }
            },
            MuiListItem: {
                root: {
                    '&.Mui-selected': {
                        backgroundColor: "#fff",
                        '&:hover': {
                            backgroundColor: "#f5f5f5",
                        }
                    },
                }
            },
            MuiAccordionSummary: {
                root: {
                    "&$expanded": {
                        color: "#6e6cfb",
                        fontSize: "1.4em"
                    },
                    "&.Mui-disabled": {
                        opacity: 1
                    }
                },
            },
            MuiChip: {
                label: {
                    "&:first-letter": {
                        textTransform: "uppercase"
                    }
                }
            }
        }
    })
;

export default MainTheme;
