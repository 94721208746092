import AuthClient from '../lib/services/api/auth';
import UserClient from '../lib/services/api/user';

export const AUTH_ACTIONS = {
    AUTHENTICATED: 'authenticated_user',
    AUTHENTICATION_ERROR: 'authentication_error',
    PASSWORD_CHANGE_SUCCESS: 'password_change_success',
    PASSWORD_CHANGE_ERROR: 'password_change_error',
    TOGGLE_LOADING_STATE: 'toggle_loading_state',
    TOGGLE_MASK: 'toggle_mask_state',
    CLEAR: 'clear',
    SET_ERRORS: 'set_error'
}

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;

export function getUserDetails(dispatch, response){
    UserClient.getDetails().then((res) => {
        if (res.data) {
            dispatch({type: AUTH_ACTIONS.AUTHENTICATED, response: {loading: false}});
            let userProfile = res.data;
            userProfile['role'] = res.data?.user_role;
            sessionStorage.setItem('user', JSON.stringify(userProfile));
            const redirect_url = localStorage.getItem('redirect_url');
            if (redirect_url) {
                localStorage.clear();
                window.location.href = redirect_url;
            } else {
                window.location.href = '/';
            }
        } else {
            dispatch({type: AUTH_ACTIONS.AUTHENTICATION_ERROR, response: "Something went wrong"})
        }
    }).catch((e) => {
        dispatch({type: AUTH_ACTIONS.AUTHENTICATION_ERROR, response: "Something went wrong"})
    })
}

export function userSignIn(dispatch, formData, history) {
    dispatch({type: AUTH_ACTIONS.TOGGLE_LOADING_STATE});
    formData.append("client_id", CLIENT_ID);
    formData.append("grant_type", "password");
    AuthClient.login(formData).then((response) => {
        if(!response?.data?.two_factor_authentication_enabled) {
           getUserDetails(dispatch, response)
        }
        else {
            if(response?.data?.uuid) {
                window.location.href = `/auth/two-factor-verification/${response?.data?.uuid}`;
            }
            else {
                window.location.href = '/';
            }
        }
    }).catch((e) => {
        dispatch({type: AUTH_ACTIONS.AUTHENTICATION_ERROR, response: e.message || "Something went wrong"})
    });
}

export function userSignOut(dispatch) {
    async function logout() {
        const user = JSON.parse(sessionStorage.getItem('user'));
        if (user && user.email) {
            AuthClient.logout().then(() => {
                sessionStorage.clear()
            }).catch(() => {
                sessionStorage.clear()
            });
        } else {
            sessionStorage.clear()
        }
    }

    logout().then(() => { /*Nothing to Do*/
    });
}


export function changePassword(dispatch, formData, headers, handleSuccess) {
    dispatch({type: AUTH_ACTIONS.TOGGLE_LOADING_STATE});
    AuthClient.changePassword(formData, headers).then((res) => {
        if (res.success) {
            dispatch({type: AUTH_ACTIONS.PASSWORD_CHANGE_SUCCESS, response: res});
            handleSuccess();
        } else {
            dispatch({
                type: AUTH_ACTIONS.PASSWORD_CHANGE_ERROR,
                response: res.errors ? res.errors.join(', ') : res.message ? res.message : "Something Went wrong!. Try again."
            });
        }
    }).catch(() => {
        dispatch({type: AUTH_ACTIONS.PASSWORD_CHANGE_ERROR});
    })
}

export function setError(dispatch, error) {
    dispatch({type: AUTH_ACTIONS.SET_ERRORS, error: error});
}

export function changeFieldMask(dispatch) {
    dispatch({type: AUTH_ACTIONS.TOGGLE_MASK});
}

const AuthActions = {
    userSignIn,
    userSignOut,
    changePassword,
    changeFieldMask,
    setError,
    getUserDetails
}

export default AuthActions;
