import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {requireAuth} from "../Auth/hoc/require_auth";
import SignIn from "../Auth/signin";
import ForgotPassword from "../Auth/forgot_password_request";
import Footer from "../../components/universal/footer";
import ResetPassword from "../Auth/reset_password";
import ToastMessage from "../../components/universal/status_messages";
import PageNotFound from "../../components/universal/page_not_found";
import Dashboard from "../Dashboard";
import Header from "../../components/universal/header";
import Batches from "../Batches";
import AdminSection from "../Admin";
import OverallReport from "../Report/Overall";

export default function BrowserAppRoutes(props) {

    const SUPER_ADMIN = requireAuth(['SUPERADMIN', 'MANAGER']);
    const USER = requireAuth(['NOQC', 'USER', 'ADMIN', 'SUPERADMIN', 'MANAGER']);
    const ADMIN = requireAuth(['ADMIN', 'SUPERADMIN', 'MANAGER']);

    return (
        <Router>
            <Header/>
            <div>
                <Switch>
                    <Route exact path={'/auth/forgot-password'} component={ForgotPassword}/>
                    <Route exact path={'/auth/reset-password/:accessToken'} component={ResetPassword}/>
                    <Route exact path={'/auth/signin'} component={SignIn}/>
                    <Route path={'/auth/'} component={SignIn}/>
                    <Route exact path={'/dashboard'} component={SUPER_ADMIN(Dashboard)}/>
                    <Route exact path={'/user/batches'} component={USER(Batches)}/>
                    <Route exact path={'/user/:userId/batches'} component={USER(Batches)}/>
                    <Route exact path={'/user/batches/:batchId'} component={USER(Batches)}/>
                    <Route exact path={'/user/batches/:batchId/analysis'} component={USER(OverallReport)}/>
                    <Route exact path={'/'} component={USER(Batches)}/>
                    <Route exact path={'/admin/'} component={ADMIN(AdminSection)}/>
                    <Route path="*" component={PageNotFound}/>
                </Switch>
                <ToastMessage/>
            </div>
            <Footer/>
        </Router>
    )
}
