import React, {useEffect, useState} from 'react';
import Typography from "@material-ui/core/Typography";
import moment from "moment/moment";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import List from "@material-ui/core/List";
import DateRangeDialog from "../../components/universal/date_range_dialog";
import StatsClient from "../../lib/services/api/statistics";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles(theme => ({
    root: {
        padding: '2% 3%'
    },
    title: {
        fontSize: '2em',
        padding: '5px 0'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    }
}))

function AutoManualStats(props) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [data, setData] = useState();
    const initialFromDate = moment().startOf('month').format("YYYY-MM-DDTHH:mm");
    const initialToDate = moment().endOf('month').format("YYYY-MM-DDTHH:mm");
    const [fromDate, setFromDate] = useState(initialFromDate);
    const [toDate, setToDate] = useState(initialToDate);

    useEffect(() => {
        const params = {
            created_at__gte: moment(fromDate, 'YYYY-MM-DDTHH:mm').format('YYYY-MM-DD HH:mm:ss'),
            created_at__lte: moment(toDate, 'YYYY-MM-DDTHH:mm').format('YYYY-MM-DD HH:mm:ss'),
        }
        StatsClient.getAutoRunStats(params).then((res) => {
            if (res.success) {
                setData(res.data);
            }
        }).catch((e) => {
            console.log(e.message);
        })

    }, [fromDate, toDate])

    return (
        <div className={classes.root}>
            <div className={"corner-aligned"}>
                <Typography className={classes.title} variant={'subtitle2'} gutterBottom>
                    Auto % Stats :
                </Typography>
                <div style={{marginLeft: '16px'}}>
                    <TextField
                        label="From"
                        type="datetime-local"
                        defaultValue={initialFromDate}
                        className={classes.textField}
                        onChange={(e, v) => {
                            setFromDate(e.target.value);
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        id="datetime-local"
                        label="To"
                        type="datetime-local"
                        defaultValue={initialToDate}
                        onChange={(e, v) => {
                            setToDate(e.target.value);
                        }}
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>
            </div>
            {data?.counts && <Grid container spacing={2}>
                <Grid item md={6}>
                    <Typography variant={"subtitle2"}>
                        Batch Counts
                    </Typography>
                    <List>
                        {Object.entries(data.counts)?.map(([key, value], i) => (
                            <ListItem key={key}>
                                <ListItemText className={"capitalize"}>{key?.replace(/_/g, ' ')}</ListItemText>
                                <ListItemSecondaryAction>{value}</ListItemSecondaryAction>
                            </ListItem>
                        ))}
                    </List>
                </Grid>
                <Grid item md={6}>
                    <Typography variant={"subtitle2"}>
                        Percent and Time Data
                    </Typography>
                    <List>
                        {Object.entries(data.percent)?.map(([key, value], i) => (
                            <ListItem key={key}>
                                <ListItemText className={"capitalize"}>{key?.replace(/_/g, ' ')}</ListItemText>
                                <ListItemSecondaryAction>{value ? `${value}%` : "NA"}</ListItemSecondaryAction>
                            </ListItem>
                        ))}
                        {Object.entries(data.time_data)?.map(([key, value], i) => (
                            <ListItem key={key}>
                                <ListItemText className={"capitalize"}>{key?.replace(/_/g, ' ')}</ListItemText>
                                <ListItemSecondaryAction>{value ? moment.utc(value * 1000).format('HH:mm:ss') : "NA"}</ListItemSecondaryAction>
                            </ListItem>
                        ))}
                    </List>
                </Grid>
            </Grid>}
        </div>
    );
}

export default AutoManualStats;