import React, {useState} from "react";
import ReportBugDialog from "./report_bug_dialog";
import ReportBugButton from "./report_bug_button";

function ReportBug(props) {

    const [open, setOpen] = useState(false);
    const [batchDetails, setBatchDetails] = useState("");
    const files = props.files;

    const handleClose = () => {
        setOpen(false);
    }

    const handleOpenIssue = () => {
        const batch = props.batchDetails;
        let batchDetailString = "**** System Captured Details ****\n\n";
        const infoKeys = ["name","bank_name","expanded_bank_name","id","file_count","valid_files","invalid_files"]
        for (let key in batch) {
            if (batch.hasOwnProperty(key)){
                if (infoKeys.includes(key)){
                    batchDetailString += key?.replace("_"," ") + " : " + batch[key] + "\n";
                }
            }
        }
        batchDetailString += "\n" + "Failed Files: \n\n"
        files.forEach((file => {
            if (file.status === "FAILED"){
                batchDetailString += file?.name + " : " + file?.additional_info?.short_description + "\n\n";
            }
        }));
        batchDetailString += "\n**** Add Further details Below ****\n\n";

        setBatchDetails(batchDetailString);
        setOpen(true);
    }

    return (
        <>
            <ReportBugButton openDialog={handleOpenIssue}/>
            <ReportBugDialog batchDetails={batchDetails} open={open} handleClose={handleClose}/>
        </>
    )
}

export default ReportBug;
