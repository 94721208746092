import React, {useContext} from "react";
import {TransactionFilterContext} from "../../../../lib/contexts/transaction_filter_context";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {getCurrencyFormat} from "../../../../lib/utils/common_utils";
import moment from "moment";
import {timePeriodLevel} from "./statement_filter";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: theme.spacing(0.5),
        margin: 0,
    },
    chip: {
        margin: theme.spacing(0.5),
        textTransform: 'capitalize'
    },
}));


function FiltersInfo(props) {
    const classes = useStyles();
    const {batchFiles, filterData} = props;
    const TxnData = useContext(TransactionFilterContext);

    const filtersApplied = TxnData.filters;
    const TxnType = ["All", "Deposits", "Withdrawals"];
    const accountType = ["All", "Checking", "Savings"];
    const recurringType = ["All", "Recurring", "Non-Recurring"];
    const flagType = ["All", "Excluded", "Income Only", "Atypical Deposit", "Large Deposits"];

    const getStatementInfo = (statements) => {
        if (statements?.length > 0) {
            const incomeTime = filterData?.considered_income_time;
            if (incomeTime) {
                const periodStatements = batchFiles.slice(timePeriodLevel[incomeTime]['min'], timePeriodLevel[incomeTime]['max'])
                const statementSelections = periodStatements.map(f => moment(f?.statement_date, "MM-DD-YYYY").format("MMM-YYYY")) || []
                if (TxnData.filters.statement === statementSelections.join(",")) {
                    return `${filterData?.considered_income_time?.replace(/_/g, ' ')} files chosen`;
                }
            }
            return `${statements?.split(",").length} files chosen`;
        }
    }

    let filterAppliedDetails = {
        transaction_type: filtersApplied?.type !== 0 ? TxnType[filtersApplied?.type] : null,
        criteria: filtersApplied?.flag !== 0 ? flagType[filtersApplied?.flag] : null,
        frequency: filtersApplied?.recurring !== 0 ? recurringType[filtersApplied?.recurring] : null,
        categories: filtersApplied?.category !== "" ? filtersApplied?.category : null,
        account_type: filtersApplied?.account_type !== 0 ? accountType[filtersApplied?.account_type] : null,
        statements: filtersApplied?.statement !== "" ? getStatementInfo(filtersApplied?.statement) : null,
        min_amount: filtersApplied?.minAmount !== null ? getCurrencyFormat(filtersApplied?.minAmount) : null,
        max_amount: filtersApplied?.maxAmount !== null ? getCurrencyFormat(filtersApplied?.maxAmount) : null,
        search: filtersApplied?.search !== "" ? filtersApplied?.search : null,
        account_number: filtersApplied?.account_number !== "All" ? filtersApplied?.account_number : null,
    }

    const filterNameMap = {
        transaction_type: "type",
        criteria: "flag",
        frequency: "recurring",
        categories: "category",
        account_type: "account_type",
        statements: "statement",
        min_amount: "minAmount",
        max_amount: "maxAmount",
        search: "search",
        account_number: "account_number"
    }

    const defaults = {
        type: 1,
        flag: 0,
        category: "",
        recurring: 0,
        account_type: 0,
        statement: "",
        minAmount: null,
        maxAmount: null,
        search: "",
        account_number: "All"
    };

    const handleDelete = (key) => {
        if(key !== "transaction_type") {
            TxnData.setFilters({...TxnData.filters, [filterNameMap[key]]: defaults[filterNameMap[key]]});
        }
    }

    const isFilterApplied = Object.keys(filterAppliedDetails).filter(key => filterAppliedDetails[key]).length > 0;

    const includedFiles = batchFiles.filter(f => f.includes_analysis).length;
    const excludedFiles = batchFiles.filter(f => !f.includes_analysis).length;

    return (
        <>
            {isFilterApplied && <div>
                <div>
                    <Typography variant="subtitle2" display="inline" style={{fontSize: '18px', color: '#000'}}>Applied
                        Filters:</Typography>
                    <ul className={classes.root}>
                        {Object.keys(filterAppliedDetails).map((key, idx) => {
                            return (
                                filterAppliedDetails[key] && <li key={key}>
                                    <Chip
                                        style={{borderRadius: '2px'}}
                                        variant="outlined"
                                        label={<div>
                                            <Typography variant={"subtitle1"} color={"textSecondary"} display="inline">
                                                {`${key.replace('_', " ")}`}
                                            </Typography>
                                            &nbsp;:&nbsp;
                                            <Typography variant={"subtitle2"} color={"secondary"} display="inline">
                                                {`${filterAppliedDetails[key]}`}
                                            </Typography>
                                        </div>}
                                        onDelete={() => handleDelete(key)}
                                        className={classes.chip}
                                    />
                                </li>
                            );
                        })}
                        <li><Button size="small" color={"primary"} style={{textDecoration: 'underline'}}
                                    onClick={() => TxnData.resetFilter()}>
                            Reset filters to default
                        </Button>
                        </li>
                        &nbsp;&nbsp;
                        <li><Button size="small" color={"secondary"} style={{textDecoration: 'underline'}}
                                    onClick={() => TxnData.clearFilter()}>
                            Clear filters
                        </Button>
                        </li>
                    </ul>
                </div>
                <div style={{paddingLeft: '10px'}}>
                    <Typography variant="subtitle2" color="textSecondary" display="inline">
                        <strong
                            style={{
                                fontSize: '18px',
                                color: '#000'
                            }}>{TxnData?.filteredTransaction?.length}</strong>&nbsp;
                        Transaction after filters from a total of {TxnData?.txnList?.length} all available transactions
                        from {includedFiles} files in analysis.
                    </Typography>
                    &nbsp;&nbsp;
                </div>
            </div>}
            {!isFilterApplied && <div>
                <div>
                    <Typography variant="subtitle2" color="textSecondary" display="inline">
                        <strong
                            style={{
                                fontSize: '18px',
                                color: '#000'
                            }}>{TxnData?.filteredTransaction?.length}</strong>&nbsp;
                        transactions.
                    </Typography>
                    &nbsp;&nbsp;
                </div>
            </div>}
        </>
    )
}

export default FiltersInfo;