import request from "./common/api_client";

function getBatchList(params) {
    const url = params.user_id ? `/users/${params.user_id}/batch` : '/v1/admin/batch';
    return request({
        url: url,
        method: 'GET',
        params: params
    })
}

function updateBatch(formData) {
    return request({
        url: '/batch/edit-file-upload',
        method: 'POST',
        data: formData
    })
}

function deleteBatch(batchId) {
    return request({
        url: `/batch/${batchId}`,
        method: 'DELETE',
    })
}

function getDownloadURL(id) {
    return request({
        url: `v1/admin/batch/${id}/download`,
        method: 'GET',
    })
}

function processBatch(batchId,data) {
    return request({
        url: `/batch/${batchId}/process`,
        method: 'POST',
        data: data
    })
}

function reProcessBatch(batchId,formData) {
    return request({
        url: `/batch/${batchId}/process`,
        method: 'POST',
        data: formData
    })
}

function requestManualProcess(batchId) {
    return request({
        url: `v1/admin/batch/${batchId}/process/manual`,
        method: 'POST'
    })
}

function uploadManualOutput(batchId,formData) {
    return request({
        url: `v1/admin/batch/${batchId}/process/manual/validate-output`,
        method: 'POST',
        data: formData
    })
}

const BatchClient = {
    getBatchList,
    updateBatch,
    deleteBatch,
    getDownloadURL,
    processBatch,
    reProcessBatch,
    requestManualProcess,
    uploadManualOutput,
}

export default BatchClient;
