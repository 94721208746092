import React, {useContext, useEffect, useState} from "react";
import Drawer from "@material-ui/core/Drawer";
import Typography from "@material-ui/core/Typography";
import {ToastMessageContext} from "../../../lib/contexts/message_context";
import {makeStyles} from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import BackIcon from "@material-ui/icons/ArrowBackIos";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import AnalyticsClient from "../../../lib/services/api/analytics";

const useStyles = makeStyles(theme => ({
    wrapper: {
        overflowY: 'scroll',
        overflowX: 'hidden',
        width: '50vw'
    },
    noOverflow: {
        overflow: 'hidden'
    },
    listWrapper: {
        width: '50vw',
        overflow: 'hidden'
    },
    title: {
        fontSize: '18px'
    },
    sectionTitle: {
        paddingLeft: '20px',
        paddingTop: '8px'
    }
}))


function AccountThresholdValue(props) {
    const classes = useStyles();
    const {accountThresholdData, batchId, handleClose} = props;
    const [accountMapping, setAccountMapping] = useState({});
    const [updating, setUpdating] = useState(false);

    useEffect(() => {
        setAccountMapping(accountThresholdData || {});
    }, [accountThresholdData]);

    const Toast = useContext(ToastMessageContext);

    const saveThresholdValues = (reset) => {
        setUpdating(true);
        let newAccountMapping = {};
        if(reset){
            const accNums = Object.keys(accountMapping);
            for(let i=0; i<accNums?.length; i++){
                newAccountMapping[accNums[i]] = -1
            }
        }
        AnalyticsClient.updateThresholdValues(batchId, {account_large_deposit_thresholds:reset?newAccountMapping:accountMapping}).then((res) => {
            if (res.success) {
                if (props?.handleClose) {
                    props.handleClose();
                }
            }
        }).catch((e) => {
            Toast.showError(e?.message || "Something went wrong. Try again");
        }).finally(() => {
            props.handleClose();
        })
    }

    return (
        <Drawer className={classes.noOverflow} anchor={'right'} open={true}>
            <div className={`${classes.wrapper}`}>
                <Toolbar style={{
                    position: 'absolute',
                    top: '0px',
                    width: '50vw',
                    background: '#fff',
                    zIndex: 375
                }}>
                    <div style={{textAlign: 'left', background: '#fff', display: 'flex', alignItems: 'center'}}>
                        <IconButton disableFocusRipple disableRipple onClick={handleClose}>
                            <BackIcon color={"primary"}/>
                        </IconButton>
                        <Typography className={classes.sectionTitle} variant={"h6"} component="div" gutterBottom>
                            Account Large Deposit Threshold Settings
                        </Typography>
                    </div>
                </Toolbar>
                <div style={{height: '65px'}}/>
                <div style={{padding: '24px'}}>
                    <Grid container alignItems={"center"} justify={"center"} spacing={3}>
                        <Grid item xs={6}>
                            <Typography variant={"subtitle2"} gutterBottom>
                                Account Number & Type
                            </Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography variant={"subtitle2"} gutterBottom>
                                Large Deposit Threshold Amount
                            </Typography>
                        </Grid>
                        <Grid item xs={1}/>
                        {Object.keys(accountThresholdData|| {})?.map((accNum, i) => (
                            <React.Fragment
                                key={accNum}>
                                <Grid item xs={6}>
                                    <Typography variant={"subtitle2"}>
                                        {accNum}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <OutlinedInput
                                        margin="dense"
                                        color="primary"
                                        fullWidth
                                        type="number"
                                        inputProps={{
                                            min: 1,
                                            step: 1
                                        }}
                                        defaultValue={accountThresholdData[accNum]}
                                        value={accountMapping[accNum]}
                                        onChange={(e) => {
                                            setAccountMapping({...accountMapping,[accNum]:e.target.value})
                                        }}
                                        endAdornment={<InputAdornment position="end">$</InputAdornment>}
                                    />
                                </Grid>
                                <Grid item xs={2}/>
                            </React.Fragment>
                        ))}
                    </Grid>
                </div>
                <div style={{textAlign: "right", marginRight: '136px', marginTop: '32px'}}>
                    <Button disabled={updating} startIcon={updating && <CircularProgress size={15}/>} size={"small"}
                            variant={"outlined"} color="primary" onClick={()=> saveThresholdValues(-1)}>
                        Reset to default
                    </Button>
                    <Button style={{marginLeft: '12px'}} disabled={updating} startIcon={updating && <CircularProgress size={15}/>} size={"small"}
                            variant={"contained"} color="primary" onClick={() => saveThresholdValues()}>
                        Update Threshold values
                    </Button>
                </div>
            </div>
        </Drawer>
    );
}

export default AccountThresholdValue;