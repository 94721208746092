import React from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import CheckIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Cancel";
import WarningIcon from "@material-ui/icons/Warning";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import RiskDetailList from "./risk_detail_list";
import {useStyles} from "./index";

function CategoryWiseRiskItems(props) {
    const classes = useStyles();
    let totalInconsistencies = null;
    if (props.risk?.test_name === "Tampered Fonts" || props.risk?.test_name === "Font Inconsistencies") {
        totalInconsistencies = props.risk?.data?.reduce((sum, {additional_data}) => sum + additional_data?.length, 0);
    }
    return (<>
        <Accordion
            style={{marginBottom: "16px", boxShadow: "unset"}}>
            <AccordionSummary disabled={props.risk?.test_status === "PASS"}
                              className={`${classes.accordionTitle} ${props.risk?.type}`}
                              id={props.risk.test_name}
                              expandIcon={props.risk?.test_status === "FLAGGED" ? <ExpandMoreIcon/> : null}
            >
                <Typography className={classes.heading}>
                    {props.risk?.test_status === "PASS" && <CheckIcon fontSize={"20px"} htmlColor="#6EB268"/>}
                    {props.risk?.test_status === "FLAGGED" ? (props.risk?.type === "critical" ?
                        <ErrorIcon htmlColor="#E25950"/> : <WarningIcon htmlColor="#F5C163"/>) : null}
                    &nbsp;&nbsp;{props.risk?.test_name} {(props.risk?.test_name === "Tampered Fonts" || props.risk?.test_name === "Font Inconsistencies") ? ` (${totalInconsistencies})` : null}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <div className={classes.root}>
                    <Typography className={classes.description} gutterBottom>
                        <InfoIcon htmlColor="#4C6FFF"/>&nbsp;&nbsp;{props.risk?.description}
                    </Typography>
                    <div className={classes.riskDetail}>
                        <RiskDetailList total={totalInconsistencies} batchId={props?.batchId} data={props.risk?.data} testName={props?.risk?.test_name}/>
                    </div>
                </div>
            </AccordionDetails>
        </Accordion>
    </>);
}

export default CategoryWiseRiskItems;