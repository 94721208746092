import React from "react";
import Grid from "@material-ui/core/Grid";
import FlagFilter from "./flagged";
import CategoryFilter from "./category_filter";
import RecurringTxnFilter from "./recurring_txn";
import SearchTransaction from "./search";
import StatementFilter from "./statement_filter";
import AmountRange from "./amount_range";
import AccountTypeFilter from "./account_type_filter";
import FiltersInfo from "./filter_info";
import AccountNumberFilter from "./account_number_filter";

function TransactionFilters({categoryData, batchFiles}) {
    return (
        <Grid container spacing={3} style={{padding: '25px'}}>
            <Grid item md={3}>
                <FlagFilter/>
            </Grid>
            <Grid item md={3}>
                <AccountTypeFilter categoryData={categoryData}/>
            </Grid>
            <Grid item md={3}>
                <RecurringTxnFilter/>
            </Grid>
            <Grid item md={3}>
                <SearchTransaction/>
            </Grid>
            <Grid item md={3}>
                <AmountRange/>
            </Grid>
            <Grid item md={3}>
                <CategoryFilter categoryData={categoryData}/>
            </Grid>
            <Grid item md={6}>
                <StatementFilter allFiles={batchFiles} filterData={categoryData}/>
            </Grid>
            <Grid item md={8} style={{paddingTop: 0}}>
                <AccountNumberFilter categoryData={categoryData}/>
            </Grid>
            <Grid item md={12}>
                <FiltersInfo batchFiles={batchFiles} filterData={categoryData}/>
            </Grid>
        </Grid>
    );
}

export default TransactionFilters;
