import React, {useContext, useEffect, useState} from 'react';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import {TransactionFilterContext} from "../../../../lib/contexts/transaction_filter_context";
import moment from "moment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import {unique} from "../../../../lib/utils/common_utils";


const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;
export const timePeriodLevel = {
    recent_2m: {
        min: 0,
        max: 2
    },
    recent_6m: {
        min: 0,
        max: 6
    },
    recent_12m: {
        min: 0,
        max: 12
    },
    recent_24m: {
        min: 0,
        max: 24
    },
    previous_6m: {
        min: 6,
        max: 12
    },
    previous_12m: {
        min: 12,
        max: 24
    },
    previous_24m: {
        min: 24,
        max: 48
    },
    overall: {
        min: 0,
        max: 99
    }
}

function StatementFilter(props) {
    const {allFiles, filterData} = props;
    const batchFiles = allFiles.filter(f => ((f.status.indexOf('PROCESSED') > -1) && (f?.is_removed !== true || f?.is_duplicate !== true) && (f.includes_analysis === true)))
    const [selectedOptions, setSelectedOptions] = useState([]);
    const statementMonths = unique(batchFiles.map(f => moment(f?.statement_date, "MM-DD-YYYY").format("MMM-YYYY")));
    const TxnData = useContext(TransactionFilterContext);

    useEffect(() => {
        if (!TxnData.filters.statement) {
            setSelectedOptions([]);
        }
    }, [TxnData.filters.statement])

    useEffect(() => {
        if (filterData?.considered_income_time && filterData?.considered_income_time !== "overall") {
            const incomeTime = filterData?.considered_income_time;
            const periodStatements = batchFiles.slice(timePeriodLevel[incomeTime]['min'], timePeriodLevel[incomeTime]['max'])
            const statementSelections = unique(periodStatements.map(f => moment(f?.statement_date, "MM-DD-YYYY").format("MMM-YYYY"))) || []
            setSelectedOptions(statementSelections);
            TxnData.setFilters({...TxnData.filters, statement: statementSelections.join(",")})
        } else if (filterData?.considered_income_time && filterData?.considered_income_time === "overall") {
            setSelectedOptions([]);
            TxnData.setFilters({...TxnData.filters, statement: ""})
        }
    }, [filterData?.considered_income_time,])

    const handleChange = (value) => {
        setSelectedOptions(value);
        TxnData.setFilters({...TxnData.filters, statement: value.join(",")});
    };


    return (
        <div>
            <Typography variant="subtitle2" color="textSecondary">
                Statement period:
            </Typography>
            <FormControl fullWidth style={{marginTop: 0}}>
                <Autocomplete
                    fullWidth
                    multiple
                    limitTags={5}
                    options={statementMonths}
                    disableCloseOnSelect
                    size="small"
                    value={selectedOptions}
                    onChange={(event, value) => handleChange(value)}
                    renderOption={(option, {selected}) => (
                        <React.Fragment>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{marginRight: 8}}
                                checked={selected}
                            />
                            {option}
                        </React.Fragment>
                    )}
                    renderInput={(params) => (
                        <TextField {...params} variant="outlined" size="small"/>
                    )}
                />
            </FormControl>
        </div>
    );
}

export default StatementFilter;
