import React, {useState} from "react"
import MaterialTable from "material-table";
import tableIcons, {tableOptions} from "../../../components/universal/table_attributes";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import HighlightedPdfViewer from "./view_risk_highlighted_pdf";
import {getCurrencyFormat} from "../../../lib/utils/common_utils";

const useStyles = makeStyles((theme) => ({
    tableWrapper: {
        width: '100%',
        padding: '4px 0 32px',
        marginBottom: '12px'
    }
}));

function RiskDetailList(props) {
    const classes = useStyles();
    const [showFile, setShowFile] = useState(false);
    const [currentSelection, setCurrentSelection] = useState(null);

    let {data, testName, batchId} = props;
    const tempColumns = data?.length > 0 && data instanceof Object ? Object.keys(data[0]) : [];
    let columns = tempColumns.map(col => {
        if (col !== "tableData" && col !== "additional_data") {
            return (
                {
                    title: col?.replace(/_+/g, " "),
                    field: col,
                    headerStyle: {
                        textTransform: 'uppercase',
                        color: '#707B8C',
                        fontSize: '11px',
                        letterSpacing: '0.06em'
                    },
                    render: rowData => <Typography component={"span"} color={col === 'filename' ? 'primary' : 'inherit'}
                                                   variant={col === 'filename' ? "subtitle2" : "body1"}>
                        {(typeof (rowData[col]) === 'string' && !(col.includes("ing_balance") || col.includes("total"))) ? rowData[col]: null}
                        {(col === "missing_months" && rowData[col]?.length > 0 ? rowData[col].join(", ") : null)}
                        {(col.includes("ing_balance") || col.includes("total")) ? getCurrencyFormat(rowData[col], 2) : null}
                    </Typography>
                })
        }
    });
    columns = columns.filter(col => col?.title);

    if (testName === "Font Inconsistencies") {
        const actionColumn = {
            title: "View File",
            field: 'filename',
            headerStyle: {
                textTransform: 'uppercase',
                color: '#707B8C',
                fontSize: '11px',
                letterSpacing: '0.06em'
            },
            render: rowData => <Button
                onClick={() => {
                    setCurrentSelection(rowData);
                    setShowFile(true);
                }} size={"small"}
                style={{padding: '4px 24px'}}
                variant={"outlined"} color={"primary"}>
                View Issues in File
            </Button>
        }
        columns = columns.concat(actionColumn);
    }

    let MITableOptions = {
        ...tableOptions,
        padding: "dense",
        showTitle: false,
        paging: false,
        filtering: false,
        searchable: false,
        selection: false,
        sorting: false,
        toolbar: false,
        headerStyle: {
            backgroundColor: "#FAFAFB",
            borderBottom: "1px solid #EDF2F7",
            textTransform: "uppercase"
        }
        // maxBodyHeight: 400
    }

    return (
        <div className={classes.tableWrapper}>
            <MaterialTable
                style={{boxShadow: 'unset'}}
                columns={columns}
                data={(data && data?.length > 0 && data[0] instanceof Object) ? data.filter(Boolean) : []}
                options={MITableOptions}
                icons={tableIcons}
                localization={{
                    body: {
                        emptyDataSourceMessage: 'No details found',
                    }
                }}
            />
            {showFile &&
            <HighlightedPdfViewer total={props?.total} data={currentSelection} batchId={batchId} handleClose={() => setShowFile(null)}/>}
        </div>
    )
}

export default RiskDetailList;