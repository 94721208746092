import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import Typography from "@material-ui/core/Typography/Typography";
import AuthClient from "../../lib/services/api/auth";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import LockIcon from "@material-ui/icons/LockOutlined";
import useAuthStyles from "./auth_styles";
import PasswordVisible from "@material-ui/icons/Visibility";
import PasswordHide from "@material-ui/icons/VisibilityOff";
import CircularProgress from "@material-ui/core/CircularProgress";
import {MainBrandingWidget, PoweredByWidget} from "./branding_widgets";

export default function ResetPassword(props) {
    const classes = useAuthStyles();
    const [password, handlePasswordChange] = useState();
    const [passwordConfirmation, handleConfirmPassword] = useState();
    const [message, setMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [mask,setMask] = useState(true);
    let accessToken = props.match.params.accessToken;

    useEffect(() => {
        if (!accessToken) {
            props.history.push('/auth/forgot-password');
        }
    }, []);

    const handleMasked = () => {
        setMask(!mask);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (password !== passwordConfirmation) {
            setError(true);
        } else {
            setLoading(true);
            const formData = new FormData();
            formData.append("password",password?.trim());
            formData.append("reset_token",accessToken);
            AuthClient.changePassword(formData).then((res) => {
                setMessage({
                    success:true,
                    message: "Password changed successfully"
                });
                handleSuccess();
            }).catch(() => {
                setLoading(false);
                setMessage({message: "Something went wrong. Please try again!", success: false});
            })
        }
    };
    const handleSuccess = () => {
        setTimeout(() => {
            props.history.replace('/auth/signin');
        }, 2000);
    };

    return (
        <div className={classes.wrapper}>
            <div className={classes.formWrapper}>
                {!!!message ? <form className={classes.form} onSubmit={handleSubmit} autoComplete="custom-form">
                    <MainBrandingWidget/>
                    <Typography color={"primary"} align={"center"} component={"h6"} variant={"h6"}>Change
                        Password</Typography>
                    <FormControl fullWidth className={classes.formField}>
                        <OutlinedInput
                            required
                            name="password"
                            type={mask?"password":"text"}
                            autoComplete="new-password"
                            placeholder="New Password"
                            autoFocus
                            onChange={(e) => {
                                handlePasswordChange(e.target.value)
                            }}
                            onFocus={() => setError(false)}
                            startAdornment={<InputAdornment position="start">
                                <LockIcon/>
                            </InputAdornment>
                            }
                            endAdornment={
                                <InputAdornment position="end">
                                    {!mask ? <PasswordVisible className={"clickable"} onClick={handleMasked}/> :
                                        <PasswordHide className={"clickable"} onClick={handleMasked}/>}
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    <FormControl fullWidth className={classes.formField}>
                        <OutlinedInput
                            required
                            name="password_confirmation"
                            autoComplete="new-password"
                            placeholder="Password confirmation"
                            type={mask?"password":"text"}
                            id="password-confirmation"
                            error={error}
                            autoFocus
                            helperText={error ? "Passwords do not match" : ""}
                            onChange={(e) => {
                                handleConfirmPassword(e.target.value)
                            }}
                            onFocus={() => setError(false)}
                            startAdornment={<InputAdornment position="start">
                                <LockIcon/>
                            </InputAdornment>
                            }
                        />
                    </FormControl>
                    <div style={{margin: '20px 0'}}>
                        <Button
                            type="submit"
                            className={classes.submit}
                            disabled={loading}
                            fullWidth
                            color={"primary"}
                            variant={"contained"}
                            startIcon={loading && <CircularProgress size={15}/>}
                        >
                            Submit
                        </Button>
                    </div>

                </form> : <div className={`${classes.form} ${message.success ? classes.success : classes.error}`}>
                    <MainBrandingWidget/>
                    <Typography color={"primary"} align={"center"} component={"h6"} variant={"h6"}>Change
                        Password</Typography>
                    <Typography variant="subtitle1" gutterBottom
                                className={classes.message}>
                        {message.message}
                    </Typography>
                    {!message.success && <Button
                        fullWidth
                        className={classes.submit}
                        onClick={() => setMessage(null)}
                    >
                        Retry
                    </Button>}
                </div>}
                <PoweredByWidget/>
            </div>
        </div>
    );
}
