import React from 'react';
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import BackIcon from "@material-ui/icons/ArrowBackIos";
import Drawer from "@material-ui/core/Drawer";
import {useStyles} from "./file_selection";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import UpdateBatch from "../../Batches/update_batch";
import {fileStatusMap} from "../../Batches/file_status_info";
import Tooltip from "@material-ui/core/Tooltip";
import {unique} from "../../../lib/utils/common_utils";
import moment from "moment";

export function MissingMonthDetails(props) {
    const {
        incomeSplit,
        missingMonths,
        missingPages,
        incomePeriod,
        accountWiseMissing,
        hideIncomePeriod,
        hideMissingMonths,
        failedFiles,
        batchFiles
    } = props;

    const statementDays = batchFiles.map(f => {
        if(f?.statement_date !== "N/A") {
            return (moment(f?.statement_date, "MM-DD-YYYY").format("DD"));
        }
    });
    const midMonths = statementDays.filter(d => (d && (+d >= 10) && (+d < 28)));
    const tenant = JSON.parse(sessionStorage.getItem('user'))?.tenant_name?.toUpperCase();

    return (
        <>
            {incomePeriod && !hideIncomePeriod && <Typography variant={"subtitle2"} gutterBottom>
                Income Period for Batch is chosen as&nbsp;
                <Typography component={"span"} color={"secondary"} variant={"subtitle2"}>
                    {incomePeriod?.replace("MONTHS_12", "12 Months")?.replace("MONTHS_24", "24 Months")}
                    </Typography>
            </Typography>}
            {["ANGELOAK"].includes(tenant) && midMonths?.length > 2 &&
                <Typography component={"div"} variant={"subtitle2"} color={"secondary"} gutterBottom>*Mid Month Statements Found</Typography>
            }
            {missingMonths?.length > 0 && !hideMissingMonths && <div>
                <br/>
                <Typography variant={"subtitle2"} gutterBottom>Missing Months in the Batch:</Typography>
                <Typography variant={"body1"} color={"secondary"}
                            style={{paddingLeft: '12px', maxWidth:'30vw'}}>{missingMonths?.join(", ")}</Typography>
            </div>}
            {missingPages?.length > 0 && <div>
                <br/>
                <Typography variant={"subtitle2"}>Files with missing pages</Typography>
                <List dense>
                    {missingPages?.map((m, i) => <ListItem key={m.name}>
                        <Typography variant={"body1"}>{i + 1}.&nbsp;{m.name}</Typography>
                    </ListItem>)}
                </List>
            </div>}
            {accountWiseMissing?.length > 0 && <div>
                <br/>
                <Typography variant={"subtitle2"}>Accounts with missing months</Typography>
                <List dense>
                    {incomeSplit?.map((is, i) => (
                        is.missing_months?.length > 0 && <ListItem key={is.account_number}>
                            <div>
                                <Typography variant={"subtitle1"}
                                            gutterBottom>{is.account_type}:&nbsp;{is.captured_account_number || is.account_number}:</Typography>
                                <Typography variant={"body1"} color={"secondary"}
                                            style={{paddingLeft: '12px', maxWidth:'30vw'}}>&nbsp;{is.missing_months?.join(", ")}</Typography>
                            </div>
                        </ListItem>
                    ))}
                </List>
            </div>}
            {failedFiles?.length > 0 && <div>
                <br/>
                <Typography variant={"subtitle2"}>Files with Other Issues</Typography>
                <List dense>
                    {failedFiles?.map((m, i) => <ListItem key={m.name}>
                        <div className={"corner-aligned"}>
                            <Typography variant={"body1"}>{i + 1}.&nbsp;{m.name}</Typography><br/>
                            <Tooltip title={m?.internal_admin_comments} arrow>
                                <Typography variant={"info"} color={"secondary"} style={{paddingLeft: '8px'}}>
                                    {fileStatusMap[m.status]?.text}
                                </Typography>
                            </Tooltip>
                        </div>
                    </ListItem>)}
                </List>
            </div>}
        </>
    )
}

function MissingFilesInfo(props) {
    const classes = useStyles();
    const {batchId, batchFiles, showFilesList, handleClose, metaData, missingMonths, missingPages} = props;
    const incomeSplit = metaData?.qualified_income_split;
    const accountWiseMissing = incomeSplit?.filter((f) => f?.missing_months?.length > 0);

    const failedStatus = ["UNSUPPORTED_LANGUAGE", "FILE_0_BYTES", "BAD_SCAN", "INVALID_FILE", "FAILED"]
    const failedFiles = batchFiles.filter((f) => failedStatus.includes(f.status));

    return (
        <div>
            <Drawer className={classes.noOverflow} anchor={'right'} open={showFilesList}>
                <div className={`${classes.wrapper}`}>
                    <Toolbar style={{
                        position: 'absolute',
                        top: '0px',
                        width: '35vw',
                        background: '#fff',
                        zIndex: 375
                    }}>
                        <div style={{textAlign: 'left', background: '#fff', display: 'flex', alignItems: 'center'}}>
                            <IconButton disableFocusRipple disableRipple onClick={handleClose}>
                                <BackIcon color={"primary"}/>
                            </IconButton>
                            <Typography className={classes.sectionTitle} variant={"h6"} component="div" gutterBottom>
                                Files with Problems
                            </Typography>
                        </div>
                    </Toolbar>
                    <div style={{padding: '65px 35px 75px 35px'}}>
                        <div style={{width: '35vw'}}/>
                        <MissingMonthDetails
                            incomePeriod={metaData?.income_period}
                            missingMonths={missingMonths}
                            missingPages={missingPages}
                            incomeSplit={incomeSplit}
                            accountWiseMissing={accountWiseMissing}
                            failedFiles={failedFiles}
                            batchFiles={batchFiles}
                        />
                        <div>
                            <UpdateBatch
                                show={true}
                                existingFiles={batchFiles}
                                batchId={batchId}
                                handleCancel={handleClose}
                            />
                        </div>
                    </div>
                </div>
            </Drawer>
        </div>
    )
}

export default MissingFilesInfo;