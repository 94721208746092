import React, {useState} from "react";
import {DateRangePicker} from "materialui-daterange-picker";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";

function DateRangeDialog(props) {
    const {open, onClose, handleDateRange} = props;
    const [range, setDateRange] = useState({});

    return (
        <Dialog
            maxWidth="md"
            fullWidth
            open={open}
            onClose={onClose}
        >
            <DialogContent>
                <DateRangePicker
                    open={true}
                    toggle={() => null}
                    onChange={(range) => setDateRange(range)}
                />
                <div style={{margin: '15px auto', display: 'flex', justifyContent: 'flex-end'}}>
                    <Button variant={"contained"} color="primary" onClick={() => handleDateRange(range)}>
                        Apply
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default DateRangeDialog;
