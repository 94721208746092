export const flattenObjectToArray = (object, key) => {
    let values = [];
    for (let i = 0; i < object.length; i++) {
        if (object[i].hasOwnProperty(key)) {
            values.push(object[i][key]);
        }
    }
    return values;
};

export const getParams = (url) => {
    let params = {};
    let parser = document.createElement('a');
    parser.href = url;
    let query = parser.search.substring(1);
    let lets = query.split('&');
    for (let i = 0; i < lets.length; i++) {
        let pair = lets[i].split('=');
        params[pair[0]] = decodeURIComponent(pair[1]);
    }
    return params;
};

export const validateEmail = (email) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export const checkInternalEmails = (email) => {
    let commonStrings = ['prudent','admin', 'normal','zenocr'];
    for(let i=0; i < commonStrings.length; i++){
        if(email?.indexOf(commonStrings[i]) > -1){
            return true;
        }
    }
    return false;
}

export const sleepFor = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const unique = (arr) => {
    if (!arr || !arr.length < 0) {
        return []
    }
    let uniqueArray = [];
    for (let i = 0; i < arr.length; i++) {
        if (uniqueArray.indexOf(arr[i]) === -1) {
            if(arr[i]){
                uniqueArray = uniqueArray.concat(arr[i]);
            }
        }
    }
    return uniqueArray;
}

export const groupByKey = (xs, key) => {
    return xs.reduce(function (rv, x) {
        let v = key instanceof Function ? key(x) : x[key];
        let el = rv.find((r) => r && r.key === v);
        if (el) {
            el.values.push(x);
        } else {
            rv.push({key: v, values: [x]});
        }
        return rv;
    }, []);
}

export const getCurrencyFormat = (number, fractions, currency) => {
    if(!Number.isNaN(number)){
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currency || 'USD',
            maximumFractionDigits: fractions || 0
        });
        return formatter.format(number)
    }
    return "NA";
}

export const inRange = (num, min, max) => {
    return num >= min && num <= max;
}




