import React, {createContext, useState} from "react";

export const ToastMessageContext = createContext({});

export default function ToastMessageProvider({ children }) {
    const [toastMessage,setToastMessageState] = useState({
        variant:"info",
        message:"",
        open:false,
    });

    const showToastMessage = (data) => {
        setToastMessageState({
            ...toastMessage,
            open:true,
            ...data
        })
    }

    const showError = (message) => {
        setToastMessageState({
            message: message,
            open:true,
            variant: "error"
        })
    }

    const showSuccess = (message) => {
        setToastMessageState({
            message: message,
            open:true,
            variant: "success"
        })
    }

    let toastMessageValue = {toastMessage,showToastMessage,showSuccess,showError};
    return (
        <ToastMessageContext.Provider value={toastMessageValue}>
            {children}
        </ToastMessageContext.Provider>
    );
}