import React from "react";
import SnackbarContent from "@material-ui/core/SnackbarContent/SnackbarContent";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Offline} from "react-detect-offline";
import ImportantNotice from "./important_notice";

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: 600,
    },
    snackbar: {
        margin: theme.spacing(1),
    },
}));


export default function Footer() {
    const classes = useStyles();
    return (
        <div>
            {/*<ImportantNotice/>*/}
            <span id="versionIndicator">Dev- v2.104.03</span>
            <Offline>
                <div style={{position: 'fixed', bottom: '0', zIndex: 1200}}>
                    <SnackbarContent anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }} className={classes.snackbar}
                                     message="You are currently offline. Connect to internet and try again."/>
                </div>
            </Offline>
            {/*<span id={"environmentIndicator"}>Development</span>*/}
        </div>
    );
}
