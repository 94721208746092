import React, {useContext, useEffect, useState} from "react";
import {ToastMessageContext} from "../../../lib/contexts/message_context";
import BasicStats from "./basic_stats";
import BatchInformation from "./batch_detail";
import Grid from "@material-ui/core/Grid";
import IncomeCategorySelection from "./income_category_selection";
import {useParams} from "react-router-dom";
import FileTransactionListDetails from "../TransactionList";
import AnalyticsClient from "../../../lib/services/api/analytics";
import TransactionFilterProvider from "../../../lib/contexts/transaction_filter_context";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import BackIcon from "@material-ui/icons/ArrowBackIos";
import EditIcon from "@material-ui/icons/Edit";
import BatchFilesClient from "../../../lib/services/api/files";
import PartialAnalysisWarning from "./partial_analysis_warning";
import FileSelection from "./file_selection";
import RenameCategory from "./rename_category";
import ActivityLog from "../ActivityLog/activity_log";
import Button from "@material-ui/core/Button";
import HistoryIcon from "@material-ui/icons/History";
import moment from "moment";
import ReportBug from "../../BugReport";
import scrollToTargetAdjusted from "../../../lib/utils/scroll";
import Backdrop from "@material-ui/core/Backdrop";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import {BatchNameUpdate} from "../../../components/universal/confirm_dialog";
import RiskSummary from "../RiskSummary";
import AccountSummaryDetail from "./account_summary_detail";
import NSFAndODTransactions from "../TransactionList/nsf_and_od_transactions";
import StatsByTimePeriod from "./timely_breakdown_stats";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import SectionNavbar from "./section_navbar";
import Tooltip from "@material-ui/core/Tooltip";
import {HelpOutlined} from "@material-ui/icons";

function OverallReport(props) {
    const [loading, setLoading] = useState(false);
    const [analysisData, setAnalysisData] = useState({});
    const [fullAnalysisData, setFullAnalysisData] = useState({});
    const [showEdit, setShowEdit] = useState(false);
    const Toast = useContext(ToastMessageContext);
    const params = useParams();
    const [counts, setCounts] = useState({});
    const [refresh, setRefresh] = useState(false);
    const [txnTypeContext, setTxnTypeContext] = useState("income");
    const [batchFiles, setBatchFiles] = useState([]);
    const [batchDetail, setBatchDetail] = useState({});
    const [txnUpdates, setTxnUpdates] = useState({"flag_txn": [], "flag_nsf": [], "flag_od": []});
    const [showRenameDialog, setShowRenameDialog] = useState(false);
    const [showLog, setShowLog] = useState(false);
    let sections = ['batch-detail-and-files', 'accounts-and-income-factors', 'transaction-list'];
    const tenant = JSON.parse(sessionStorage.getItem('user'))?.["tenant_name"]?.toUpperCase();
    const riskSummaryTenants = ["NEWFI", "DEMO", "FOUNDATION", "KIND_LENDING", "JET", "PRUDENT", "ONYGLO", "SMB", "SG_CAPITAL", "LOGAN", "AMERICAN_HERITAGE", "CLEAREDGE", "OAKTREE", "DEEPHAVEN", "ARCHOME", "SHARESTATES", "LOANSTORE", "CHANGE_HOME", "HOMEMAC", "STG_MORTGAGE", "CARRINGTON", "LENDZ", "FIRST_COLONY", "LENDSURE", "GRAND_BANK"];
    const cashFlowTenants = ["DEMO", "ONYGLO", "SMB", "SG_CAPITAL", "LOGAN", "AMERICAN_HERITAGE", "CLEAREDGE", "OAKTREE", "DEEPHAVEN", "ARCHOME", "LOANSTORE", "NEWFI", "CHANGE_HOME", "HOMEMAC", "STG_MORTGAGE", "LENDSURE", "GRAND_BANK", "CARRINGTON"];
    const NSFODListTenants = ["KIND_LENDING", "PRUDENT", "SMB", "DEMO", "SG_CAPITAL", "LOGAN", "AMERICAN_HERITAGE", "CLEAREDGE", "OAKTREE", "DEEPHAVEN", "ARCHOME", "LOANSTORE", "NEWFI", "CHANGE_HOME", "HOMEMAC", "STG_MORTGAGE", "CARRINGTON", "LENDZ", "FIRST_COLONY", "LENDSURE", "GRAND_BANK"];
    const [quickAnalysis, setQuickAnalysis] = useState({});

    if (riskSummaryTenants.includes(tenant)) {
        sections = sections.concat(['risk-summary']);
    }
    if (cashFlowTenants.includes(tenant)) {
        sections = sections.concat(['cash-flow-analysis']);
    }
    useEffect(() => {
        setLoading(true);
        document.body.style.overflow = "auto";

        AnalyticsClient.getFullAnalysis(params?.batchId).then((res) => {
            if (res?.success) {
                setFullAnalysisData(res.data);
                setAnalysisData(res?.data?.general_analysis);
                const txnCount = {
                    deposit_count: res?.data?.general_analysis?.card?.deposit_txn_count,
                    withdrawal_count: res?.data?.general_analysis?.card?.withdrawals_txn_count
                }
                setCounts(txnCount);
            }
        }).catch((e) => {
            Toast.showError("Something went wrong");
        }).finally((e) => {
            AnalyticsClient.getQuickAnalysis(params?.batchId).then((res) => {
                setQuickAnalysis(res.data);
            }).catch((e) => {
                Toast.showError("Something went wrong fetching quick summary. This is INTERNAL only");
            })
            setLoading(false);
        });


        BatchFilesClient.getBatchDetails(params?.batchId).then((res) => {
            if (res?.data?.files?.length > 0) {
                const sortedFiles = res?.data?.files?.sort(function (a, b) {
                    return moment(b.statement_date, "MM-DD-YYYY") - moment(a.statement_date, "MM-DD-YYYY");
                });
                setBatchFiles(sortedFiles);
                const batchDetail = res?.data?.batch;
                batchDetail.addresses = res?.data?.addresses;
                batchDetail.customerNames = res?.data?.customer_name;
                setBatchDetail(batchDetail);
            }
        }).catch((e) => {
            Toast.showError("Something went wrong while fetching activity details");
        });

    }, [params?.batchId, refresh])

    useEffect(() => {
        if(txnTypeContext === "expense"){
            window.document.body.style.borderLeft = "10px solid #f44336";
            window.document.body.style.borderRight = "10px solid #f44336";
        }
        else {
            window.document.body.style.borderRight = "unset"
            window.document.body.style.borderLeft = "unset"
        }
        return (() => {
            window.document.body.style.borderRight = "unset"
            window.document.body.style.borderLeft = "unset"
        })
    }, [txnTypeContext]);

    const handleRenameClose = (r) => {
        setShowRenameDialog(false);
        if(!r){
            setRefresh(!refresh);
        }
    }

    const handleStepClick = (label, idx) => {
        scrollToTargetAdjusted(label);
    }

    const handleEdit = (data) => {
        if (data['batchName']?.length > 0) {
            const newBatchDetail = {...batchDetail, name: data['batchName'], external_id: data['externalId']};
            setBatchDetail(newBatchDetail);
        }
        setShowEdit(false);
    }
    const knownFailures = ["UNSUPPORTED_LANGUAGE", "FILE_0_BYTES", "BAD_SCAN", "PAGES_MISSING", "INVALID_FILE"];

    const incomeMeta = analysisData?.table?.filter(t => t.description === "Average Income");
    const allFilesProcessed = batchDetail && ((batchDetail?.file_count === batchDetail.valid_files) || (batchDetail?.status?.indexOf("PROCESSED") === 0));
    const failedFiles = batchFiles?.filter(file => ((file?.status?.indexOf("PROCESS") === -1) && (file?.status !== "SPLIT_FILE")));
    const manualFiles = batchFiles?.filter(file => file?.status?.indexOf("PROCESSING_MANUALLY") > -1);
    const processedFiles = batchFiles?.filter(file => file?.status?.indexOf("PROCESSED") > -1);
    const canRequestManualFiles = failedFiles.filter((f) => !knownFailures.includes(f.status));
    return (
        <>
            {loading && <Backdrop open={true} invisible/>}
            <Grid container alignItems={"center"} justify={"center"} spacing={4} id={"sections-wrapper"}
                  style={{padding: '75px 0', width: '96%', margin: 'auto'}}>
                <Grid item xs={12}>
                    <div className={"corner-aligned"}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <IconButton onClick={() => props?.history.push('/')}><BackIcon/></IconButton>
                            <Typography variant="h6"
                                        component="span">#{params?.batchId || "..."} - {batchDetail?.external_id ? `[${batchDetail?.external_id}] - ` :""} {batchDetail?.name || "..."} -
                                Analysis &nbsp;
                                <Typography className={"clickable"} color={"primary"} variant={"h5"} component={"span"}
                                            onClick={() => {
                                                handleStepClick('income_period_calc');
                                                const element = document.getElementById('income_period_cal_input');
                                                if (element) {
                                                    element.focus();
                                                }
                                            }}>
                                    ({`${batchDetail?.income_period?.replace("MONTHS_12", "12 Months")?.replace("MONTHS_24", "24 Months").replace("MONTHS_2", "2 Months")}`})
                                </Typography>
                            </Typography>
                            <IconButton onClick={() => setShowEdit(true)}><EditIcon fontSize={"small"}/></IconButton>
                            {tenant === "DEEPHAVEN" && (<Tooltip placement={"right"} arrow title={<div>
                                <ul>
                                    <li>User can edit both batch name & loan number</li>
                                    <li>Batch name & Loan number allow special characters</li>
                                    <li>Loan number character limit: 15</li>
                                    <li>Please enter a unique Batch name & Loan number</li>
                                </ul>
                            </div>}>
                                <HelpOutlined style={{marginTop:'-12px'}} fontSize={"small"} className={"clickable"} color={"disabled"}/>
                            </Tooltip>)}
                        </div>
                        <div>
                            <Button color="primary" size="small" startIcon={<HistoryIcon/>}
                                    onClick={() => setShowLog(true)}>View Change History</Button>
                            <ReportBug batchDetails={batchDetail} files={batchFiles}/>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} style={{
                    paddingTop: 0,
                    paddingBottom: 0,
                    position: "sticky",
                    top: '65px',
                    background: "#fff",
                    zIndex: 2,
                    marginLeft: "-32px",
                }}>
                    <SectionNavbar sections={sections} refresh={refresh} batchId={params.batchId}/>
                </Grid>
                <Grid container spacing={4} style={{padding: '25px 0', background: "#6e6cfb10"}}>
                    {tenant !== "NEWFI" && batchDetail?.internal_admin_comments?.length > 2 && (
                        <Grid item xs={12} style={{padding: '8px'}}>
                            <Alert severity="error" variant={"outlined"}>
                                <AlertTitle className={"bold"}>
                                    <Typography variant={"subtitle2"}>Batch Review Comments</Typography>
                                </AlertTitle>
                                <Typography variant={"body1"}>{batchDetail?.internal_admin_comments}</Typography>
                            </Alert>
                        </Grid>)}
                    {tenant === "ANGELOAK" && fullAnalysisData?.general_analysis?.card?.new_qi?.message?.length > 2 && (
                        <Grid item xs={12} style={{padding: '8px'}}>
                            <Alert severity="warning" variant={"outlined"}>
                                <AlertTitle className={"bold"}>
                                    <Typography variant={"subtitle2"}>QI Calculation Note</Typography>
                                </AlertTitle>
                                <Typography variant={"body1"}>
                                    {fullAnalysisData?.general_analysis?.card?.new_qi?.message}
                                </Typography>
                            </Alert>
                        </Grid>)}
                    <Grid item xs={12} id={sections[0]}>
                        <BatchInformation loading={loading} batchId={params?.batchId}
                                          detail={fullAnalysisData?.customer_details} batchDetails={batchDetail}/>
                    </Grid>
                    <Grid item xs={12}>
                        <FileSelection
                            loading={loading}
                            batchId={params?.batchId}
                            batchFiles={batchFiles}
                            setRefresh={() => setRefresh(!refresh)}
                        />
                    </Grid>
                    {!loading && tenant !== "NEWFI" && <Grid item xs={12}>
                        <PartialAnalysisWarning
                            batchId={params?.batchId}
                            failedFiles={failedFiles}
                            manualFiles={manualFiles}
                            totalFiles={batchFiles?.length}
                            setRefresh={() => setRefresh(!refresh)}
                            status={batchDetail?.status}
                            canRequestManualFiles={canRequestManualFiles}
                        />
                    </Grid>}
                    <Grid item xs={12}>
                        <BasicStats
                            loading={loading}
                            data={fullAnalysisData?.batch_meta}
                            counts={counts}
                            refresh={refresh}
                            allFilesProcessed={allFilesProcessed}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{marginTop: '32px'}}>
                    <Typography variant="h5">Qualified Income Calculation</Typography>
                    {["SMB"].includes(tenant) && <div className="center" style={{justifyContent:'flex-end'}}>
                        <ToggleButtonGroup exclusive size={"small"} value={txnTypeContext} onChange={(e, val) =>setTxnTypeContext(val)}>
                            <ToggleButton color={"primary"} value="income" aria-label="income">
                                Income
                            </ToggleButton>
                            <ToggleButton color={"secondary"} value="expense" aria-label="expense">
                                Expense
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </div>}
                </Grid>
                <Grid item xs={12} style={{minHeight: '500px'}} id={sections[1]}>
                    <IncomeCategorySelection
                        loading={loading}
                        batchId={params?.batchId}
                        data={fullAnalysisData?.general_analysis?.card}
                        batchMeta={fullAnalysisData?.batch_meta}
                        incomeMeta={incomeMeta?.[0]}
                        refresh={refresh}
                        processedFiles={processedFiles}
                        setRefresh={() => setRefresh(!refresh)}
                        allFilesProcessed={allFilesProcessed}
                        batchFiles={batchFiles}
                        failedFiles={failedFiles}
                        failedFilesCount={+failedFiles?.length + manualFiles?.length}
                        txnUpdates={txnUpdates}
                        rename={() => setShowRenameDialog(true)}
                        popup={batchDetail?.is_user_reviewed === false}
                        txnTypeContext={txnTypeContext}
                        transactions={fullAnalysisData?.transactions?.deposits || []}
                    />
                </Grid>
                <Grid item xs={12} style={{marginTop: '32px'}}>
                    <Typography variant="h5">Transactions List</Typography>
                    {["SMB"].includes(tenant) && <div className="center" style={{justifyContent:'flex-end'}}>
                        <ToggleButtonGroup exclusive size={"small"} value={txnTypeContext} onChange={(e, val) =>setTxnTypeContext(val)}>
                            <ToggleButton color={"primary"} value="income" aria-label="income">
                                Income
                            </ToggleButton>
                            <ToggleButton color={"secondary"} value="expense" aria-label="expense">
                                Expense
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </div>}
                </Grid>
                <Grid item xs={12} id={sections[2]}>
                    <TransactionFilterProvider filterData={analysisData?.card} batchFiles={batchFiles}>
                        <FileTransactionListDetails
                            categoryData={fullAnalysisData?.general_analysis?.card}
                            loading={loading}
                            batchId={params?.batchId}
                            refresh={refresh}
                            setRefresh={() => setRefresh(!refresh)}
                            allFilesProcessed={allFilesProcessed}
                            txnUpdates={txnUpdates}
                            setTxnUpdates={setTxnUpdates}
                            batchFiles={batchFiles}
                            rename={() => setShowRenameDialog(true)}
                            transactions={fullAnalysisData?.transactions || {}}
                            txnTypeContext={txnTypeContext}
                        />
                    </TransactionFilterProvider>
                </Grid>
                {riskSummaryTenants.includes(tenant) && <>
                    <Grid item xs={12} style={{marginTop: '32px'}}>
                        <Typography variant="h5">Risk Summary</Typography>
                    </Grid>
                    <Grid item xs={12} style={{marginTop: '12px'}} id={sections[3]}>
                        <RiskSummary batchId={params?.batchId} refresh={refresh}/>
                    </Grid>
                </>}
                {NSFODListTenants.includes(tenant) ? <>
                    <Grid item xs={12} style={{marginTop: '32px'}}>
                        <Typography variant="h5">NSF/OD Transactions</Typography>
                    </Grid>
                    <Grid item md={12}>
                        <NSFAndODTransactions
                            deposits={fullAnalysisData?.transactions?.deposits}
                            withdrawals={fullAnalysisData?.transactions?.withdrawals}
                            loading={loading}
                        />
                    </Grid>
                </> : <Grid item md={12}>
                    <StatsByTimePeriod
                        data={fullAnalysisData?.general_analysis?.table}
                        allFilesProcessed={allFilesProcessed}
                        loading={loading}
                        incomePeriod={fullAnalysisData?.general_analysis?.card?.considered_income_time}
                    />
                </Grid>
                }
                {cashFlowTenants.includes(tenant) && <>
                    <Grid item xs={12} style={{marginTop: '32px'}}>
                        <Typography variant="h5">Cash Flow</Typography>
                    </Grid>
                    <Grid item md={12} id={sections[4]}>
                        <AccountSummaryDetail refresh={refresh} batchId={params?.batchId} qualifiedIncome={fullAnalysisData?.general_analysis?.card?.calculated_income}/>
                    </Grid>
                </>}
                <Grid item md={12}>
                    <pre style={{marginBottom: '150px'}}>
                        <Typography variant={"h6"}> Quick Analysis Data </Typography>
                        <code>
                        {JSON.stringify(quickAnalysis, null, 2)}
                        </code>
                    </pre>
                </Grid>
            </Grid>
            {fullAnalysisData?.general_analysis?.card?.all_categories?.length > 1 && (<RenameCategory
                txnCategories={fullAnalysisData?.general_analysis?.card?.[txnTypeContext === "expense"?"wd_all_categories" :"all_categories"]}
                showRenameDialog={showRenameDialog}
                handleClose={(r) => handleRenameClose(r)}
                batchId={params?.batchId}
                loading={loading}
                setRefresh={() => setRefresh(!refresh)}
                txnTypeContext={txnTypeContext}
            />)
            }
            <ActivityLog open={showLog} batchId={params?.batchId} handleClose={() => setShowLog(false)}/>
            <div style={{height:'125px'}}/>
        </>
    )
}

export default OverallReport;
