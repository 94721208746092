import React, {useContext, useState} from 'react';
import Button from "@material-ui/core/Button";
import RequestIcon from "@material-ui/icons/ContactMail";
import BatchClient from "../../lib/services/api/batches";
import {ToastMessageContext} from "../../lib/contexts/message_context";
import Typography from "@material-ui/core/Typography";


function RequestManualProcessing(props) {
    const {batchId, refresh} = props;
    const [loading, setLoading] = useState(false);
    const ToastMessage = useContext(ToastMessageContext);

    const handleSubmit = () => {
        setLoading(true);
        BatchClient.requestManualProcess(batchId).then((res) => {
            if (res.success) {
                ToastMessage.showSuccess("Batch sent for Manual processing");
                refresh();
            }
        }).catch((err) => {
            ToastMessage.showError(err?.message || "Something went wrong. Try again later")
        }).finally(() => {
            setLoading(false);
        })
    }

    return (
        <div>
            <Button size={"small"} disableElevation disabled={loading} variant={"outlined"} className={"warning-btn"}
                    startIcon={<RequestIcon/>} onClick={handleSubmit}>
                Request manual output
            </Button>
            <Typography variant="subtitle1" style={{fontSize: '0.7em'}}>*Output may take about 24hrs to reach
                you</Typography>
        </div>
    )
}

export default RequestManualProcessing;
