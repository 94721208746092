import React, {useContext, useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import Chip from "@material-ui/core/Chip";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CheckIcon from "@material-ui/icons/Check";
import RemoveIcon from "@material-ui/icons/Close";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import TextField from "@material-ui/core/TextField";
import {ToastMessageContext} from "../../../lib/contexts/message_context";
import AnalyticsClient from "../../../lib/services/api/analytics";
import InputAdornment from "@material-ui/core/InputAdornment";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import QualifiedIncome from "./qualified_income";
import Slide from "@material-ui/core/Slide";
import SaveConfirmation from "./show_save_information";
import LoadingIndicator from "../../../components/universal/loading_indicator";
import AccountClassMapping from "./account_class_change";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import AccountThresholdValue from "./account_large_deposit_threshold_update";
import {getCurrencyFormat} from "../../../lib/utils/common_utils";
import {Alert} from "@material-ui/lab";
import {InfoOutlined} from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
    categoryCard: {
        paddingTop: '5px',
        borderRadius: '10px',
        height: '486px',
        boxShadow: 'inset 0 0 2px 0px #e5e5e5, 0 0 2px 1px #e5e5e5',
    },
    categoryCardSmall: {
        marginBottom: '10px',
        paddingTop: '5px',
        borderRadius: '10px',
        boxShadow: 'inset 0 0 2px 0px #e5e5e5, 0 0 2px 1px #e5e5e5',
    },
    title: {
        fontSize: '14px'
    },
    value: {
        fontSize: '32px'
    },
    listWrap: {
        height: '350px',
        padding: '8px',
        overflowX: 'hidden',
        overflowY: 'auto'
    },
    exclude: {
        backgroundColor: '#ff000007'
    },
    include: {
        backgroundColor: '#00ff0007'
    },
    inputFields: {
        height: '52px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start'
    },
    appbar: {
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        top: 'auto',
        bottom: 0,
        backgroundColor: '#fff',
        color: '#000'
    }
}));


function IncomeCategorySelection(props) {
    const {
        txnUpdates,
        saveTxnUpdates,
        txnSaving,
        rename,
        loading,
        data,
        failedFiles,
        batchFiles,
        popup,
        batchMeta,
        txnTypeContext,
        transactions
    } = props;
    const classes = useStyles();
    const [incomeAccountType, setIncomeAccountType] = useState(0);
    const [incomeAccountNumber, setIncomeAccountNumber] = useState("All");
    const [sourceCategories, setSourceCategories] = useState([]);
    const [incomeCategories, setIncomeCategories] = useState([]);
    const [expenseSourceCategories, setExpenseSourceCategories] = useState([]);
    const [expenseCategories, setExpenseCategories] = useState([]);
    const [consideredIncome, setConsideredIncome] = useState([]);
    const [income, setIncome] = useState();
    const [ownership, setOwnership] = useState(100);
    const [expenseFactor, setExpenseFactor] = useState(50);
    const [change, setChange] = useState();
    const [saving, setSaving] = useState(false);
    const [updated, setUpdated] = useState([]);
    const [showMapping, setShowMapping] = useState(false);
    const [atypicalThreshold, setAtypicalThreshold] = useState(0);
    const [largeDepThreshold, setLargeDepThreshold] = useState(0);
    const [showThreshold, setShowThreshold] = useState(false);
    const tenant = JSON.parse(sessionStorage.getItem('user'))?.tenant_name?.toUpperCase();
    const Toast = useContext(ToastMessageContext);
    const [errorReset, setErrorReset] = useState(false);
    const smb = ["SMB"].includes(tenant);

    useEffect(() => {
        if (data) {
            if (data?.flag_ac_type) {
                const accType = (data?.flag_ac_type === "SAVINGS" || data?.flag_ac_type === "savings") ? 0 : ((data?.flag_ac_type === 'CHECKING' || data?.flag_ac_type === 'checking') ? 1 : 2);
                setIncomeAccountType(accType);
            }
            if (data?.excluded_categories?.length > 1) {
                setSourceCategories(data?.excluded_categories?.split(',') || []);
            }
            else {
                setSourceCategories([]);
            }
            if (data?.available_categories?.length > 1) {
                setIncomeCategories(data?.available_categories?.split(',') || []);
            }
            else {
                setIncomeCategories( []);
            }
            if(data?.wd_expense_categories?.length > 1) {
                setExpenseCategories(data?.wd_expense_categories?.split(',') || []);
            }
            if(data?.wd_non_expense_categories?.length > 1) {
                setExpenseSourceCategories(data?.wd_non_expense_categories?.split(',') || [])
            }
            if (data?.ownership_factor >= 0) {
                setOwnership(data?.ownership_factor);
            }
            if (data?.expense_factor >= 0) {
                setExpenseFactor(data?.expense_factor);
            }
            if (data?.considered_income_time) {
                setConsideredIncome(data?.considered_income_time);
            }
            if (data?.net_income) {
                setIncome(Math.round(data?.net_income));
            }
            if (data?.atypical_threshold) {
                setAtypicalThreshold(data?.atypical_threshold);
            }
            if (data?.large_deposit_threshold) {
                setLargeDepThreshold(data?.large_deposit_threshold);
            }
            if (data?.income_ac_numbers) {
                if (data?.income_ac_numbers.indexOf(",") < 0) {
                    setIncomeAccountNumber(data?.income_ac_numbers);
                } else {
                    setIncomeAccountNumber("All");
                }
            }
        }
        return (() => {
            setChange(null);
        })
    }, [props.data, props?.refresh, errorReset, txnTypeContext]);

    useEffect(() => {
        if (props?.incomeMeta && consideredIncome !== 'custom') {
            setIncome(Math.round(props?.incomeMeta?.[consideredIncome]));
        } else {
            setIncome(Math.round(data?.net_income) || 0);
        }
    }, [consideredIncome, props?.incomeMeta])

    const handleMove = (el, action) => {
        let newUpdated = updated;
        if (!newUpdated.includes(el)) {
            newUpdated.push(el);
            setUpdated(newUpdated);
        }
        if (action === "to_income") {
            if(txnTypeContext === "expense") {
                const final = expenseSourceCategories.filter(v => v !== el);
                const dest = expenseCategories.concat([el]);
                setExpenseSourceCategories(final);
                setExpenseCategories(dest);
            }
            else {
                const final = sourceCategories.filter(v => v !== el);
                const dest = incomeCategories.concat([el]);
                setSourceCategories(final);
                setIncomeCategories(dest);
            }
        } else {
            if(txnTypeContext === "expense") {
                const final = expenseCategories.filter(v => v !== el);
                const dest = expenseSourceCategories.concat([el]);
                setExpenseCategories(final);
                setExpenseSourceCategories(dest);
            }
            else {
                const final = incomeCategories.filter(v => v !== el);
                const dest = sourceCategories.concat([el]);
                setIncomeCategories(final);
                setSourceCategories(dest);
            }
        }
        setChange({...change, categories: Array.from(updated)?.join(", ")});
    }

    const saveChanges = (accountType, accountNumber) => {
        setSaving(true);
        const formData = new FormData();
        formData.append("income_categories", incomeCategories.join(","));
        formData.append("non_income_categories", sourceCategories.join(","));
        if(smb) {
            formData.append("wd_expense_categories", expenseCategories.join(","));
            formData.append("wd_non_expense_categories", expenseSourceCategories.join(","));
        }
        formData.append("considered_income_time", consideredIncome);
        formData.append("net_income", income);
        formData.append("ownership_factor", ownership);
        formData.append("expense_factor", expenseFactor);
        if (change?.atypicalThreshold === "Reset to Default") {
            formData.append("atypical_threshold", -1);
        } else {
            formData.append("atypical_threshold", atypicalThreshold);
        }
        if (change?.largeDepThreshold === "Reset to Default") {
            formData.append("large_deposit_threshold", -1);
        } else {
            formData.append("large_deposit_threshold", largeDepThreshold);
        }
        const accountTypeMap = ["SAVINGS", "CHECKING", " "];
        formData.append("flag_ac_type", (accountType?.target === undefined && accountType) ? accountType : accountTypeMap[incomeAccountType]);
        formData.append("income_ac_numbers", (accountNumber?.target === undefined && accountNumber) ? accountNumber : incomeAccountNumber);

        AnalyticsClient.updateAnalysis(props?.batchId, formData).then((res) => {
            if (res.success) {
                if (props?.setRefresh) {
                    props.setRefresh();
                }
            }
        }).catch((e) => {
            setErrorReset(!errorReset);
            Toast.showError(e?.message || "Something went wrong. Try again");
        }).finally(() => {
            setSaving(false);
            setChange(null);
        })
    }

    const getAccountNumber = (value) => {
        const chosenNumber = accountNumberOptions?.filter((op) => op.value === value)?.[0];
        return `${chosenNumber.value} - ${chosenNumber.label}`;
    }

    const accountNumberInfo = data?.account_number_info;
    let accountNumberOptions = [{label: "Include All Acc.No's", value: "All"}];
    if (accountNumberInfo?.FULL?.length > 0) {
        const checkingAccounts = accountNumberInfo?.FULL?.map((ac) => ({
            "label": `${ac?.captured_account_number? ac?.captured_account_number : ac?.account_number}: ${ac?.account_class}:${ac?.account_type}`,
            "value": ac?.account_number,
            "type": ac?.account_type,
            "disabled": incomeAccountType === 1,
            "message": "Savings only account type filter Applied"
        }))
        accountNumberOptions = accountNumberOptions.concat(checkingAccounts);
    }

    const accountTypeOptions = ["Checking Only", "Savings Only", "Both Checking and Savings"];
    let incomePeriodOptions = [];

    if (tenant === "ANGELOAK") {
        incomePeriodOptions = [{"key": "recent_2m", "label": "Recent 2m"}]
    }

    incomePeriodOptions = incomePeriodOptions.concat([
        {"key": "recent_12m", "label": "Recent 12m"},
        {"key": "recent_24m", "label": "Recent 24m"},
        {"key": "overall", "label": "Overall Available"}
    ]);

    const checkingAcc = accountNumberInfo?.CHECKING?.map(ac => `${ac?.captured_account_number? ac?.captured_account_number : ac?.account_number}: ${ac?.account_class}:${ac?.account_type}`);
    const savingAcc = accountNumberInfo?.SAVINGS?.map(ac => `${ac?.captured_account_number? ac?.captured_account_number : ac?.account_number}: ${ac?.account_class}:${ac?.account_type}`);
    const catMap = txnTypeContext === "expense" ? (data?.withdrawal_category_amount_agg || {})  : (data?.deposit_category_amount_agg || {});
    const incomeCategoriesAggMap = incomeCategories?.map(c => {return ({"category": c, "sum":catMap[c]?.sum})})?.sort((a,b) => +b.sum - +a.sum);
    const sourceCategoriesAggMap = sourceCategories?.map(c => {return ({"category": c, "sum":catMap[c]?.sum})})?.sort((a,b) => +b.sum - +a.sum);
    const expenseCategoriesAggMap = expenseCategories?.map(c => {return ({"category": c, "sum":catMap[c]?.sum})})?.sort((a,b) => +a.sum - +b.sum);
    const expenseSourceCategoriesAggMap = expenseSourceCategories?.map(c => {return ({"category": c, "sum":catMap[c]?.sum})})?.sort((a,b) => +a.sum - +b.sum);

    return (
        <>
            <Grid container spacing={3}>
                <Grid item md={4}>
                    {!loading ? (<form onSubmit={(e) => e.preventDefault()}>
                        <Card elevation={0} className={classes.categoryCardSmall}>
                            <CardContent style={{minHeight: '208px'}}>
                                <Typography className={classes.title} variant={"subtitle2"} align={"center"}
                                            gutterBottom>
                                    Accounts for Income
                                </Typography>
                                <Grid container alignItems="center" justify="space-around" spacing={4}>
                                    <Grid item xs={6}>
                                        <Typography className={classes.inputFields} variant="subtitle2"
                                                    color="textSecondary">
                                            Income Account Type:
                                            <br/>
                                        </Typography>
                                        <Typography className={classes.inputFields} variant="subtitle2"
                                                    color="textSecondary">
                                            Income Account No:
                                            <br/>
                                        </Typography>
                                        {["ANGELOAK"].includes(tenant) ?
                                            <Typography className={classes.inputFields} variant="subtitle2"
                                                        color="textSecondary">
                                                <br/>
                                                Atypical Threshold:
                                                <br/>
                                            </Typography> :
                                            <Typography className={classes.inputFields} variant="subtitle2"
                                                        color="textSecondary">
                                                <br/>
                                                Large Dep. Threshold:
                                                <br/>
                                            </Typography>}
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className={classes.inputFields}>
                                            <TextField
                                                color="primary"
                                                margin="dense"
                                                defaultValue={data?.income_account_type}
                                                select
                                                fullWidth
                                                variant="outlined"
                                                value={incomeAccountType}
                                                onChange={(e) => {
                                                    setIncomeAccountType(e.target.value);
                                                    setChange({
                                                        ...change,
                                                        income_account_type: accountTypeOptions[e.target.value]
                                                    });
                                                }}
                                            >
                                                {accountTypeOptions?.map((accType, i) => (
                                                    <MenuItem key={accType} value={i}>
                                                        {accType}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </div>
                                        <div className={classes.inputFields}>
                                            <br/>
                                            <Tooltip title={<div style={{color: "#fff", padding: '8px'}}>
                                                {checkingAcc?.length > 0 && <>
                                                    <Typography variant={"subtitle2"} color={"inherit"} gutterBottom>Checking
                                                        Account Numbers</Typography>
                                                    <ol>
                                                        {checkingAcc?.map((ca) => <li key={ca}>{ca}</li>)}
                                                    </ol>
                                                    <br/>
                                                </>}
                                                {savingAcc?.length > 0 && <>
                                                    <Typography variant={"subtitle2"} color={"inherit"} gutterBottom>Savings
                                                        Account Numbers</Typography>
                                                    <ol>
                                                        {savingAcc?.map((ca) => <li key={ca}>{ca}</li>)}
                                                    </ol>
                                                </>}
                                            </div>}>
                                                <Typography variant={"subtitle2"} className={"clickable"} gutterBottom>
                                                    {(checkingAcc?.length || 0) + (savingAcc?.length || 0)}&nbsp;
                                                    Acc. numbers in batch
                                                </Typography>
                                            </Tooltip>
                                            <Typography className={"clickable center"} variant={"subtitle1"}
                                                        style={{textDecoration: 'underline', fontSize: '13px'}}
                                                        color="primary"
                                                        onClick={() => setShowMapping(true)}>
                                                Manage Account Class</Typography>
                                            <Divider variant={"inset"}/>
                                        </div>
                                        <div className={classes.inputFields}>
                                            <br/><br/><br/>
                                            <OutlinedInput
                                                margin="dense"
                                                color="primary"
                                                fullWidth
                                                type="number"
                                                inputProps={{
                                                    min: 1,
                                                    step: 1
                                                }}
                                                value={["ANGELOAK"].includes(tenant) ? atypicalThreshold : largeDepThreshold}
                                                onChange={(e) => {
                                                    if (["ANGELOAK"].includes(tenant)) {
                                                        setAtypicalThreshold(e.target.value);
                                                        setChange({...change, atypicalThreshold: e.target.value});
                                                    } else {
                                                        setLargeDepThreshold(e.target.value);
                                                        setChange({...change, largeDepThreshold: e.target.value});
                                                    }
                                                }}
                                                endAdornment={<InputAdornment position="end">$</InputAdornment>}
                                            />
                                            <Typography className={"clickable"} variant={"subtitle1"}
                                                        style={{textDecoration: 'underline', fontSize: '12px'}}
                                                        color="primary"
                                                        onClick={() => {
                                                            if (["ANGELOAK"].includes(tenant)) {
                                                                setChange({
                                                                    ...change,
                                                                    atypicalThreshold: "Reset to Default"
                                                                })
                                                            } else {
                                                                setChange({
                                                                    ...change,
                                                                    largeDepThreshold: "Reset to Default"
                                                                })
                                                            }
                                                        }
                                                        }>
                                                Reset to Default
                                            </Typography>
                                        </div>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                        <Card elevation={0} className={classes.categoryCardSmall}>
                            <CardContent>
                                <Typography id={"income_period_calc"} className={classes.title} variant={"subtitle2"} align={"center"}
                                            gutterBottom>
                                    Income Calculation Factors
                                </Typography>
                                <Grid container alignItems="center" justify="space-around" spacing={4}>
                                    <Grid item xs={6}>
                                        <Typography className={classes.inputFields} variant="subtitle2"
                                                    color="textSecondary">
                                            {["ANGELOAK","ARCHOME"].includes(tenant) ? "Loan Program" : "Net Income Period"}:
                                        </Typography>
                                        <Typography className={classes.inputFields} variant="subtitle2"
                                                    color="textSecondary">
                                            Ownership:
                                        </Typography>
                                        <Typography className={classes.inputFields} variant="subtitle2"
                                                    color="textSecondary">
                                            Expense Factor:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className={classes.inputFields}>
                                            <TextField
                                                id={"income_period_cal_input"}
                                                color="primary"
                                                margin="dense"
                                                defaultValue={data?.considered_income_time}
                                                select
                                                fullWidth
                                                variant="outlined"
                                                value={consideredIncome}
                                                onChange={(e) => {
                                                    setConsideredIncome(e.target.value);
                                                    setChange({...change, income_time: e.target.value});
                                                }}
                                            >
                                                {incomePeriodOptions.map((period, i) => (
                                                    <MenuItem key={period.key} value={period.key}>
                                                        {period.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </div>
                                        <div className={classes.inputFields} style={{alignItems: 'right'}}>
                                            <OutlinedInput
                                                margin="dense"
                                                color="primary"
                                                fullWidth
                                                type="number"
                                                inputProps={{
                                                    min: 0,
                                                    max: 100,
                                                    step: 1
                                                }}
                                                value={ownership}
                                                onChange={(e) => {
                                                    setOwnership(e.target.value);
                                                    setChange({...change, ownership: e.target.value});
                                                }}
                                                endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                            />
                                        </div>
                                        <div className={classes.inputFields} style={{alignItems: 'right'}}>
                                            <OutlinedInput
                                                margin="dense"
                                                color="primary"
                                                fullWidth
                                                type="number"
                                                inputProps={{
                                                    min: 0,
                                                    max: 100,
                                                    step: 1
                                                }}
                                                value={expenseFactor}
                                                onChange={(e) => {
                                                    setExpenseFactor(e.target.value);
                                                    setChange({...change, expenseFactor: e.target.value});
                                                }}
                                                endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </form>) : (
                        <div style={{height: '450px'}}><LoadingIndicator variant="contained"/></div>
                    )
                    }
                </Grid>
                <Grid item md={4}>
                    <Card elevation={0} className={`${classes.categoryCard} ${classes.include}`}>
                        <CardContent>
                            <Typography className={classes.title} variant={"subtitle2"} align={"center"} gutterBottom>
                                {txnTypeContext === "expense" ? "Expense" : "Income"} Categories
                            </Typography>
                            <Typography className={"clickable center"} variant={"subtitle1"}
                                        style={{textDecoration: 'underline', fontSize: '13px'}} color="primary"
                                        onClick={rename}>
                                Rename Categories</Typography>
                            {!loading ? (<div className={`${classes.listWrap} scroll`}>
                                {(txnTypeContext === "expense" ? expenseCategoriesAggMap :incomeCategoriesAggMap)?.map((cat) => (
                                    <Chip
                                        className="clickable"
                                        clickable
                                        style={{
                                            margin: '5px',
                                            color: 'green',
                                            borderColor: 'green',
                                        }}
                                        variant="outlined"
                                        label={cat.sum ? `${cat?.category} (${getCurrencyFormat(cat?.sum)})`: cat.category}
                                        key={cat.category}
                                        onClick={() => incomeCategoriesAggMap?.length >= 2 ? handleMove(cat.category, 'to_exclude'): null}
                                        onDelete={(e) => incomeCategoriesAggMap?.length >= 2 ? handleMove(cat.category, 'to_exclude'): null}
                                        deleteIcon={incomeCategoriesAggMap?.length >= 2 ? <RemoveIcon/> : <Tooltip title={"At least one Category to be present as Income Category"}><InfoOutlined/></Tooltip>}
                                    />
                                ))}
                                {(txnTypeContext === "expense" ? expenseCategories :incomeCategories)?.length === 0 && <div style={{height: '100%'}} className="center">
                                    <Typography align="center" variant="subtitle1">
                                        No Categories selected as {txnTypeContext ? "expense" : "income"}
                                    </Typography>
                                </div>}
                            </div>) : (
                                <div style={{height: '318px'}}><LoadingIndicator variant="contained"/></div>
                            )}
                        < /CardContent>
                    </Card>
                </Grid>
                <Grid item md={4}>
                    <Card elevation={0} className={`${classes.categoryCard} ${classes.exclude}`}>
                        <CardContent>
                            <Typography className={classes.title} variant={"subtitle2"} align={"center"} gutterBottom
                                        style={{marginBottom: '12px'}}>
                                Non-{txnTypeContext === "expense" ? "Expense" : "Income"} Categories
                            </Typography>
                            {!loading ? (<div className={`${classes.listWrap} scroll`}>
                                {(txnTypeContext === "expense" ? expenseSourceCategoriesAggMap :sourceCategoriesAggMap)?.map((cat) => (
                                    <Chip
                                        className="clickable"
                                        clickable
                                        style={{margin: '5px'}}
                                        variant="outlined"
                                        label={cat.sum ? `${cat?.category} (${getCurrencyFormat(cat?.sum)})`: cat.category}
                                        key={cat.category}
                                        onClick={() => handleMove(cat.category, 'to_income')}
                                        onDelete={(e) => handleMove(cat.category, 'to_income')}
                                        deleteIcon={<CheckIcon/>}
                                    />
                                ))}
                                {(txnTypeContext === "expense" ? expenseSourceCategories :sourceCategories)?.length === 0 && (
                                    <div className="center" style={{height: '100%'}}>
                                        <Typography align={"center"} variant="subtitle1">
                                            All Categories are selected as {txnTypeContext}</Typography>
                                    </div>
                                )}
                            </div>) : (
                                <div style={{height: '336px'}}><LoadingIndicator variant="contained"/></div>
                            )}
                        < /CardContent>
                    </Card>
                </Grid>
            </Grid>
            <div>
                {((showMapping || (popup && ["ANGELOAK"].includes(tenant))) && !loading) &&
                <AccountClassMapping
                    accountNumberInfo={accountNumberInfo}
                    batchId={props?.batchId}
                    showMapping={showMapping}
                    setShowMapping={() => setShowMapping(false)}
                    refresh={props?.refresh}
                    setRefresh={props?.setRefresh}
                    popup={popup}
                />}
            </div>

            {showThreshold && <AccountThresholdValue batchId={props?.batchId} accountThresholdData={data?.account_large_deposit_thresholds} handleClose={() => {
                setShowThreshold(false);
                props.setRefresh(!props?.refresh);
            }}/>}

            <Slide appear={!loading} direction="up" in={!loading}>
                <AppBar elevation={0} position="fixed" className={classes.appbar}>
                    {change && <Toolbar style={{padding: '3px 3%'}}>
                        <SaveConfirmation saving={saving} changes={change} saveChanges={saveChanges}/>
                    </Toolbar>}
                    {(txnUpdates.flag_txn?.length > 0 || txnUpdates.flag_nsf?.length > 0 || txnUpdates.flag_od?.length > 0) &&
                    <Toolbar style={{padding: '3px 3%'}}>
                        <SaveConfirmation header={"You have made below changes to transactions."} saving={txnSaving}
                                          changes={txnUpdates} saveChanges={saveTxnUpdates} txnTypeContext={txnTypeContext}/>
                    </Toolbar>}
                    <Toolbar style={{padding: '3px 3%'}}>
                        <QualifiedIncome
                            batchFiles={batchFiles}
                            batchId={props?.batchId}
                            saving={saving || txnSaving}
                            txnChanges={txnUpdates}
                            changes={change}
                            metaData={data}
                            failedFiles={failedFiles}
                            batchMeta={batchMeta}
                            txnTypeContext={txnTypeContext === "expense"}
                            refresh={props?.refresh}
                            setRefresh={props?.setRefresh}
                            transactions={transactions}
                        />
                    </Toolbar>
                </AppBar>
            </Slide>
        </>
    );
}

export default IncomeCategorySelection;
