import React, {useEffect} from 'react';
import PageNotFound from "../../../components/universal/page_not_found";

export const requireAuth = (allowedRoles) => (ComposedComponent) => {

    function Authentication(props) {
        useEffect(() => {
            const user = JSON.parse(sessionStorage.getItem('user'));
            const userRole = user?.user_role;
            if (!userRole) {
                window.location.href = '/auth/signin';
            }
            //eslint-disable-next-line react-hooks/exhaustive-deps
        });
        const user = JSON.parse(sessionStorage.getItem('user'));
        const userRole = user?.user_role;
        if (allowedRoles.includes(userRole)) {
            return <ComposedComponent {...props} role={userRole} />
        } else {
            return <PageNotFound />;
        }
    }

    return (Authentication);
};
