import React, {createContext, useState} from "react";

export const EventsContext = createContext({});

export default function EventsProvider({children}) {

    const GA_TRACKING_ID = 'G-458P5FDVMG';

    const [event, setEvent] = useState({
        category: 'Page View',
        action: 'Page Opened',
        label: 'Dashboard',
        value: 1
    });

    const [notificationCount, setNotificationCount] = useState(0);

    const recordEvent = (category = "Default", action = "Click", label = "Button Clicked", value = 999) => {
        window.gtag && window.gtag('event', action, {
            event_category: category,
            event_label: label,
            value: value
        })
    }

    const recordException = (description) => {
        window.gtag && window.gtag('exception', {
            description: description,
            fatal: true
        })
    }

    const recordPageView = (page) => {
        window.gtag && window.gtag('config', GA_TRACKING_ID, {
            page_location: page
        })
    }

    let eventValue = {
        event,
        recordEvent,
        recordException,
        recordPageView,
        setEvent,
        notificationCount,
        setNotificationCount
    };
    return (
        <EventsContext.Provider value={eventValue}>
            {children}
        </EventsContext.Provider>
    );
}
