import request from "./common/api_client";

function getBatchDetails(id) {
    return request({
        url: `/v1/admin/batch/${id}`,
        method: 'GET'
    })
}

function downloadJsonFile(batchId, fileId, params) {
    return request({
        url: `/v1/admin/batch/${batchId}/get-json-file/${fileId}`,
        method: 'GET',
        params: params
    })
}

function createBatchFile(url, formData) {
    return request({
        url: url,
        method: 'POST',
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

function deleteFile(id) {
    return request({
        url: '/batch',
        method: 'DELETE',
        data: {id: id}
    })
}

function deleteFiles(batchId, ids) {
    return request({
        url: `/v1/admin/batch/${batchId}/file/files-delete`,
        method: 'DELETE',
        data: {file_ids: ids}
    })
}

function downloadBatchFile(batchId, fileId) {
    return request({
        url: `/v1/admin/batch/${batchId}/file/${fileId}`,
        method: 'GET'
    })
}

function downloadBatchResultFile(batchId, params) {
    return request({
        url: `/v1/admin/batch/${batchId}/download`,
        method: 'GET',
        params: params
    })
}

function downloadBatchPartialResultFile(batchId) {
    return request({
        url: `/v1/admin/batch/${batchId}/process/manual/get-temp-output`,
        method: 'GET'
    })
}

function cancelManualRequest(batchId, formData) {
    return request({
        url: `/v1/admin/batch/${batchId}/cancel-process/manual`,
        method: 'POST',
        data: formData
    });
}

function updateFileStatus(batchId, fileId, formData){
    return request({
        url: `/v1/admin/batch/${batchId}/file/${fileId}/status`,
        method: 'POST',
        data: formData
    })
}

function includeExcludeAnalysis(batchId, fileId, formData){
    return request({
        url: `/batch/${batchId}/file/${fileId}/includes-analysis`,
        method: 'POST',
        data: formData
    })
}

function updateAccountClassMapping(batchId, formData) {
    return request({
        url: `/batch/${batchId}/analysis/update-account-class`,
        method: 'POST',
        data: formData
    });
}

function submitManualQuickResponse(batchId, formData) {
    return request({
        url: `/v1/admin/batch/${batchId}/manual-status-update`,
        method: 'POST',
        data: formData
    });
}

const BatchFilesClient = {
    getBatchDetails,
    downloadJsonFile,
    createBatchFile,
    deleteFile,
    downloadBatchFile,
    downloadBatchResultFile,
    downloadBatchPartialResultFile,
    cancelManualRequest,
    updateFileStatus,
    updateAccountClassMapping,
    includeExcludeAnalysis,
    deleteFiles,
    submitManualQuickResponse
}

export default BatchFilesClient;
