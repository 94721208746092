import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import StatementIcon from "@material-ui/icons/DescriptionOutlined";
import Typography from "@material-ui/core/Typography";
import ErrorIcon from "@material-ui/icons/Cancel";
import WarningIcon from "@material-ui/icons/Warning";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import List from "@material-ui/core/List";
import {ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import React from "react";
import {useStyles} from "./index";

function FileWiseRiskItems(props) {
    const classes = useStyles();
    const totalErrors = +props?.risk?.critical_counts + +props?.risk?.warning_counts;
    return <Accordion
        style={{marginBottom: "16px", boxShadow: "unset"}}>
        <AccordionSummary disabled={!(props?.risk?.critical_counts || props?.risk?.warning_counts)}
                          className={`${classes.accordionTitle} ${props.risk?.type}`}
                          id={props.risk?.filename}
                          expandIcon={totalErrors ? <ExpandMoreIcon/> : null}
        >
            <div className={"corner-aligned"}>
                <div className={"center"} style={{paddingRight: '48px'}}>
                    <StatementIcon color={"primary"}/>&nbsp;&nbsp;
                    <Typography className={`${classes.heading} center`}>{props?.risk?.filename} &nbsp; ({props?.risk?.statement_period})</Typography>
                </div>
                <Typography component={"div"} className={`${classes.heading} center`}>
                    {+props?.risk?.critical_counts > 0 && <><ErrorIcon style={{marginLeft:'16px'}} htmlColor="#E25950"/>&nbsp;{props?.risk?.critical_counts}</>}
                    {+props?.risk?.warning_counts > 0 && <><WarningIcon style={{marginLeft:'16px'}} htmlColor="#F5C163"/>&nbsp;{props?.risk?.warning_counts}</>}
                </Typography>
            </div>
        </AccordionSummary>
        <AccordionDetails>
            <div className={classes.root} style={{borderBottom: '1px solid #dfdfdf'}}>
                <div className={classes.riskDetail}>
                    <Typography className={classes.heading} gutterBottom>
                        Failed Checks:
                    </Typography>
                    <List>
                        {props?.risk?.tests_list?.map(f => (<ListItem key={f}>
                            <ListItemIcon>
                                <InfoIcon htmlColor="#E25950"/>
                            </ListItemIcon>
                            <ListItemText style={{fontWeight:'400'}}>
                                {f}
                            </ListItemText>
                        </ListItem>))}
                    </List>
                </div>
            </div>
        </AccordionDetails>
    </Accordion>;
}

export default FileWiseRiskItems