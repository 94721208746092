import React from "react";
import tableIcons, {tableOptions} from "../../../components/universal/table_attributes";
import MaterialTable from "material-table";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import {getCurrencyFormat} from "../../../lib/utils/common_utils";

function NSFAndODTransactions(props) {
    const {loading, deposits, withdrawals} = props;
    const allTransactions = !loading ? (withdrawals?.length > 0 ? deposits.concat(withdrawals) : deposits) : [];
    let filteredData = allTransactions?.filter(d => (d?.is_nsf || d?.is_od) && d?.account_type?.toUpperCase() === "CHECKING" && d?.account_class?.toUpperCase() !== "EXCLUDE");
    filteredData = filteredData?.sort((a,b) => new Date(a['date']) - new Date(b['date']));
    const columns = [
        {
            title: 'Filename',
            field: 'filename',
            headerStyle: {
                textAlign: 'left'
            },
            cellStyle: {
                textAlign: 'left',
            },
            width: '25%',
            render: rowData => <Typography variant="body2" color={"primary"}>{rowData['filename']}</Typography>
        },
        {
            title: 'Statement Period',
            field: 'id',
            width: '10%',
            render: rowData => <Typography variant={"body2"}>
                {moment(`01-${rowData['month']}-${rowData['year']}`, 'DD-MM-YYYY').format('MMM-YYYY')}
            </Typography>
        },
        {
            title: 'Account Number',
            field: 'account_number',
            width: '15%',
            render: rowData => <Typography variant={"body1"}>
                {rowData['captured_account_number'] || rowData['account_number']}
            </Typography>
        },
        {
            title: 'Description',
            field: 'description',
            width: '20%',
            render: rowData => <Typography variant={"body1"}>
                {rowData['description']}
            </Typography>
        },
        {
            title: 'Amount',
            field: 'amount',
            render: rowData => <Typography variant={"body1"}>
                {getCurrencyFormat(rowData['amount'], 2)}
            </Typography>
        },
        {
            title: 'NSF/OD',
            field: 'id',
            render: rowData => <Typography variant="body2">
                {rowData['is_nsf'] ? "NSF": "OD"}
            </Typography>
        },
    ];


    let MITableOptions = {
        ...tableOptions,
        padding: "dense",
        pageSize: 12,
        pageSizeOptions: [10, 20, 30],
        showTitle: false,
        paging: true,
        filtering: false,
        searchable: false,
        selection: false,
        sorting: false,
        toolbar: false,
        headerStyle: {
            backgroundColor: "#FAFAFB",
            borderBottom: "1px solid #EDF2F7",
            textTransform: "unset"
        },
        maxBodyHeight: 400
    }

    return (
        <div>
            <MaterialTable
                style={{boxShadow: 'unset'}}
                columns={columns}
                data={filteredData}
                isLoading={loading}
                options={MITableOptions}
                icons={tableIcons}
                localization={{
                    body: {
                        emptyDataSourceMessage: 'No details found',
                    }
                }}
            />
        </div>
    )
}

export default NSFAndODTransactions;