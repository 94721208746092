function scrollToTargetAdjusted(id, headerOffset = 130) {
    const element = document.getElementById(id);
    if(element){
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

        window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
        });
    }
}

export default scrollToTargetAdjusted;