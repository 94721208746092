import React from 'react';
import Countdown from 'react-countdown';
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import ClockIcon from "@material-ui/icons/AccessAlarm";

function SLACountDown(props) {
    const {createdAt, sla, status} = props;
    const slaExceeded = sla.diff(moment.utc()) < 0;
    const showSla = status?.indexOf("PROCESSING") > -1;

    return (
        <div>
            {createdAt && <Typography variant={"body1"} gutterBottom>{createdAt.format("DD-MMM HH:mm:SS")}</Typography>}
            {showSla &&
            <div style={{display: 'flex', alignItems: 'center'}} className={slaExceeded ? "negative" : "positive"}>
                <ClockIcon/>&nbsp;
                <Typography variant={"subtitle2"} className={slaExceeded ? "negative" : "positive"}>
                    <Countdown
                        date={sla}
                        overtime
                        zeroPadTime={2}
                    />
                </Typography>
            </div>}
        </div>
    )
}

export default SLACountDown;