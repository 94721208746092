import React, {useContext, useEffect, useState} from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import TextField from "@material-ui/core/TextField";
import HelpClient from "../../lib/services/api/help";
import {ToastMessageContext} from "../../lib/contexts/message_context";
import useDebounce from "../../lib/utils/debounce";
import {makeStyles} from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import CloseIcon from "@material-ui/icons/Cancel";
import SearchIcon from "@material-ui/icons/Search";
import BankIcon from "@material-ui/icons/AccountBalance";
import Typography from "@material-ui/core/Typography";
import LoadingIndicator from "./loading_indicator";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '250px',
        maxHeight: '450px',
        width: '100%',
        overflowY: 'scroll',
        overflowX: 'hidden'
    },
    list: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        flexGrow: 1
    },
    listItem: {
        width: '50%',
    },
    clear: {
        cursor: 'pointer'
    },
    search: {
        marginLeft: '50%'
    },
    searchField: {
        width: '90%',
    },
    content: {
        minHeight: '350px',
        minWidth: '902px',
        overflow: 'hidden'
    },
    title: {
        fontSize: '1.2em',
        marginBottom: '12px'
    }
}));

function EnabledBanksList(props) {

    const classes = useStyles();
    const [banks, setBanks] = useState([]);
    const [filteredBanks, setFilteredBanks] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState("");
    const bankInSearch = useDebounce(searchText, 600);
    const Toast = useContext(ToastMessageContext);

    useEffect(() => {
        setLoading(true);
        HelpClient.getEnabledBanksList().then((res) => {
            if (res.success) {
                setBanks(res.data);
                setFilteredBanks(res.data);
            }
        }).catch((e) => {
            Toast.showError("Something went wrong. Try Again");
        }).finally(() => {
            setLoading(false);
        })
    }, [])

    useEffect(() => {
        if (bankInSearch?.length > 1) {
            const banksMatchingSearch = banks.filter(bank => bank?.name?.toLowerCase()?.replaceAll(" ", "").indexOf(bankInSearch.toLowerCase().replaceAll(" ", "")) > -1);
            setFilteredBanks(banksMatchingSearch);
        } else {
            setFilteredBanks(banks);
        }
    }, [bankInSearch])

    const user = JSON.parse(sessionStorage.getItem('user'))
    return (
        <div className={classes.root}>
            <div style={{padding: '5px 20px'}}>
                <Typography variant={"subtitle2"} align={"center"}>Can't find your bank here?
                    Upload the data and request manual output.<br/>
                    We'll send you the data in 24 hours and also automatically configure it for you.
                </Typography>
            </div>
            <Grid container alignItems={"center"}>
                <Grid item xs={12} md={6}>
                    <Typography style={{paddingLeft: '24px'}} variant={"subtitle2"}>No. of banks
                        : {filteredBanks?.length}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        autoFocus
                        className={classes.searchField}
                        fullWidth
                        value={searchText}
                        placeholder={"Search"}
                        onChange={(e) => setSearchText(e.target.value)}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">
                                <SearchIcon color={"disabled"}/></InputAdornment>,
                            endAdornment: <InputAdornment className={classes.clear} position="start"
                                                          onClick={() => setSearchText("")}>
                                <CloseIcon color={"disabled"}/></InputAdornment>,
                        }}
                    />
                </Grid>
            </Grid>
            {!loading && filteredBanks?.length > 0 ? <List className={classes.list}>
                {filteredBanks?.map((bank, i) => <ListItem key={i} className={classes.listItem}>
                    <ListItemAvatar>
                        <BankIcon/>
                    </ListItemAvatar>
                    <ListItemText>
                        {bank.name} {user.role === "SUPERADMIN" ? <b>- {bank?.bank_key}</b> : null}
                        <Typography
                            variant="caption" color="textSecondary" component="div"
                            style={{marginTop: '5px', fontSize: '12px'}}>
                            {`Available from : ${bank.created_at || "April 14, 2021"}`}
                        </Typography>
                    </ListItemText>
                </ListItem>)}
            </List> : <div>
                {!loading ?
                    <div className="center" style={{height: '200px', padding: '5px 25px'}}><Typography
                        variant={"subtitle1"} color={"primary"}>Try short form of bank eg (BoA - Bank of America), if
                        tried and not found Bank might have not been configured yet.
                        We suggest uploading the statements and wait for max of 24 hrs to get the output after
                        requesting manual output<br/>
                    </Typography></div> :
                    <div style={{height: '200px'}}>
                        <LoadingIndicator variant={"contained"}/>
                    </div>}
            </div>}
        </div>
    )
}

export function EnabledBanksListDialog(props) {

    const classes = useStyles();
    const {open, onClose} = props;

    return (
        <Dialog open={open} onClose={onClose} maxWidth={"md"}>
            <DialogTitle>
                <Typography className={classes.title} align={"center"} variant={"subtitle2"} color={"primary"}>
                    Available Banks
                </Typography>
            </DialogTitle>
            <DialogContent className={classes.content}>
                <EnabledBanksList/>
            </DialogContent>
        </Dialog>
    )
}

export default EnabledBanksList;
