import React, {useContext, useEffect, useState} from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import {TextField} from "@material-ui/core";
import {ToastMessageContext} from "../../../lib/contexts/message_context";
import AnalyticsClient from "../../../lib/services/api/analytics";
import {groupByKey, unique} from "../../../lib/utils/common_utils";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

function CustomerInfoByAccountNumber(props) {
    const {customerData, setCustomerData, customerDetails} = props;
    return (
        <Grid container alignItems={"flex-start"} justify={"center"} spacing={3}>
            <Grid item xs={3}>
                <Typography variant={"subtitle2"} gutterBottom color={"primary"}>
                    Account Number
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography variant={"subtitle2"} gutterBottom color={"primary"}>
                    Customer Name
                </Typography>
            </Grid>
            <Grid item xs={5}>
                <Typography variant={"subtitle2"} gutterBottom color={"primary"}>
                    Customer Address
                </Typography>
            </Grid>
            {customerDetails?.map((cd, i) => <React.Fragment key={cd.account_number}>
                <Grid item xs={3}>
                    <Typography variant={"subtitle2"} gutterBottom>
                        {cd.account_number}
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        margin={"dense"}
                        fullWidth
                        multiline={true}
                        rows={4}
                        variant={"standard"}
                        defaultValue={cd.customer_name}
                        onChange={(e) => {
                            let newCustomerData = customerData;
                            newCustomerData[i]['customer_name'] = e.target.value;
                            setCustomerData(newCustomerData);
                        }}
                    />
                </Grid>
                <Grid item xs={5}>
                    <TextField
                        margin={"dense"}
                        fullWidth
                        multiline={true}
                        rows={4}
                        variant={"standard"}
                        defaultValue={cd.customer_address}
                        onChange={(e) => {
                            let newCustomerData = customerData;
                            newCustomerData[i]['customer_address'] = e.target.value;
                            setCustomerData(newCustomerData);
                        }}
                    />
                </Grid>
            </React.Fragment>)}
        </Grid>
    )
}

function CustomerInfoByNameAddress(props) {
    const {customerData, setCustomerData, customerDetails} = props;
    const groupedCustomerData = groupByKey(customerDetails, 'customer_name');
    const handleChange = (key, value, newValue) => {
        const newCustomerData = customerData?.map((cd) => {
            let newData = cd;
            if (cd[key] === value) {
                newData[key] = newValue;
            }
            return newData;
        })
        setCustomerData(newCustomerData)
    }

    return (
        <Grid container alignItems={"flex-start"} justify={"center"} spacing={4}>
            <Grid item xs={3}>
                <Typography variant={"subtitle2"} gutterBottom color={"primary"}>
                    Account Numbers
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography variant={"subtitle2"} gutterBottom color={"primary"}>
                    Current Details
                </Typography>
            </Grid>
            <Grid item xs={5}>
                <Typography variant={"subtitle2"} gutterBottom color={"primary"}>
                    Update as
                </Typography>
            </Grid>

            {
                groupedCustomerData?.map((cd, i) =>
                    <React.Fragment key={cd.key}>
                        <Grid item xs={3}>
                            <Typography variant={"subtitle2"} gutterBottom>
                                {unique(cd.values.map(d => d.account_number)).join(", ")}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant={"subtitle2"} gutterBottom>
                                Name: {cd.key}
                            </Typography>
                            <Typography variant={"subtitle2"} gutterBottom>
                                Address: {cd.values?.[0]?.customer_address}
                            </Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <TextField
                                margin={"dense"}
                                label={"Name"}
                                color={"primary"}
                                fullWidth
                                multiline={true}
                                rows={2}
                                variant={"standard"}
                                defaultValue={cd.key}
                                onBlur={(e) => {
                                    handleChange('customer_name', cd.key, e.target.value);
                                }}
                            />
                            <TextField
                                style={{marginTop: "24px"}}
                                margin={"dense"}
                                color={"primary"}
                                label={"Address"}
                                fullWidth
                                multiline={true}
                                rows={4}
                                variant={"standard"}
                                defaultValue={cd.values?.[0]?.customer_address}
                                onBlur={(e) => {
                                    handleChange('customer_address', cd.values?.[0]?.customer_address, e.target.value);
                                }}
                            />
                            <Grid item xs={12} style={{minHeight: "24px"}}/>
                        </Grid>
                    </React.Fragment>
                )
            }
        </Grid>
    )
}

function CustomerDetailsEdit(props) {
    const {batchId, customerDetails, setCustomerDetail, setShowEdit} = props;
    const [updating, setUpdating] = useState(false);
    const [customerData, setCustomerData] = useState([]);
    const [editBy, setEditBy] = useState('name_address');
    const Toast = useContext(ToastMessageContext);

    useEffect(() => {
        setCustomerData(JSON.parse(JSON.stringify(customerDetails)));
    }, [customerDetails, editBy]);

    const saveCustomerDetails = () => {
        setUpdating(true);
        AnalyticsClient.updateAccountWiseCustomerData(batchId, {
            "customer_info": customerData
        }).then((res) => {
            if (res.success) {
                Toast.showSuccess("Customer details updated successfully");
                setCustomerDetail(customerData);
            }
        }).catch((e) => {
            Toast.showError(e?.message || "Something went wrong while updating customer details");
        }).finally(() => {
            setUpdating(false);
            setShowEdit(false);
        })
    }

    return (
        <div>
            <Dialog
                maxWidth="md"
                fullWidth
                open={true}
                onClose={() => setShowEdit(false)}
            >
                <DialogContent style={{padding: "24px 48px"}}>
                    <Typography variant={"subtitle2"} style={{fontSize: "24px"}} gutterBottom>
                        Edit Customer Details
                    </Typography>
                    <div style={{padding: '24px', paddingTop: 0}}>
                        <Tabs
                            style={{borderBottom: '1px solid #d5d5d5'}}
                            variant="fullWidth"
                            value={editBy}
                            onChange={(e, v) => setEditBy(v)}
                            indicatorColor="primary"
                        >
                            <Tab label="Update by Name and Address" value={"name_address"}/>
                            <Tab label="Update by Account Number" value={"account_number"}/>
                        </Tabs>
                        <div style={{height: '24px'}}/>
                        {editBy === "account_number" && <CustomerInfoByAccountNumber customerDetails={customerDetails}
                                                                                     customerData={customerData}
                                                                                     setCustomerData={setCustomerData}/>}
                        {editBy === "name_address" && <CustomerInfoByNameAddress customerDetails={customerDetails}
                                                                                 customerData={customerData}
                                                                                 setCustomerData={setCustomerData}/>}
                    </div>
                </DialogContent>
                <DialogActions className={"center"} style={{padding: "24px"}}>
                    <Button disableElevation onClick={() => setShowEdit(false)} variant={"contained"}>
                        Cancel
                    </Button>
                    <Button disabled={updating} startIcon={updating && <CircularProgress size={15}/>} size={"small"}
                            variant={"contained"} color="primary" onClick={() =>saveCustomerDetails()}>
                        Save Details
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default CustomerDetailsEdit;