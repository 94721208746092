import React, {useContext, useEffect, useState} from "react";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import {makeStyles} from "@material-ui/core/styles";
import StatsClient from "../../lib/services/api/statistics";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import moment from "moment";
import EditIcon from "@material-ui/icons/Edit";
import DateRangeDialog from "../../components/universal/date_range_dialog";
import Button from "@material-ui/core/Button";
import UserClient from "../../lib/services/api/user";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import {ToastMessageContext} from "../../lib/contexts/message_context";

const useStyles = makeStyles(theme => ({
    root: {
        padding: '2% 3%'
    },
    title: {
        fontSize: '2em',
        padding: '5px 0'
    }
}))

function RawStats(props) {

    const classes = useStyles();
    const [data, setData] = useState([]);
    const [fromDate, setFromDate] = useState(moment().startOf('month').format('DD-MM-YY'));
    const [toDate, setToDate] = useState(moment().endOf('month').format('DD-MM-YY'));
    const [open, setOpen] = useState(false);
    const [tenantList, setTenantList] = useState([]);
    const [tenant, setTenant] = useState();

    const Toast = useContext(ToastMessageContext);

    useEffect(() => {
        UserClient.getTenants().then((res) => {
            if (res?.results?.length > 0) {
                setTenantList(res?.results);
            }
        }).catch((e) => {
        })
    }, [])


    const handleDateRange = (range) => {
        setFromDate(moment(range?.startDate).format("DD-MM-YY"));
        setToDate(moment(range?.endDate).format("DD-MM-YY"));
        setOpen(false);
    }

    useEffect(() => {
        const params = {
            date_from: fromDate,
            date_to: toDate,
            tenant_id: tenant
        }
        StatsClient.getBatchStats(params).then((res) => {
            if (res.success) {
                let resultData = []
                for (let key in res.data) {
                    if (res.data?.hasOwnProperty(key)) {
                        let obj = {
                            name: key?.replace("lte", "Upto")?.replace("lt", "less than"),
                            value: res.data[key] || 0
                        }
                        resultData.push(obj)
                    }
                }
                setData(resultData);
            }
        })
    }, [fromDate, toDate, tenant])

    const downloadReport = () => {
        const params = {
            date_from: fromDate,
            date_to: toDate,
            tenant_id: tenant
        }
        StatsClient.getBatchReportDownloadUrl(params).then((res) => {
            if (res.success) {
                window.open(res?.data?.uri);
            }
        }).catch((e) => {
            Toast.showError(e.message);
        })
    }

    const downloadReportByType = (report_type) => {
        const params = {
            report_type: report_type,
            date_from: fromDate,
            date_to: toDate,
            tenant_id: tenant,
            file_name: report_type === "DAILY_REPORT" ? `DAILY_REPORT_${moment(toDate, "DD-MM-YY").format("YYYY-MM-DD")}.xlsx` : undefined
        }

        StatsClient.getReportDownloadURL(params).then((res) => {
            if (res.success) {
                window.open(res?.data?.uri);
            }
        }).catch((e) => {
            Toast.showError(e.message);
        })
    }

    return (
        <div className={classes.root}>
            <div className={"corner-aligned"}>
                <Typography className={classes.title} variant={'subtitle2'} gutterBottom>
                    Detailed Stats :
                </Typography>
                <div className={"corner-aligned"}>
                    <TextField
                        color="primary"
                        margin="dense"
                        label={"Tenant Filter"}
                        placeholder={"All Tenants"}
                        style={{width: '300px'}}
                        select
                        variant="outlined"
                        value={tenant}
                        onChange={(e) => {
                            setTenant(e.target.value);
                        }}
                    >
                        {tenantList.map((t, i) => (
                            <MenuItem key={t.id} value={t.id}>
                                {t.name}
                            </MenuItem>
                        ))}
                    </TextField>
                    <div style={{marginLeft: '16px'}}>
                        <Typography color={"primary"} variant={"subtitle2"}
                                    style={{display: 'flex', alignItems: 'center'}}>
                            From: {fromDate} To: {toDate} &nbsp;&nbsp;&nbsp;
                            <EditIcon size={"small"}
                                      className={"clickable"}
                                      color={"disabled"}
                                      onClick={() => setOpen(true)}/>
                        </Typography>
                    </div>
                </div>
            </div>
            {data.length > 17 && <Grid container spacing={2}>
                <Grid item md={4} xs={12}>
                    <List>
                        {data?.slice(0, 8).map(d => <React.Fragment key={d.name}><ListItem key={d.name}>
                                <div className="corner-aligned" style={{width: '100%'}}>
                                    <Typography style={{fontSize: '14px'}} varaint={"subtitle1"} className="capitalize">
                                        {d.name?.replaceAll("_", " ")}
                                    </Typography>
                                    <Typography variant={"subtitle2"} className="capitalize">
                                        {d.value}
                                    </Typography>
                                </div>
                            </ListItem>
                                <Divider variant="fullWidth" component="li"/>
                            </React.Fragment>
                        )}
                    </List>
                </Grid>
                <Grid item md={4} xs={12}>
                    <List>
                        {data?.slice(8, 16).map(d => <React.Fragment key={d.name}><ListItem key={d.name}>
                                <div className="corner-aligned" style={{width: '100%'}}>
                                    <Typography style={{fontSize: '14px'}} varaint={"subtitle1"} className="capitalize">
                                        {d.name?.replaceAll("_", " ")}
                                    </Typography>
                                    <Typography variant={"subtitle2"} className="capitalize">
                                        {d.value}
                                    </Typography>
                                </div>
                            </ListItem>
                                <Divider variant="fullWidth" component="li"/>
                            </React.Fragment>
                        )}
                    </List>
                </Grid>
                <Grid item md={4} xs={12}>
                    <List>
                        {data?.slice(16, 30).map(d => <React.Fragment key={d.name}><ListItem key={d.name}>
                                <div className="corner-aligned" style={{width: '100%'}}>
                                    <Typography style={{fontSize: '14px'}} varaint={"subtitle1"} className="capitalize">
                                        {d.name?.replaceAll("_", " ")}
                                    </Typography>
                                    <Typography variant={"subtitle2"} className="capitalize">
                                        {d.value}
                                    </Typography>
                                </div>
                            </ListItem>
                                <Divider variant="fullWidth" component="li"/>
                            </React.Fragment>
                        )}
                    </List>
                </Grid>
                <Grid item md={4} xs={12}>
                    <Button fullWidth variant={"text"} color="primary" onClick={() => downloadReport()}>
                        Download Batch Report
                    </Button>
                </Grid>
                <Grid item md={4} xs={12}>
                    <Button fullWidth variant={"text"} color="primary"
                            onClick={() => downloadReportByType("DAILY_REPORT")}>
                        Download Daily Report
                    </Button>
                </Grid>
                <Grid item md={4} xs={12}>
                    <Button fullWidth variant={"text"} color="primary"
                            onClick={() => downloadReportByType("DAILY_ACCOUNT_REPORT")}>
                        Download Account Number Report
                    </Button>
                </Grid>
            </Grid>}
            <DateRangeDialog handleDateRange={handleDateRange} open={open} onClose={() => setOpen(false)}/>
        </div>
    );
}

export default RawStats;
