import React, {createContext, useEffect, useState} from "react";
import moment from "moment";
import {timePeriodLevel} from "../../views/Report/TransactionList/filter/statement_filter";

export const TransactionFilterContext = createContext({});
const monthName = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec"
}

const initialFilter = {
    type: 1,
    flag: 0,
    category: "",
    recurring: 0,
    account_type: 1,
    statement: "",
    minAmount: null,
    maxAmount: null,
    search: "",
    account_number:null
};

function TransactionFilterProvider({children, filterData, batchFiles}) {
    const [txnList, setTxnList] = useState([]);
    const [filteredTransaction, setFilteredTransaction] = useState([]);
    const [filters, setFilters] = useState({});

    const [recurringInfo, setRecurringInfo] = useState();

    useEffect(() => {
        resetFilter();
    }, [])

    useEffect(() => {
        let filteredTxn = txnList;

        if (filters?.statement?.length > 0) {
            const statements = filters?.statement?.split(",");
            filteredTxn = filteredTxn.filter(txn => statements?.includes(`${monthName[txn['month']]}-${txn['year']}`))
        }

        if (filters?.account_type !== 0) {
            if (filters?.account_type === 1) {
                filteredTxn = filteredTxn.filter(txn => txn['account_type'] === 'CHECKING');
            } else if (filters?.account_type === 2) {
                filteredTxn = filteredTxn.filter(txn => txn['account_type'] === 'SAVINGS');
            }
        }

        if (filters?.type !== 0) {
            if (filters.type === 1) {
                filteredTxn = filteredTxn.filter(txn => txn['amount'] >= 0);
            } else if (filters.type === 2) {
                filteredTxn = filteredTxn.filter(txn => txn['amount'] < 0);
            }
        }

        if (filters?.minAmount > 0) {
            filteredTxn = filteredTxn.filter(txn => txn['amount'] >= filters.minAmount);
        }

        if (filters?.maxAmount > 0) {
            filteredTxn = filteredTxn.filter(txn => txn['amount'] <= filters.maxAmount);
        }

        if (filters?.flag !== 0) {
            if (filters.flag === 1) {
                filteredTxn = filteredTxn.filter(txn => txn['flag']);
            } else if (filters.flag === 2) {
                filteredTxn = filteredTxn.filter(txn => !txn['flag']);
            } else if (filters.flag === 4) {
                filteredTxn = filteredTxn.filter(txn => txn['other_flag']?.length > 1);
            } else if (filters.flag === 3) {
                filteredTxn = filteredTxn.filter(txn => txn['atypical']?.length > 1);
            }
        }

        if (filters?.category?.length > 0) {
            const catList = filters?.category?.split(",");
            filteredTxn = filteredTxn.filter(txn => catList?.includes(txn['category']))
        }

        if (filters?.search?.length > 0) {
            const searchText = filters?.search?.toLowerCase();
            filteredTxn = filteredTxn.filter(txn => {
                const desc = txn['description'].toLowerCase();
                const short_desc = txn['category'].toLowerCase();
                if (desc?.indexOf(searchText) > -1 || short_desc?.indexOf(searchText) > -1) {
                    return true
                }
            })
        }

        if (filters?.account_number && filters?.account_number !== 'All') {
            filteredTxn = filteredTxn.filter(txn => txn['account_number']?.toString() === filters?.account_number?.toString())
        }

        if (filters?.recurring !== 0) {
            if (filters?.recurring === 1) {
                const lookup = filteredTxn.reduce((txn, e) => {
                    txn[e.amount] = ++txn[e.amount] || 0;
                    return txn;
                }, {});
                filteredTxn = filteredTxn.filter(txn => lookup[txn.amount])
            } else if (filters.recurring === 2) {
                const lookup = filteredTxn.reduce((txn, e) => {
                    txn[e.amount] = ++txn[e.amount] || 0;
                    return txn;
                }, {});
                setRecurringInfo(null);
                filteredTxn = filteredTxn.filter(txn => !lookup[txn.amount])
            } else {
                setRecurringInfo(null);
            }
        }

        setFilteredTransaction(filteredTxn);

    }, [txnList, filters]);


    const resetFilter = () => {
        let defaultFilter = initialFilter;
        if (filterData?.flag_ac_type) {
            defaultFilter.account_type = (filterData?.flag_ac_type === "SAVINGS" || filterData?.flag_ac_type === "savings") ? 1 : ((filterData.flag_ac_type === 'CHECKING' || filterData.flag_ac_type === 'checking') ? 2 : 0);
        }

        if (filterData?.considered_income_time && filterData?.considered_income_time !== "overall") {
            const incomeTime = filterData?.considered_income_time;
            const periodStatements = batchFiles.slice(timePeriodLevel[incomeTime]['min'], timePeriodLevel[incomeTime]['max'])
            const statementSelections = periodStatements.map(f => moment(f?.statement_date, "MM-DD-YYYY").format("MMM-YYYY")) || []
            defaultFilter.statement = statementSelections.join(",");
        }
        setFilters(defaultFilter);
    }

    const clearFilter = () => {
        const clear = {
            type: 0,
            flag: 0,
            category: "",
            recurring: 0,
            account_type: 0,
            statement: "",
            minAmount: null,
            maxAmount: null,
            search: "",
            account_number: "All"
        };
        setFilters(clear);
    }


    let txnData = {
        filteredTransaction,
        setTxnList,
        filters,
        setFilters,
        recurringInfo,
        txnList,
        resetFilter,
        clearFilter
    };
    return (
        <TransactionFilterContext.Provider value={txnData}>
            {children}
        </TransactionFilterContext.Provider>
    );
}

export default TransactionFilterProvider;
