import React, {useEffect, useState} from 'react';
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import Stepper from "@material-ui/core/Stepper";
import scrollToTargetAdjusted from "../../../lib/utils/scroll";

function SectionNavbar(props) {
    const {batchId, refresh, sections} = props;
    const [currentActiveSection, setCurrentActiveSection] = useState('batch-detail-and-files');

    const handleStepClick = (label, idx) => {
        scrollToTargetAdjusted(label);
    }

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                setCurrentActiveSection(entry.target.id);
            }
        }, {
            root: null,
            threshold: 0.1
        });
        sections.map((sec) => {
            const el = document.querySelector(`#${sec}`);
            observer.observe(el);
        })
        return () => {
            sections.map((sec) => {
                const el = document.querySelector(`#${sec}`);
                observer.unobserve(el);
            })
        };
    }, [batchId, refresh]);

    const activeSection = sections.indexOf(currentActiveSection);

    return (
        <Stepper nonLinear activeStep={activeSection} style={{width: '100%'}}>
            {sections.map((label, index) => (
                <Step key={label}>
                    <StepButton onClick={() => handleStepClick(label, index)}
                                style={{textTransform: 'capitalize'}}>
                        {label?.replace(/-/g, ' ')}
                    </StepButton>
                </Step>
            ))}
        </Stepper>
    )
}

export default SectionNavbar;