import request from "./common/api_client";

function reportIssue(formData) {
    return request({
        url: `/ticket`,
        method: 'POST',
        data: formData
    })
}

const IssueClient = {
    reportIssue
}

export default IssueClient;
