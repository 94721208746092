import React, {useContext, useEffect, useState} from 'react';
import {Drawer} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AdjustIcon from "@material-ui/icons/Adjust";
import moment from "moment";
import AnalyticsClient from "../../../lib/services/api/analytics";
import ActivityDetail from "./activity_detail";
import Toolbar from "@material-ui/core/Toolbar";
import BackIcon from "@material-ui/icons/ArrowBackIos";
import LoadingIndicator from "../../../components/universal/loading_indicator";
import {ToastMessageContext} from "../../../lib/contexts/message_context";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles(theme => ({
    timelineWrapper: {
        width: '35vw',
        overflowY: 'scroll',
        overflowX: 'unset'
    },
    dateHeader: {
        fontSize: '14px',
        display: 'flex',
        alignItems: 'center'
    },
    title: {
        fontSize: '0.7em'
    },
    sectionTitle: {
        paddingLeft: '20px',
        paddingTop: '8px'
    }
}))

function ActivityLog(props) {
    const {batchId, open, handleClose, transactions} = props;
    const classes = useStyles();
    const [activityStream, setActivityStream] = useState([]);
    const [loading, setLoading] = useState(false);

    const Toast = useContext(ToastMessageContext);

    useEffect(() => {
        if (open) {
            setLoading(true);
            AnalyticsClient.getActivityStream(batchId).then((res) => {
                if (res.success) {
                    setActivityStream(res?.data || []);
                }
            }).catch((e) => {
                Toast.showError("Something went wrong. Try again");
                handleClose();
            }).finally(() => {
                setLoading(false);
            })
        }
    }, [open])

    return (
        <Drawer anchor={'right'} open={open} onClose={() => handleClose()}>
            <div className={`${classes.timelineWrapper} scroll`}>
                <Toolbar style={{position: 'absolute', top: '0px', width: '100%', background: '#fff'}}>
                    <div style={{textAlign: 'left', background: '#fff', display: 'flex', alignItems: 'center'}}>
                        <IconButton disableFocusRipple disableRipple onClick={() => handleClose()}>
                            <BackIcon color={"primary"}/>
                        </IconButton>
                        <Typography className={classes.sectionTitle} variant={"h6"} component="div" gutterBottom>Activity
                            Stream</Typography>
                    </div>
                </Toolbar>
                <div style={{padding: '75px 25px'}}>
                    {!loading && activityStream?.map((activity, i) => {
                        return (
                            <div key={i}>
                                <Typography variant={"subtitle2"} className={classes.dateHeader}>
                                    <AdjustIcon size={"small"} htmlColor={"green"}/>
                                    &nbsp;&nbsp;{moment(activity?.updated_at).format("MMM DD, YYYY HH:mm A")}
                                </Typography>
                                <ActivityDetail activityDetail={activity} transactions={transactions} batchId={batchId}/>
                            </div>
                        )
                    })
                    }
                    {!loading && activityStream?.length < 1 &&
                    <Typography style={{marginTop: "25px"}} variant={"subtitle1"} align="center">
                        You haven't made any changes
                    </Typography>}
                    {loading && (<div style={{height: '75vh'}}>
                        <LoadingIndicator variant={"contained"}/>
                    </div>)}
                </div>
            </div>
        </Drawer>

    );
}

export default ActivityLog;