import React, {createContext, useContext, useEffect, useState} from "react";
import {BatchContext} from "./batch_context";

export const RefreshContext = createContext({});

export default function RefreshStateProvider({children}) {
    const [doRefresh, setDoRefresh] = useState(false);
    const Batch = useContext(BatchContext);
    const {action} = Batch.data;

    useEffect(() => {
        if(action === 'create'){
            setDoRefresh(!doRefresh);
        }
    }, [action])

    const refreshData = (flag) => {
        setDoRefresh(flag);
    }

    let refreshStateValue = {refresh: doRefresh, refreshData};
    return (
        <RefreshContext.Provider value={refreshStateValue}>
            {children}
        </RefreshContext.Provider>
    );
}
