import React from 'react';
import MainTheme from "./theme/main_theme";
import {BrowserRouter as Router} from 'react-router-dom';
import BrowserAppRoutes from "./views/Routes";
import {ThemeProvider} from '@material-ui/styles';
import ToastMessageProvider from "./lib/contexts/message_context";
import EventsProvider from "./lib/contexts/events_context";
import {CustomPreloader} from "react-preloaders";
import './App.css';
import LinearProgress from "@material-ui/core/LinearProgress";

function App() {
    return (
        <Router onUpdate={() => document.getElementById('main_content').scrollTo(10, 10)}>
            <CustomPreloader background={"#fff"}>
                <div className={"centered-logo"}>
                    <img src={'/prudent_logo.svg'} alt={"ZenOCR"}/>
                    <LinearProgress style={{width: '250px', marginTop: '25px'}} color={"primary"}/>
                </div>
            </CustomPreloader>
            <ThemeProvider theme={MainTheme}>
                <ToastMessageProvider>
                    <EventsProvider>
                        <BrowserAppRoutes/>
                    </EventsProvider>
                </ToastMessageProvider>
            </ThemeProvider>
        </Router>
    );
}

export default App;
