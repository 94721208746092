import BatchClient from "../lib/services/api/batches";

export const BATCH_ACTIONS = {
    TOGGLE_LOADING_STATE: 'toggle_loading_state',
    SET_ERRORS: 'set_error',
    SET_LIST: 'set_list_data',
    SET_PAGE_SIZE: 'set_no_of_result_per_page',
    SET_PAGE: 'set_page_to_get_data',
    SET_SEARCH: 'set_search_text',
    CREATE_BATCH: 'create_batch',
    DELETE_BATCH: 'delete_batch',
    UPDATE_BATCH: 'update_batch',
    SET_CURRENT_SELECTION: 'current_selection',
    CLEAR_CURRENT_SELECTION: 'clear_current_selection',
    CURRENT_ACTION: 'current_action',
    TOGGLE_PROGRESS: 'toggle_progress_loading_state'
}

function createBatch(dispatch, formData, Toast, Event) {
    dispatch({type: BATCH_ACTIONS.TOGGLE_LOADING_STATE, payload: true});
    Event.recordEvent("Batch", "Create", "Create Batch", 0);
    BatchClient.createBatch(formData).then((res) => {
        if (res?.data?.id) {
            dispatch({type: BATCH_ACTIONS.CREATE_BATCH, payload: res.data});
        }
    }).catch((err) => {
        dispatch({type: BATCH_ACTIONS.TOGGLE_LOADING_STATE, payload: false});
        Toast.showError(`Error creating Batch - ${err?.message || "Try again"}`);
    })
}

function processBatch(dispatch, batchId, Toast, Event) {
    dispatch({type: BATCH_ACTIONS.TOGGLE_LOADING_STATE, payload: true});
    Event.recordEvent("Batch", "Process", "Process Batch", 0);
    const data = {
        id: batchId
    }
    BatchClient.processBatch(data).then((res) => {
        dispatch({type: BATCH_ACTIONS.CURRENT_ACTION, payload: 'processed'});
        Toast.showSuccess("Batch created and queued for processing");
    }).catch((err) => {
        Toast.showError(`${err.message ? err.message : "Something went wrong. Try again"}`);
    }).finally(() => {
        dispatch({type: BATCH_ACTIONS.TOGGLE_LOADING_STATE, payload: false});
    });
}

function getBatchList(dispatch, params) {
    dispatch({type: BATCH_ACTIONS.TOGGLE_LOADING_STATE, payload: true});
    BatchClient.getBatchList(params).then((res) => {
        dispatch({type: BATCH_ACTIONS.SET_LIST, payload: res.data});
    }).catch((e) => {
            dispatch({type: BATCH_ACTIONS.TOGGLE_LOADING_STATE, payload: false});
        }
    ).finally(() => {
        dispatch({type: BATCH_ACTIONS.TOGGLE_LOADING_STATE, payload: false});
    })
}

function setPage(dispatch, page = 1) {
    dispatch({type: BATCH_ACTIONS.SET_PAGE, payload: page});
}

function setPageSize(dispatch, pageSize = 5) {
    dispatch({type: BATCH_ACTIONS.SET_PAGE_SIZE, payload: pageSize});
}

function setSearch(dispatch, pageSize = 5) {
    dispatch({type: BATCH_ACTIONS.SET_SEARCH, payload: pageSize});
}

function deleteBatch(dispatch, batchId, Toast, Event) {
    Event.recordEvent("Batch", "Delete", "Delete Batch", batchId);
    BatchClient.deleteBatch(batchId).then((res) => {
        dispatch({type: BATCH_ACTIONS.DELETE_BATCH, payload: {id: batchId}});
        Toast && Toast.showSuccess("Batch removed successfully");
    }).catch((res) => {
        Toast && Toast.showError("Something went wrong while removing batch");
    })
}


export const BatchAction = {
    getBatchList, setPage, setPageSize, createBatch, processBatch, deleteBatch, setSearch
}

export default BatchAction;
