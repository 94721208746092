import React, {useEffect, useRef, useState} from 'react';
import SearchIcon from '@material-ui/icons/Search';
import {makeStyles} from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import useDebounce from "../../lib/utils/debounce";
import BatchClient from "../../lib/services/api/batches";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import LoadingIndicator from "./loading_indicator";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme) => ({
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: 'rgba(255,255,255,0.85)',
        '&:hover, &:active, &:focus, &:focus-within': {
            backgroundColor: 'rgba(255,255,255)',
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        minWidth: '300px',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        }
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '30ch',
        },
    }
}));

function SearchBatch(props) {
    const classes = useStyles();
    const [searchText, setSearchText] = useState("");
    const userSearchText = useDebounce(searchText, 1500);
    const [searchResult, setSearchResult] = useState([]);
    const [loading, setLoading] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);
    const id = open ? 'search-results-list' : undefined;
    const inputRef = useRef(null);

    // useKeyboardShortcut(
    //     ['Control', 'S'],
    //     () => handleSearchShortcut(), {overrideSystem: true}
    // )

    const handleSearchShortcut = () => {
        inputRef.current.focus();
        setSearchText('');
    }

    useEffect(() => {
        if (userSearchText?.length > 0 || Number.isInteger(userSearchText)) {
            setLoading(true);
            const params = {
                page: 1,
                page_size: 5,
                batch_name: userSearchText
            }
            BatchClient.getBatchList(params).then((res) => {
                if (res.success) {
                    setSearchResult(res.data?.batch);
                }
            }).catch((e) => {
                setSearchResult([]);
            }).finally(() => {
                setLoading(false);
            })
        } else {
            setSearchResult([]);
            setAnchorEl(null);
        }
    }, [userSearchText])

    const handleSearch = (e) => {
        setSearchText(e.target.value);
        setAnchorEl(e.currentTarget);
    }
    return (
        <div>
            <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                <div className={classes.search}>
                    <div className={classes.searchIcon}>
                        <SearchIcon htmlColor={"#464646"}/>
                    </div>
                    <InputBase
                        placeholder="Search batch by name or id"
                        classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                        }}
                        value={searchText}
                        inputRef={inputRef}
                        onChange={(e) => handleSearch(e)}
                        inputProps={{'aria-label': 'search'}}
                    />
                    <Popper id={id} open={open} anchorEl={anchorEl} transition
                            placement="bottom"
                            style={{zIndex: '5'}}
                    >
                        <Paper style={{
                            padding: '10px',
                            marginTop: '5px',
                            width: '450px',
                            zIndex: '9999',
                            backgroundColor: "rgb(226,238,255)"
                        }}>
                            <div style={{backgroundColor: "#fff"}}>
                                {searchResult?.length > 1 &&
                                <Typography variant="subtitle2" style={{padding: '10px 10px 0 10px'}} gutterBottom>
                                    Top Results matching your search
                                </Typography>}
                                <List>
                                    {searchResult.map(res => <React.Fragment key={res.id}>
                                        <ListItem
                                            key={res.id} button

                                            onClick={() => {
                                                props.history.push(`/user/batches/${res.id}`)
                                            }}
                                        >
                                            <ListItemText>
                                                <Typography variant={"body2"}>{res.name}</Typography>
                                                <Typography variant={"body1"} color="textSecondary">Bank
                                                    Name: {res?.expanded_bank_name || res.bank_name || "NA"}
                                                </Typography>
                                                <Typography variant={"body1"} color={'textSecondary'}>Batch
                                                    ID:# {res.id}</Typography>
                                            </ListItemText>
                                        </ListItem>
                                        <Divider variant="middle" light={true}/>
                                    </React.Fragment>)
                                    }
                                </List>
                                {((searchResult?.length < 1) || (loading)) && (<div style={{
                                    height: '100px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    {searchResult?.length < 1 && !loading &&
                                    <Typography variant="subtitle2">No Batches Found</Typography>}
                                    {loading && <div>
                                        <LoadingIndicator variant={"contained"}/>
                                        <Typography variant={"body1"}>Searching for results...</Typography>
                                    </div>
                                    }
                                </div>)
                                }
                            </div>
                        </Paper>
                    </Popper>
                </div>
            </ClickAwayListener>
        </div>
    )
}

export default SearchBatch;