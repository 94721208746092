import {AUTH_ACTIONS} from '../actions/auth_actions';
import CreateReducer from '../lib/utils/create_reducer';

export const INITIAL_STATE = {
    loading:false,
    error:{},
    mask:true,
    message:{}
};

const authReducer = CreateReducer(INITIAL_STATE, {
    [AUTH_ACTIONS.AUTHENTICATED](state, action) {
        return {
            ...state,
            loading: false,
        };
    },
    [AUTH_ACTIONS.AUTHENTICATION_ERROR](state, action) {
        return {
            ...state,
            error: {...state.error,message:action.response},
            loading:false
        };
    },
    [AUTH_ACTIONS.TOGGLE_LOADING_STATE](state,action) {
        return {
            ...state,
            loading: !state.loading
        }
    },
    [AUTH_ACTIONS.PASSWORD_CHANGE_SUCCESS](state,action) {
        return {
            ...state,
            loading:false,
            message:{...state.message,message:action.response,success:true}
        }
    },
    [AUTH_ACTIONS.PASSWORD_CHANGE_ERROR](state,action) {
        return {
            ...state,
            loading:false,
            message:{...state.message,message:action.response,success:false}
        }
    },
    [AUTH_ACTIONS.SET_ERRORS](state,action){
        return {
            ...state,
            loading: false,
            error: {...state.error,...action.error},
        }
    },
    [AUTH_ACTIONS.TOGGLE_MASK](state,action){
        return {
            ...state,
            loading: false,
            mask: !state.mask
        }
    }
});

export default authReducer;