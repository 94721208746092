import React from "react";
import BugIcon from "@material-ui/icons/BugReport";
import Button from "@material-ui/core/Button";

function ReportBugButton(props){

    return (
        <Button className={"error-outline-btn"} onClick={props.openDialog} startIcon={<BugIcon/>}>
            Report an Issue
        </Button>
    )
}

export default ReportBugButton;
