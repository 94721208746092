import React from "react";
import Chart from "react-apexcharts";
import {getCurrencyFormat} from "../../../lib/utils/common_utils";
import LoadingIndicator from "../../../components/universal/loading_indicator";

function MonthlyDepositsChart(props) {
    const {selectedAccount, data, loading} = props;
    const selectedAcc = selectedAccount === "All" ? "overall" : (selectedAccount || "overall");

    const chartConfig = {
        series: [
            {
                name: 'Deposits',
                data: data?.[selectedAcc]?.deposits || []
            }, {
                name: 'Withdrawals',
                data: data?.[selectedAcc]?.normalised_withdrawals || []
            }
        ],
        options: {
            title: {
                text: props?.title
            },
            chart: {
                type: 'bar',
                height: 450,
                toolbar:{
                    export: {
                        csv: {
                            headerCategory: 'Statement Period'
                        }
                    }
                }
            },
            plotOptions: {
                bar: {
                    columnWidth: '60%',
                    endingShape: 'rounded'
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },
            xaxis: {
                categories: data?.[selectedAcc]?.label || [],
                type: 'category'
            },
            yaxis: {
                title: {
                    text: 'Amount'
                },
                labels: {
                    formatter: (value) => {
                        return getCurrencyFormat(value, 0)
                    },
                },
                type: 'number'
            },
            tooltip: {
                shared: true,
                intersect:false,
                y: {
                    formatter: function (val) {
                        return getCurrencyFormat(val, 0)
                    }
                }
            },
            fill: {
                opacity: 1
            },
            noData: {
                text: 'Loading...'
            },
            colors: ["#0dbd0d", "#F53E64"]
        },
    };

    return (
        <div>
            {!loading && data && <Chart
                options={chartConfig.options}
                series={chartConfig.series}
                type="bar"
                width="100%"
                height="400px"
            />}
            {loading && <div style={{height: "420px", width: '100%'}}>
                <LoadingIndicator variant={"contained"}/>
            </div>}
        </div>
    )
}

export default MonthlyDepositsChart;