const BASE_APP_URL = process.env.REACT_APP_BASE_URL;
export const msalConfig = {
    auth: {
        clientId: '8582d160-d2a3-4ffb-9104-5846ed8c00b6',
        authority: 'https://login.microsoftonline.com/organizations',
        redirectUri: `${BASE_APP_URL}/auth/msal/callback`,
    },
    cache: {
        cacheLocation: 'sessionStorage'
    },
};

export const loginRequest = {
    scopes: ["User.Read"]
};