import React, {useContext, useEffect, useState} from "react";
import Drawer from "@material-ui/core/Drawer";
import Typography from "@material-ui/core/Typography";
import {ToastMessageContext} from "../../../lib/contexts/message_context";
import BatchFilesClient from "../../../lib/services/api/files";
import {makeStyles} from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import BackIcon from "@material-ui/icons/ArrowBackIos";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

const useStyles = makeStyles(theme => ({
    wrapper: {
        overflowY: 'scroll',
        overflowX: 'hidden',
        width: '50vw'
    },
    noOverflow: {
        overflow: 'hidden'
    },
    listWrapper: {
        width: '50vw',
        overflow: 'hidden'
    },
    title: {
        fontSize: '18px'
    },
    sectionTitle: {
        paddingLeft: '20px',
        paddingTop: '8px'
    }
}))

function AccountClassContent(props) {
    const {accountMapping, handleAccountClassChange, tenant} = props;
    return (
        <>
            <div style={{padding: '24px'}}>
                <Grid container alignItems={"center"} justify={"center"}>
                    <Grid item xs={5}>
                        <Typography variant={"subtitle2"} gutterBottom>
                            Account Number & Type
                        </Typography>
                    </Grid>
                    <Grid item xs={7}>
                        <Typography variant={"subtitle2"} gutterBottom>
                            Account Class
                        </Typography>
                    </Grid>
                    {accountMapping?.map((acc, i) => (
                        <React.Fragment
                            key={`${acc?.account_number}-${acc?.account_class}`}>
                            <Grid item xs={5}>
                                <Typography variant={"subtitle2"}>
                                    {`${acc?.captured_account_number ? acc?.captured_account_number : acc?.account_number} (${acc?.account_type})`}
                                </Typography>
                            </Grid>
                            <Grid item xs={7}>
                                <RadioGroup defaultValue={acc?.account_class} row>
                                    <FormControlLabel primary value={"Business"} control={<Radio primary/>}
                                                      label="Business"
                                                      onChange={(e) => handleAccountClassChange(i, e.target.value)}
                                    />
                                    <FormControlLabel
                                        defaultChecked={acc?.account_class === "Personal"} primary
                                        value={"Personal"} control={<Radio primary/>} label="Personal"
                                        onChange={(e) => handleAccountClassChange(i, e.target.value)}
                                    />
                                    <FormControlLabel
                                        defaultChecked={acc?.account_class === "Exclude"} primary
                                        value={"Exclude"} control={<Radio primary/>} label="Exclude"
                                        onChange={(e) => handleAccountClassChange(i, e.target.value)}
                                    />
                                </RadioGroup>
                            </Grid>
                        </React.Fragment>
                    ))}
                </Grid>
            </div>
            <div style={{padding: '8px 24px'}}>
                {!["ANGELOAK"].includes(tenant) && <Typography variant={"subtitle1"}>
                    Updating the Account Class to "Personal" will apply a 0% expense factor for that account
                    (Personal accounts are those that do not include business expenses)
                </Typography>}
            </div>
        </>
    )
}

function AccountClassAsDialog(props) {
    const {setShowMapping, accountMapping, handleAccountClassChange, saveAccountClassChanges, updating, tenant} = props;
    return (
        <Dialog
            maxWidth="md"
            fullWidth
            open={true}
            onClose={() => setShowMapping(false)}
        >
            <DialogContent style={{padding: "24px 48px"}}>
                <Typography variant={"subtitle2"} style={{fontSize: "16px"}} gutterBottom>
                    Account Class Mapping
                </Typography>
                <div>
                    <Typography variant={"body1"} style={{padding: "12px 0px"}}>
                        Please tag the bank accounts as Business or Personal (you can also update them and rerun later)
                    </Typography>
                </div>
                <AccountClassContent
                    accountMapping={accountMapping}
                    handleAccountClassChange={handleAccountClassChange}
                    tenant={tenant}
                />
            </DialogContent>
            <DialogActions className={"center"} style={{padding: "24px"}}>
                <Button disabled={updating} startIcon={updating && <CircularProgress size={15}/>} size={"small"}
                        variant={"contained"} color="primary" onClick={saveAccountClassChanges}>
                    Confirm Mapping
                </Button>
            </DialogActions>
        </Dialog>
    )
}

function AccountClassAsDrawer(props) {
    const {setShowMapping, accountMapping, handleAccountClassChange, saveAccountClassChanges, updating, tenant} = props;
    const classes = useStyles();
    return (
        <Drawer className={classes.noOverflow} anchor={'right'} open={true}>
            <div className={`${classes.wrapper}`}>
                <Toolbar style={{
                    position: 'absolute',
                    top: '0px',
                    width: '50vw',
                    background: '#fff',
                    zIndex: 375
                }}>
                    <div style={{textAlign: 'left', background: '#fff', display: 'flex', alignItems: 'center'}}>
                        <IconButton disableFocusRipple disableRipple onClick={() => setShowMapping(false)}>
                            <BackIcon color={"primary"}/>
                        </IconButton>
                        <Typography className={classes.sectionTitle} variant={"h6"} component="div" gutterBottom>
                            Account Class Mapping
                        </Typography>
                    </div>
                </Toolbar>
                <div style={{height: '65px'}}/>
                <AccountClassContent
                    accountMapping={accountMapping}
                    handleAccountClassChange={handleAccountClassChange}
                    tenant={tenant}
                />
                <div style={{textAlign: "right", marginRight: '42px'}}>
                    <Button disabled={updating} startIcon={updating && <CircularProgress size={15}/>} size={"small"}
                            variant={"contained"} color="primary" onClick={saveAccountClassChanges}>
                        Update Mapping
                    </Button>
                </div>
            </div>
        </Drawer>
    )
}

function AccountClassMapping(props) {
    const {accountNumberInfo, batchId, setRefresh, showMapping, setShowMapping, popup} = props;
    const [accountMapping, setAccountMapping] = useState([]);
    const [updating, setUpdating] = useState(false);
    const accountNumbers = accountNumberInfo?.FULL;
    const tenant = JSON.parse(sessionStorage.getItem('user'))?.['tenant_name']?.toUpperCase();

    useEffect(() => {
        if (accountNumbers?.length > 0) {
            let accountMapping = [];
            for (let i = 0; i < accountNumbers?.length; i++) {
                const accM = {
                    "account_type": accountNumbers[i]?.account_type,
                    "account_number": accountNumbers[i]?.account_number,
                    "account_class": accountNumbers[i]?.account_class,
                    "captured_account_number": accountNumbers[i]?.captured_account_number ? accountNumbers[i]?.captured_account_number: ''
                }
                accountMapping.push(accM);
            }
            setAccountMapping(accountMapping);
        }
    }, []);

    const Toast = useContext(ToastMessageContext);

    const handleAccountClassChange = (idx, accountClass) => {
        let accountInfo = accountMapping;
        accountInfo[idx]['account_class'] = accountClass;
        setAccountMapping(accountInfo);
    }

    const saveAccountClassChanges = () => {
        setUpdating(true);
        BatchFilesClient.updateAccountClassMapping(batchId, {
            "data": accountMapping
        }).then((res) => {
            if (res.success) {
                setRefresh();
                Toast.showSuccess("Account classes updated successfully");
                setShowMapping(false);
            }
        }).catch((e) => {
            Toast.showError(e?.message || "Something went wrong while updating file status.");
        }).finally(() => {
            setUpdating(false);
        })
    }

    return (<>
            {popup ?
                <AccountClassAsDialog
                    setShowMapping={setShowMapping}
                    accountMapping={accountMapping}
                    handleAccountClassChange={handleAccountClassChange}
                    saveAccountClassChanges={saveAccountClassChanges}
                    updating={updating}
                    tenant={tenant}
                /> : <AccountClassAsDrawer
                    setShowMapping={setShowMapping}
                    accountMapping={accountMapping}
                    handleAccountClassChange={handleAccountClassChange}
                    saveAccountClassChanges={saveAccountClassChanges}
                    updating={updating}
                    tenant={tenant}
                />
            }
        </>

    );
}

export default AccountClassMapping;