import React, {useContext, useEffect, useState} from 'react';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from "@material-ui/core/Typography";
import {TextField} from "@material-ui/core";
import {TransactionFilterContext} from "../../../../lib/contexts/transaction_filter_context";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";


const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;

function CategoryFilter(props) {
    const {categoryData} = props;
    const [selectedCategories, setSelectedCategories] = useState([]);

    const TxnData = useContext(TransactionFilterContext);

    const handleChange = (value) => {
        setSelectedCategories(typeof value === 'string' ? value.split(',') : value);
        TxnData.setFilters({...TxnData.filters, category: value.join(",")});
    };

    useEffect(() => {
        if (!TxnData.filters.category) {
            setSelectedCategories([]);
        }
    }, [TxnData.filters.category])

    let categories = [];
    if(TxnData?.filters?.type === 1){
        categories = categoryData?.all_categories?.split(',') || [];
    }
    else {
        categories = categoryData?.wd_all_categories?.split(',') || [];
    }


    return (
        <div>
            <Typography variant="subtitle2" color="textSecondary">
                {TxnData?.filters?.type === 1 ? "Income" : "Expense"} Categories:
            </Typography>
            <FormControl fullWidth style={{marginTop: 0}}>
                <Autocomplete
                    fullWidth
                    multiple
                    limitTags={1}
                    options={categories}
                    disableCloseOnSelect
                    size="small"
                    value={selectedCategories}
                    onChange={(event, value) => handleChange(value)}
                    renderOption={(option, {selected}) => (
                        <React.Fragment>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{marginRight: 8}}
                                checked={selected}
                            />
                            {option}
                        </React.Fragment>
                    )}
                    renderInput={(params) => (
                        <TextField {...params} variant="outlined" size="small"/>
                    )}
                />
            </FormControl>
        </div>
    );
}

export default CategoryFilter;
