import React, {useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import Skeleton from "@material-ui/lab/Skeleton";
import {Tooltip} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Edit from "@material-ui/icons/Edit";
import CustomerDetailsEdit from "./customer_details_edit";
import AnalyticsClient from "../../../lib/services/api/analytics";
import {unique} from "../../../lib/utils/common_utils";

const useStyles = makeStyles(theme => ({
    infoCard: {
        borderRadius: '10px',
        boxShadow: 'inset 0 0 2px 0px #f5f5f5, 0 0 2px 1px #f5f5f5',
        height: '175px',
        [theme.breakpoints.down('md')]: {
            minHeight: '250px'
        }
    },
    title: {
        fontSize: '18px',
        marginBottom: '15px'
    }
}));

function BatchInformation(props) {
    const classes = useStyles();
    const [showEdit, setShowEdit] = useState(false);
    const {batchId, detail, loading} = props;
    const [cusLoading, setCusLoading] = useState(false);
    const [customerInfo, setCustomerInfo] = useState([]);

    useEffect(() => {
        if (batchId) {
            setCusLoading(true);
            AnalyticsClient.getAccountWiseCustomerData(batchId).then((res) => {
                if (res.success) {
                    setCustomerInfo(res.data);
                }
            }).finally(() => {
                setCusLoading(false);
            })
        }
    }, [batchId]);

    const customerNames = unique(customerInfo?.map((d, i) => d.customer_name)).map((d, i) => `Name ${i+1}: ${d}`);
    const customerAddress = unique(customerInfo?.map((d, i) => d.customer_address)).map((d, i) => `Address ${i+1}: ${d}`);

    let details = [
        {
            section: "Statement Processor",
            info: [
                {key: "Batch Id", value: batchId},
                {key: "Loan Processed By", value: detail?.batch_data?.created_by},
                {key: "Email Id", value: detail?.batch_data?.email},
                {key: "Processed On", value: moment(detail?.batch_data?.created_on).format("DD MMM, YYYY")}
            ]
        },
        {
            section: "Customer Details",
            info: [
                {
                    key: "Names",
                    value: customerNames?.length > 0 ? customerNames?.join(", ") : detail?.customer_data?.customer_name
                },
                {
                    key: "Addresses",
                    value: customerAddress?.length > 0 ? customerAddress?.join(", ") : detail?.customer_data?.customer_address
                }
            ]
        },
        {
            section: "Bank Details",
            info: [
                {key: "Name", value: detail?.customer_data?.bank_name},
                {key: "Account Type", value: detail?.customer_data?.account_type},
                {
                    key: "Account Numbers",
                    value: detail?.customer_data?.captured_account_numbers || detail?.customer_data?.bank_number
                },
            ]
        },
    ]

    return (
        <>
            <Grid container spacing={2} className={classes.root}>
                <Grid item xs={12}>
                    <Typography variant="h5">Batch Details</Typography>
                </Grid>
                {details?.map((detail, i) => {
                    return (<Grid key={`info-${i}`} item md={4}>
                        <Card elevation={0} className={classes.infoCard}>
                            <CardContent>
                                <div className={"corner-aligned"}>
                                    <Typography variant={"subtitle2"} className={classes.title}>
                                        {detail.section}
                                    </Typography>
                                    {detail.section === "Customer Details" &&
                                        <IconButton color={"primary"} size={"small"} onClick={() => setShowEdit(true)}>
                                            <Edit fontSize={"small"}/>
                                        </IconButton>}
                                </div>

                                <Grid container spacing={0}>
                                    {detail?.info?.map((inf, i) => <React.Fragment key={`${inf.key}-${i}`}>
                                        <Grid item md={5}>
                                            <Typography variant={"subtitle2"} color="textSecondary">
                                                {inf.key}
                                            </Typography>
                                        </Grid>
                                        <Grid item md={7}>
                                            {(loading || cusLoading) ? <Skeleton variant="text"/> : (
                                                <Tooltip
                                                    disableHoverListener={!["Account Numbers", "Addresses"].includes(inf.key)}
                                                    title={inf.value} arrow placement={"left-start"}>
                                                    <Typography
                                                        className={["Account Numbers", "Addresses"].includes(inf.key) ? "clickable" : ""}
                                                        variant={"subtitle1"} color="primary">
                                                        {inf.value}
                                                    </Typography>
                                                </Tooltip>
                                            )}
                                        </Grid>
                                    </React.Fragment>)}
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>)
                })}
            </Grid>
            {showEdit && <CustomerDetailsEdit batchId={batchId}
                                              customerDetails={customerInfo} setCustomerDetail={setCustomerInfo}
                                              setShowEdit={setShowEdit}/>}
        </>
    )
}

export default BatchInformation;
