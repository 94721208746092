import React, {useContext, useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ErrorIcon from "@material-ui/icons/Cancel";
import UserIcon from "@material-ui/icons/ContactMail";
import InfoIcon from "@material-ui/icons/Info";
import StatsClient from "../../lib/services/api/statistics";
import {RefreshContext} from "../../lib/contexts/refresh_context";
import {BatchContext} from "../../lib/contexts/batch_context";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {getCurrencyFormat} from "../../lib/utils/common_utils";

let _mounted = false;

const useStyles = makeStyles(theme => ({
    statCard: {
        padding: '15px',
        border: '1px solid #e6e6e6',
        borderRadius: '15px',
        boxShadow: '0 0 2px 1px #f5f5f5',
        height: '85px',
        [theme.breakpoints.down('md')]: {
            minHeight: '100px'
        }
    },
    fileStatus: {
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    },
    fileStatusText: {
        fontSize: '0.8em',
        display: 'flex',
        alignItems: 'center'
    },
    cardWrap: {
        height: '100%'
    }
}))

function Statistics(props) {

    const classes = useStyles();

    const [statistics, setStatistics] = useState([]);
    const [loading, setLoading] = useState(false);
    const RefreshObserver = useContext(RefreshContext);
    const {refresh} = RefreshObserver;
    const Batch = useContext(BatchContext);

    useEffect(() => {
        const localData = JSON.parse(sessionStorage.getItem('stats') || "{}");
        if (localData?.length > 0) {
            setStatistics(localData);
        } else {
            setStatistics([
                {name: 'Uploaded Statements', count: 0},
                {name: 'Statements in Processing*', count: 0},
                {name: 'Processed successfully', count: 0},
                {name: 'Rejected Statements', count: 0, valid: 0, invalid: 0},
            ])
        }
        _mounted = true;
        const refreshPeriodically = setInterval(() => {
            if (!document.hidden && _mounted) {
                getStatistics();
            }
        }, 300000);
        return (() => {
            clearInterval(refreshPeriodically);
        })
    }, []);

    useEffect(() => {
        getStatistics();
    }, [refresh]);


    const getStatistics = () => {
        setLoading(true);
        let params = {
            updates_after: Date.now(),
            user_id: props.userId
        }
        StatsClient.getOverallStats(params).then(({data}) => {
            const res = data;
            if (res?.hasOwnProperty('total_batch')) {
                const stat = [
                    {
                        name: 'Total Batches',
                        count: res['total_batch'],
                        pages: res['total_pages']
                    },
                    {
                        name: 'Batches in Processing*',
                        count: res['total_processing_batches'],
                    },
                    {
                        name: 'Batches Processed successfully',
                        count: res['total_processed_batches'],
                    },
                    {
                        name: 'Rejected Batches',
                        count: +res['total_failed_batches']
                    },
                ];
                const currentCheckStats = sessionStorage.getItem('full_stats') || "";
                if (currentCheckStats !== JSON.stringify(res)) {
                    sessionStorage.setItem('stats', JSON.stringify(stat));
                    sessionStorage.setItem('full_stats', JSON.stringify(res));
                    props.setRefresh && props.setRefresh(Date.now());
                    setStatistics(stat);
                    if (props?.match?.params?.userId) {
                        const params = {
                            page: 1,
                            page_size: 50,
                            user_id: props?.match?.params?.userId
                        }
                        Batch.getBatchList(params);
                    } else {
                        const params = {
                            page: 1,
                            page_size: 50,
                            status: sessionStorage.getItem('status_filter'),
                            tenant: sessionStorage.getItem('tenant_filter')
                        }
                        Batch.getBatchList(params);
                    }
                }
            }
            setLoading(false);
        }).catch((e) => {
            setLoading(false);
        })
    }

    return (
        <Grid container alignItems={'flex-start'} justify={'space-between'} spacing={4}>
            {statistics && statistics.map((stat, i) =>
                <Grid className={classes.cardWrap} key={`stat-${i}`} xs={6} item md={3}>
                    <div className={classes.statCard}>
                        <Typography component={'div'} variant={'subtitle2'} gutterBottom>{stat.name}</Typography>
                        <div className={"corner-aligned"} style={{height: '85px', marginTop: '-8px'}}>
                            <Typography component={'div'} variant={'subtitle2'} className={'stat-count'}>
                                {getCurrencyFormat(stat.count).replaceAll("$", "")}
                            </Typography>
                            {stat?.pages && (<Typography color="textSecondary" component={"div"} variant="caption"
                                                         className={"caption mt--2 infoText"}>
                                {getCurrencyFormat(stat.pages).replaceAll("$", "")} pages
                            </Typography>)}
                            {(stat.hasOwnProperty('valid') || stat.hasOwnProperty('invalid')) &&
                            <div className={classes.fileStatus}>
                                {stat.na > 0 &&
                                <Typography className={classes.fileStatusText} gutterBottom>
                                    <ErrorIcon className={"negative"}/>
                                    &nbsp; {`${stat.na}`} Not Uploaded
                                </Typography>}
                                {stat.new > 0 &&
                                <Typography className={classes.fileStatusText} gutterBottom>
                                    <InfoIcon className={"warning"}/>
                                    &nbsp; {`${stat.new}`} New bank
                                </Typography>}
                            </div>}
                            {stat.hasOwnProperty('manual') && stat?.manual > 0 &&
                            <div className={classes.fileStatus}>
                                <Typography className={classes.fileStatusText} gutterBottom>
                                    <UserIcon className={i === 2 ? "positive" : "warning"}/>
                                    &nbsp; {`${stat.manual}`} {i === 2 ? "by" : "in"} manual
                                </Typography>
                            </div>
                            }
                        </div>
                    </div>
                </Grid>)}
        </Grid>
    )
}

export default Statistics;
