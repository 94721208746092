import React, {useContext} from "react";
import {TransactionFilterContext} from "../../../../lib/contexts/transaction_filter_context";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";

function RecurringTxnFilter(props) {
    const TxnData = useContext(TransactionFilterContext);

    const filterOptions = ["All", "Recurring", "Non-Recurring"];

    return (
        <div>
            <Typography variant="subtitle2" color="textSecondary" gutterBottom style={{marginBottom: '10px'}}>Transaction
                Frequency: &nbsp;</Typography>
            {filterOptions?.map((op, i) => (
                <Chip
                    variant={TxnData?.filters?.recurring === i ? "default" : "outlined"}
                    key={op}
                    size="small"
                    style={{marginRight: '5px'}}
                    onClick={() => TxnData?.setFilters({...TxnData.filters, recurring: i})}
                    clickable
                    color={TxnData?.filters?.recurring === i ? "primary" : "default"}
                    label={op}
                />
            ))
            }
        < /div>
    );
}

export default RecurringTxnFilter;
