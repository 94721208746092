import React, {useEffect, useState} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography/Typography";

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: 600,
    },
    snackbar: {
        margin: theme.spacing(1),
    },
    notice: {
        position: 'fixed',
        bottom: 5,
        left: 0,
        right: 0,
        '& .MuiAlert-message': {
            width: '100%'
        }
    }
}));

function ImportantNotice() {
    const classes = useStyles();
    const [showInfo, setShowInfo] = useState('Y');

    useEffect(() => {
        const info = localStorage.getItem('holiday_info');
        setShowInfo(info);
    }, []);

    const closeInfo = () => {
        localStorage.setItem('holiday_info', 'N');
        setShowInfo('N');
    }
    return (
        <>
            {showInfo !== 'N' && <div className={classes.notice}>
                <Alert severity="warning">
                    <AlertTitle><Typography variant="subtitle2">Important Update</Typography></AlertTitle>
                    <Grid container>
                        <Grid item md={10}>
                            <Typography variant="body1">
                                We will be on holiday <strong> from 14th April 2022 to 17th April 2022</strong>.
                                <br/>Hence, your manual requests from <strong>13th April 2022</strong>
                                &nbsp;will be processed and delivered on <strong>Monday, 18th April 2022</strong>.<br/>
                                We request you to plan accordingly. - Team ZenOCR
                            </Typography>
                        </Grid>
                        <Grid item md={2}>
                            <Button color="primary" variant="contained" onClick={() => closeInfo()}>Got it,
                                Close </Button>
                        </Grid>
                    </Grid>
                </Alert>
            </div>}
        </>
    )
}

export default ImportantNotice;