import React, {useContext} from "react";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import {TransactionFilterContext} from "../../../../lib/contexts/transaction_filter_context";

function FlagFilter() {
    const TxnData = useContext(TransactionFilterContext);
    const tenant = JSON.parse(sessionStorage.getItem('user'))?.["tenant_name"]?.toUpperCase();
    let filterOptions = ["All", "Excluded", "Income Only", "Atypical Deposit", "Large Deposit", "Comingling", "Reversal"];
    if(TxnData?.filters?.type === 2){
        filterOptions = ["All", "Expense Only", "Non-Expense"];
    }
    return (
        <div style={{marginTop: '10px'}}>
            <Typography variant="subtitle2" color="textSecondary" gutterBottom>Transaction Option: &nbsp;</Typography>
            {filterOptions?.map((op, i) => {
                if (["ANGELOAK"].includes(tenant) && i === 4) {
                    return;
                } else if (!["ANGELOAK"].includes(tenant) && i === 3) {
                    return;
                }
                return (<Chip
                    variant={TxnData?.filters?.flag === i ? "default" : "outlined"}
                    key={op}
                    size="small"
                    style={{marginRight: '5px', marginTop: TxnData?.filters?.type === 2 ? '0px' :'8px'}}
                    onClick={() => TxnData?.setFilters({...TxnData.filters, flag: i})}
                    clickable
                    color={TxnData?.filters?.flag === i ? "primary" : "default"}
                    label={op}
                />)
            })
            }
        < /div>
    );
}

export default FlagFilter;
