import CreateReducer from "../lib/utils/create_reducer";
import {BATCH_FILE_ACTIONS} from "../actions/file_actions";

export const INITIAL_BATCH_FILE_STATE = {
    loading:false,
    errors:null,
    data:[],
    page:1,
    pageSize:10,
    total:0,
    action:undefined,
    currentSelection:{}
}

const batchFileReducer = CreateReducer(INITIAL_BATCH_FILE_STATE, {
    [BATCH_FILE_ACTIONS.TOGGLE_LOADING_STATE](state, action) {
        return {
            ...state,
            loading: action.payload || false,
        };
    },
    [BATCH_FILE_ACTIONS.SET_LIST](state, action) {
        return {
            ...state,
            loading: false,
            data:action.payload||[]
        };
    },
    [BATCH_FILE_ACTIONS.SET_PAGE](state, action){
        return {
            ...state,
            page:action.payload
        };
    },
    [BATCH_FILE_ACTIONS.SET_PAGE_SIZE](state, action){
        return {
            ...state,
            pageSize:action.payload
        }
    },
});

export default batchFileReducer