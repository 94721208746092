import React from "react";
import Grid from "@material-ui/core/Grid";
import BatchProvider from "../../lib/contexts/batch_context";
import {makeStyles} from "@material-ui/core/styles";
import AdminBatchList from "./batch_list";
import Statistics from "../Dashboard/statistics";

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: '105px',
        width: '98%',
        margin: '0 auto',
        paddingBottom: '10%'
    }
}));


function AdminSection(props) {
    const classes = useStyles();
    const user = JSON.parse(sessionStorage.getItem('user'));
    return (
        <div className={classes.root}>
        <Grid container alignItems={"center"} justify={"center"}>
            <Grid item md={12} style={{padding:'0 20px'}}>
                <Statistics {...props} userId={user?.id}/>
            </Grid>
            <Grid item md={12} style={{padding:'20px'}}>
                <BatchProvider>
                    <AdminBatchList {...props}/>
                </BatchProvider>
            </Grid>
        </Grid>
        </div>
    )
}

export default AdminSection;
