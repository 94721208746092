import React, {useCallback, useContext, useState} from 'react';
import Collapse from '@material-ui/core/Collapse';
import Typography from "@material-ui/core/Typography";
import UploadIcon from "@material-ui/icons/CloudUploadOutlined";
import List from "@material-ui/core/List";
import {useDropzone} from "react-dropzone";
import {FileList, useStyles} from "./create_batch";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import {ToastMessageContext} from "../../lib/contexts/message_context";
import BatchClient from "../../lib/services/api/batches";
import BatchFilesClient from "../../lib/services/api/files";
import {EventsContext} from "../../lib/contexts/events_context";
import ProgressDialog from "../../components/universal/progress_dialog";
import Backdrop from "@material-ui/core/Backdrop";
import ConfirmActionDialog from "../../components/universal/confirm_dialog";
import {useHistory} from "react-router-dom";
import WarningIcon from "@material-ui/icons/Warning";

function UpdateBatch(props) {
    const classes = useStyles();
    const {show, existingFiles, batchId, handleCancel} = props;
    const existingFileNamesList = existingFiles?.map((file) => file.name);
    const [files, setFiles] = useState([]);
    const [uploadingFile, setUploadingFile] = useState(false);
    const [error, setError] = useState(false);
    const [uploadCount, setUploadCount] = useState(0);
    const [message, setMessage] = useState();
    const [showConfirm, setShowConfirm] = useState(false);
    const history = useHistory();
    const Event = useContext(EventsContext);
    const Toast = useContext(ToastMessageContext);

    const handleFileUploadCancel = () => {
        setFiles([]);
        setUploadingFile(false);
        setUploadCount(0);
        setShowConfirm(false);
        handleCancel();
    }

    const handleUpdateBatch = () => {
        const fileList = files.map(f => f.path?.split('\\')?.pop()?.split('/')?.pop());
        if (hasDuplicates(fileList)) {
            setError(true);
            Toast.showError('File with the same name found. Kindly remove and retry.');
        } else {
            setShowConfirm(true);
        }
    }

    const handleSubmit = (e) => {
        setShowConfirm(false);
        const fileList = files.map(f => f.path?.split('\\')?.pop()?.split('/')?.pop());

        const formData = {
            batch_id: batchId,
            files: fileList
        }
        BatchClient.updateBatch(formData).then((res) => {
            if (res?.success) {
                setUploadingFile(true);
                uploadFiles(batchId, res?.data).then((res) => {
                });
            }
        }).catch((e) => {
            setUploadingFile(false);
            Toast.showError(e.message);
        })
    }

    const onDrop = useCallback(acceptedFiles => {
        setFiles([...files, ...acceptedFiles])
    }, [files]);

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        accept: 'application/pdf', onDrop
    });

    const removeFile = file => () => {
        Event.recordEvent("Batch", "Remove selected File during update", "Remove File");
        const newFiles = [...files]
        newFiles.splice(newFiles.indexOf(file), 1)
        setFiles(newFiles);
    }

    function hasDuplicates(array) {
        return (new Set(array)).size !== array.length;
    }

    const uploadFiles = async (batchId, batchDetail) => {
        const fileUploadUrls = batchDetail?.files;
        for (let i = 0; i < files.length; i++) {
            const uploadDetails = fileUploadUrls.find(f => f.name === files[i].path?.split('\\')?.pop()?.split('/')?.pop());
            if (uploadDetails) {
                const url = uploadDetails?.uri?.url;
                const formData = new FormData();
                Object.keys(uploadDetails?.uri?.fields).forEach(key => {
                    formData.append(key, uploadDetails?.uri?.fields[key]);
                });
                formData.append("file", files[i]);
                const res = await BatchFilesClient.createBatchFile(url, formData);
                if (!res?.success) {
                    setMessage({type: "error", content: `Error uploading file - ${files[i]['path']}. Try again`})
                }
                setUploadCount(i + 1);
                if (i === files.length - 1) {
                    setUploadingFile(false);
                    setMessage({
                        type: "info",
                        content: "Files uploaded successfully. Placing a request to process the batch"
                    })
                    Toast.showSuccess("Files uploaded successfully. Placing a request to process the batch");
                    BatchClient.processBatch(batchId, {id: batchId, reset_analysis: true}).then((resp) => {
                        if (resp.success) {
                            setMessage({
                                type: "info",
                                content: "Batch successfully queued for processing. You'll be redirected to batch details page while files are being processed"
                            })
                            Toast.showSuccess("Batch successfully Queued for processing");
                            history.replace("/");
                        }
                    }).catch((e) => {
                        setMessage({
                            type: "error",
                            content: Array.isArray(e.message) ? e.message?.join(", ") : "Something went wrong. Try again"
                        });
                    }).finally(() => {
                        setUploadingFile(false);
                        handleCancel();
                        setFiles([]);
                        setUploadCount(0);
                        setUploadingFile(false);
                    })
                }
            }
        }
    }

    const selectedFilesList = files.map(file => (
        <FileList existingFiles={existingFileNamesList} key={file.path} file={file} onClick={removeFile(file)}/>
    ))

    return (
        <div>
            <Collapse in={show}>
                <div style={{padding: '20px 0'}}>
                    <Typography variant="subtitle2" color="textSecondary" gutterBottom style={{marginBottom: '8px'}}>
                        Add Statements to this batch
                    </Typography>
                    <div {...getRootProps({className: 'dropzone'})}>
                        <input {...getInputProps()} />
                        {!isDragActive ? <div>
                            <UploadIcon fontSize={"large"} color={"primary"}/>
                            <Typography color="textSecondary" variant={"subtitle1"}>
                                Drag and drop Statements here to upload, or click to select Statements<br/>
                            </Typography>
                        </div> : <div>
                            <Typography variant={"subtitle2"}>
                                Drop Statements here...
                            </Typography>
                        </div>}
                    </div>
                </div>
                {files && files.length > 0 && <>
                    <Typography component={'div'} variant={"subtitle2"} gutterBottom>Selected Statements
                        ({files.length})</Typography>
                    <List dense>
                        {selectedFilesList}
                    </List>
                </>}
                <div className={`${classes.actionButtons}`} style={{justifyContent: 'flex-end'}}>
                    <div>
                        <Button onClick={handleFileUploadCancel}>Cancel</Button>
                        <Button disabled={error ||uploadingFile || files?.length < 1} variant={"contained"} color={"primary"}
                                startIcon={uploadingFile && <CircularProgress size={15}/>}
                                onClick={handleUpdateBatch}>
                            {uploadingFile ? "Updating Batch..." : "Update Batch"}
                        </Button>
                    </div>
                </div>
            </Collapse>
            <ProgressDialog message={message} type={message?.content} open={uploadingFile && files.length > 0}
                            onClose={() => null}
                            total={files.length}
                            completed={uploadCount}/>
            <Backdrop invisible={true} style={{zIndex: 99999}} open={uploadingFile}/>
            <ConfirmActionDialog
                open={showConfirm}
                onConfirm={handleSubmit}
                onClose={handleFileUploadCancel}
                content={<div className={"center"} style={{flexDirection: 'column'}}>
                    <div>
                        <WarningIcon htmlColor={"#FFCC00"} fontSize={"large"}/>
                        <Typography variant={"subtitle2"}>WARNING</Typography>
                    </div>
                    <Typography variant={"body1"} style={{marginRight: '12px'}}>
                        Updating additional files will need you to review all transaction changes made earlier.
                        Please download the current analysis output before proceeding.<br/>
                    </Typography>
                </div>}
                title={"Confirm batch update with new files"}
            />
        </div>
    )
}

export default UpdateBatch;