import React, {useContext, useEffect, useState} from "react";
import {TransactionFilterContext} from "../../../../lib/contexts/transaction_filter_context";
import useDebounce from "../../../../lib/utils/debounce";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Typography from "@material-ui/core/Typography";
import {TextField} from "@material-ui/core";

function SearchTransaction(props) {
    const [searchText, setSearchText] = useState('');
    const debouncedSearchText = useDebounce(searchText, 800);
    const TxnData = useContext(TransactionFilterContext);

    useEffect(() => {
        TxnData.setFilters({...TxnData.filters, search: debouncedSearchText});
    }, [debouncedSearchText]);

    useEffect(() => {
        if (!TxnData.filters.search) {
            setSearchText('');
        }
    }, [TxnData.filters.search])

    return (
        <div>
            <Typography variant="subtitle2" color="textSecondary">
                Search Transaction:
            </Typography>
            <TextField
                fullWidth
                type="search"
                size="small"
                variant={"outlined"}
                value={searchText}
                placeholder="by description, category"
                onChange={(e) => {
                    setSearchText(e.target.value);
                }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon htmlColor="#d5d5d5"/>
                        </InputAdornment>
                    )
                }}
            />
        </div>
    )
}

export default SearchTransaction;