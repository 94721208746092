import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Typography from "@material-ui/core/Typography/Typography";
import AuthClient from "../../lib/services/api/auth";
import {validateEmail} from "../../lib/utils/common_utils";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MailIcon from "@material-ui/icons/EmailOutlined";
import useAuthStyles from "./auth_styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import {MainBrandingWidget, PoweredByWidget} from "./branding_widgets";


export default function ForgotPassword(props) {
    const classes = useAuthStyles();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(null);
    const [error, setError] = useState({});
    const REDIRECT_URL = process.env.REACT_APP_BASE_URL;

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const email = formData.get('username')?.trim()?.toLowerCase();
        if (!validateEmail(email)) {
            setError({email: "invalid format, provide valid email"});
            return;
        }
        setLoading(true);
        formData.append("email", email)
        formData.append("redirect_url", REDIRECT_URL)
        AuthClient.requestReset(formData).then((res) => {
            setMessage({
                success: true,
                message: <span>{res.data}</span>
            });
        }).catch((e) => {
            setMessage({
                message: <span>You'd receive an email with instruction to reset your password if your username exists in our system.<br/><br/> If you didn't receive email within 5minutes,<br/>Kindly retry with your registered email address!</span>,
                success: false
            });
        }).finally(() => {
            setLoading(false);
        });
    };

    return (
        <div className={classes.wrapper}>
            <div className={classes.formWrapper}>
                {!!!message ? <form className={classes.form} onSubmit={handleSubmit} autoComplete="custom-form">
                    <MainBrandingWidget/>
                    <Typography color={"primary"} align={"center"} component={"h6"} variant={"h6"} gutterBottom>Reset
                        Password</Typography>
                    <Typography className={"desc center"} gutterBottom>Enter registered email id to get password reset
                        link</Typography>
                    <FormControl fullWidth className={classes.formField}>
                        <OutlinedInput
                            required
                            name="username"
                            autoComplete="new-password"
                            placeholder="Email Address"
                            autoFocus
                            error={!!error.email}
                            helperText={error.email ? error.email : null}
                            onFocus={() => {
                                setError(false)
                            }}
                            startAdornment={<InputAdornment position="start">
                                <MailIcon/>
                            </InputAdornment>
                            }
                        />
                    </FormControl>
                    <div style={{margin: '20px 0'}}>
                        <Button
                            type="submit"
                            className={classes.submit}
                            disabled={loading}
                            fullWidth
                            color={"primary"}
                            variant={"contained"}
                            startIcon={loading && <CircularProgress size={15}/>}
                        >
                            Submit
                        </Button>
                        <div style={{margin: '20px 0', textAlign: "center"}}>
                            <Typography color={"textSecondary"} component={"span"} className={"clickable"}
                                        onClick={() => props.history.push('/auth/signin')}>
                                Sign in instead?
                            </Typography>
                        </div>
                    </div>
                </form> : <form className={classes.form}>
                    <MainBrandingWidget/>
                    <Typography color={"primary"} align={"center"} component={"h6"} variant={"h6"} gutterBottom>Reset
                        Password</Typography>
                    <Typography align="center" variant="subtitle1" gutterBottom
                                className={classes.message}>
                        {message.message}
                    </Typography>
                    {!message.success && <Button
                        fullWidth
                        className={classes.submit}
                        onClick={() => setMessage(null)}
                        color={"primary"}
                    >
                        Retry
                    </Button>}
                    {message.message &&
                    <Typography align="center" variant="subtitle2"
                                style={{cursor: 'pointer', margin: '20px 0'}}
                                color={"primary"}
                                onClick={() => props.history.push('/auth/signin')}>
                        Sign in
                    </Typography>
                    }
                </form>}
                <PoweredByWidget/>
            </div>
        </div>
    );
}
