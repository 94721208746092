import request from "./common/api_client";

function login(formData) {
    return request({
        url: '/auth/token',
        method: 'POST',
        data: formData,
        handleHeaders: 1
    });
}

function logout() {
    const formData = new FormData();
    formData.append("client_id", process.env.REACT_APP_CLIENT_ID);
    formData.append("token", sessionStorage.getItem('access_token'));

    return request({
        url: '/auth/revoke_token',
        method: 'POST',
        data: formData,
        handleHeaders: 0,
    });
}

function changePassword(formData) {
    return request({
        url: '/auth/reset-password/',
        method: 'POST',
        data: formData,
    });
}

function requestReset(formData) {
    return request({
        url: '/auth/forgot-password/',
        method: 'POST',
        data: formData,
    });
}

function updatePassword(formData) {
    return request({
        url: '/auth/change-password',
        method: 'POST',
        data: formData
    })
}

function verifyOTP(formData){
    return request({
        url: '/auth/verify-otp',
        method: 'POST',
        data: formData,
        handleHeaders: 1,
    })
}

function exchangeToken(headers) {
    return request({
        url: '/auth/oauth/exchange-token',
        method: 'POST',
        handleHeaders: 1,
        headers: headers
    })
}

const AuthClient = {
    login, logout, changePassword, requestReset, updatePassword, verifyOTP, exchangeToken
};


export default AuthClient;
