import React, {useState} from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import BugIcon from "@material-ui/icons/BugReport";
import TextField from "@material-ui/core/TextField";
import {QuarterWideRightDrawer} from "../../components/universal/halfwide_right_drawer";
import CircularProgress from "@material-ui/core/CircularProgress";
import IssueClient from "../../lib/services/api/bug_report";

function ReportBugDialog(props) {

    const {open, handleClose} = props;
    const [loading,setLoading] = useState(false);
    const [status,setStatus] = useState(null);
    const [error,setError] = useState(null);

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        const formData = new FormData(e.target);
        const user = JSON.parse(sessionStorage.getItem('user'));
        formData.append("email", user.username);

        IssueClient.reportIssue(formData).then((res)=>{
            if(res.success){
                setLoading(false);
                setStatus("completed");
            }
            else {
                setLoading(false);
            }
        }).catch((e)=>{
            setLoading(false);
            setStatus("error");
            setError(e.message);
        });

    }

    return (
        <QuarterWideRightDrawer open={open} elevation={1}>
            <div style={{padding: '3%'}}>
                <div style={{textAlign: 'right', marginBottom: '-30px'}}>
                    <Button size={"small"} onClick={() => handleClose(false)}>Close</Button>
                </div>
                <Typography variant={"subtitle2"} style={{fontSize: '1.2em', paddingLeft: '20px', display:'flex', alignItems: 'center'}}>
                    <BugIcon color={"primary"}/>&nbsp;Report an Issue
                </Typography>
            </div>
            <div style={{padding:'4%'}}>
                {status !== "completed" ?<form onSubmit={handleSubmit}>
                    <TextField
                        fullWidth
                        label="Subject"
                        name={"subject"}
                        required
                        variant="outlined"
                    />
                    <br/>
                    <TextField
                        fullWidth
                        label="Description"
                        multiline
                        rows={10}
                        required
                        rowsMax={20}
                        name={"description"}
                        defaultValue={props.batchDetails}
                        variant="outlined"
                    />
                    <Button
                        fullWidth
                        type="submit"
                        disabled={loading}
                        color={"primary"}
                        variant={"contained"}
                        startIcon={loading && <CircularProgress size={15} />}
                    >
                        Submit
                    </Button>
                    {status === "error" && <div>
                        <span className={"negative"}>{error}</span>
                    </div>}
                </form>:<div>
                    <div className={"center"}>
                        <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                            <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                            <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                        </svg>
                    </div>
                    <div className={"center"}>
                        <Typography className={"center"} variant={"subtitle2"}>Ticket Submitted Successfully</Typography><br/>
                    </div>
                    <div className={"center"} style={{marginTop: "24px"}}>
                        <Button variant={"contained"} color={"primary"} size={"small"} onClick={() => handleClose(false)}>Close</Button>
                    </div>
                </div>}
            </div>
        </QuarterWideRightDrawer>
    )
}

export default ReportBugDialog;
