import React from "react";
import Chart from "react-apexcharts";
import LoadingIndicator from "../../../components/universal/loading_indicator";
import Grid from "@material-ui/core/Grid";
import {getCurrencyFormat} from "../../../lib/utils/common_utils";

function TopCategoriesChart(props) {
    const {selectedAccount, data, loading} = props;
    const selectedAcc = selectedAccount === "All" ? "overall" : (selectedAccount || "overall");

    const depSeries = [
        {
            name: 'Deposits',
            data: data?.[selectedAcc]?.top_deposits || []
        }
    ];

    const withdrawSeries = [
        {
            name: 'Withdrawals',
            data: data?.[selectedAcc]?.top_withdrawals || []
        }
    ];
    const chartConfig = {
        options: {
            legend: {
                show: false
            },
            chart: {
                height: 400,
                width: '100%',
                type: 'treemap'
            },
            title: {
                text: props?.title
            },
            colors: ["#0dbd0d"],
            plotOptions: {
                treemap: {
                    enableShades: true,
                    shadeIntensity: 0.1
                }
            },
            tooltip: {
                shared: true,
                intersect:false,
                y: {
                    formatter: function (val) {
                        return getCurrencyFormat(val, 0)
                    }
                }
            },
            dataLabels: {
                enabled: true,
                style: {
                    fontSize: '11px',
                },
                formatter: function(text, op) {
                    return [text, getCurrencyFormat(op.value,0)]
                },
                offsetY: -4
            },
        },
    };

    return (
        <Grid container style={{marginTop:'64px'}}>
            <Grid item md={6}>
                {!loading && <Chart
                    options={chartConfig.options}
                    series={depSeries}
                    type="treemap"
                    width="100%"
                    height="400px"
                />}
                {loading && <div style={{height: "420px", width: '100%'}}>
                    <LoadingIndicator variant={"contained"}/>
                </div>}
            </Grid>
            <Grid item md={6}>
                {!loading && data && <Chart
                    options={{...chartConfig.options, title:{text:chartConfig.options.title.text.replace("Deposit", "Withdrawals")}, colors:['#F53E64']}}
                    series={withdrawSeries}
                    type="treemap"
                    width="100%"
                    height="400px"
                />}
                {loading && <div style={{height: "420px", width: '100%'}}>
                    <LoadingIndicator variant={"contained"}/>
                </div>}
            </Grid>
        </Grid>
    )
}

export default TopCategoriesChart;