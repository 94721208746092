import React from "react";
import Drawer from "@material-ui/core/Drawer";
import withStyles from "@material-ui/core/styles/withStyles";

const HalfWideRightDrawer = withStyles(theme =>({
    paperAnchorRight: {
        width:'65vw',
        backgroundColor:"#fff",
        [theme.breakpoints.down('md')]:{
            width:'101vw'
        }
    },
}))((props)=> <Drawer transitionDuration={600} elevation={0} anchor={"right"} {...props}>{props.open? props.children:<p style={{backgroundColor:'#fff',height:'100vh'}}/>}</Drawer>)

export const FullWidthRightDrawer = withStyles(theme =>({
    paperAnchorRight: {
        width:'95vw',
        backgroundColor:"#fff",
        [theme.breakpoints.down('md')]:{
            width:'101vw'
        }
    },
}))((props)=> <Drawer transitionDuration={600} elevation={0} anchor={"right"} {...props}>{props.open? props.children:<p style={{backgroundColor:'#fff',height:'100vh'}}/>}</Drawer>)

export const QuarterWideRightDrawer = withStyles(theme =>({
    paperAnchorRight: {
        width:'33vw',
        backgroundColor:"#fff",
        [theme.breakpoints.down('md')]:{
            width:'100vw'
        }
    },
}))((props)=> <Drawer transitionDuration={600} elevation={0} anchor={"right"} {...props}>{props.open? props.children:<p style={{backgroundColor:'#fff',height:'100vh'}}/>}</Drawer>)


export default HalfWideRightDrawer
