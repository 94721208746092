import React, {useCallback, useContext, useEffect, useState} from "react";
import Backdrop from "@material-ui/core/Backdrop";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import {useDropzone} from 'react-dropzone';
import UploadIcon from "@material-ui/icons/CloudUploadOutlined";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Tooltip from "@material-ui/core/Tooltip";
import BatchFilesClient from "../../lib/services/api/files";
import {BatchContext} from "../../lib/contexts/batch_context";
import {EventsContext} from "../../lib/contexts/events_context";
import ProgressDialog from "../../components/universal/progress_dialog";
import {EnabledBanksListDialog} from "../../components/universal/bank_list";
import BatchClient from "../../lib/services/api/batches";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";


export const useStyles = makeStyles(theme => ({
    root: {
        padding: '5%',
        paddingBottom: '10px',
        boxShadow: '0px 0px 4px 0px #d5d5d5',
        borderRadius: '15px'
    },
    list: {
        maxHeight: '200px',
        overflow: 'scroll'
    },
    actionButtons: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: 'auto',
        padding: '20px 0',
        '& div>button': {
            marginRight: '10px',
            minWidth: '100px'
        }
    }
}));

export function FileList(props) {
    const isExistingFile = !!props?.existingFiles?.includes(props.file.path?.split('\\')?.pop()?.split('/')?.pop())
    return <ListItem>
        <ListItemAvatar>
            <img src={"/pdf_icon.png"} alt={""}/>
        </ListItemAvatar>
        <ListItemText
            primary={props.file.path?.split('\\')?.pop()?.split('/')?.pop()}
            secondary={
                isExistingFile && <Typography
                    variant="body2"
                    color="error"
                    style={{fontSize: '0.6em'}}
                >
                    *File with the same name already exist
                </Typography>
            }/>
        <ListItemSecondaryAction>
            <Tooltip title={"Delete File"} arrow placement={"right"}>
                <IconButton edge="end" aria-label="delete" onClick={props.onClick}>
                    <DeleteIcon color={"disabled"}/>
                </IconButton>
            </Tooltip>
        </ListItemSecondaryAction>
    </ListItem>;
}

function CreateBatch(props) {

    const classes = useStyles();
    const [batchName, setBatchName] = useState('');
    const [message, setMessage] = useState();
    const [files, setFiles] = useState([]);
    const [incomePeriod, setIncomePeriod] = useState('MONTHS_24');
    const [error, setError] = useState({
        batchName: '',
        fileCount: ''
    })
    const [fileLimitWarning, setFileLimitWarning] = useState(false);
    const user = JSON.parse(sessionStorage.getItem('user'));
    const tenant = user?.tenant_name?.toUpperCase();
    const [uploadingFile, setFileUploading] = useState(false);
    const [showBankList, setShowBankList] = useState(false);
    const [uploadCount, setUploadCount] = useState(0);
    const [showFileList, setShowFileList] = useState(false);
    const Batch = useContext(BatchContext);
    const {loading, action, currentSelection} = Batch.data;
    const Event = useContext(EventsContext);

    useEffect(() => {
        if (action === 'create' && currentSelection.id) {
            setFileUploading(true);
            uploadFiles(currentSelection.id).then((res) => {
                // setFileUploading(false);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [action, currentSelection])

    const onDrop = useCallback(acceptedFiles => {
        setFiles([...files, ...acceptedFiles]);
    }, [files]);

    useEffect(() => {
        if (files?.length > 0) {
            const totalFiles = files?.length;
            let warning = false;
            if (tenant === 'ANGELOAK') {
                if (incomePeriod === 'MONTHS_12') {
                    if (totalFiles < 12) {
                        warning = true;
                    }
                } else if (incomePeriod === 'MONTHS_24') {
                    if (totalFiles < 24) {
                        warning = true;
                    }
                } else {
                    warning = false;
                }
            }
            setFileLimitWarning(warning)
        }
    }, [files, incomePeriod])

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        accept: 'application/pdf', onDrop
    });

    const removeFile = file => () => {
        Event.recordEvent("Batch", "Remove selected File", "Remove File");
        const newFiles = [...files]
        newFiles.splice(newFiles.indexOf(file), 1)
        setFiles(newFiles);
    }

    const handleCancel = () => {
        setBatchName('');
        setUploadCount(0);
        setError({
            batchName: '',
            fileCount: ''
        });
        setTimeout(() => {
            setFiles([]);
            setFileUploading(false);
            setMessage(null);
        }, 3000)
    }

    function hasDuplicates(array) {
        return (new Set(array)).size !== array.length;
    }

    const validateSubmit = () => {
        let valid = true;
        let foundError = {
            batchName: '',
            fileCount: ''
        };
        if (!batchName || batchName.length < 3) {
            foundError = {batchName: "Batch name should be atleast 3 chars"};
            valid = false;
        }
        if (!files || files.length < 1) {
            foundError = {...foundError, fileCount: "Atleast 1 file is needed per batch to process"};
            valid = false;
        }
        // if (files?.length > 36) {
        //     foundError = {...foundError, fileCount: "Max 36 files can only be added per batch"};
        //     valid = false;
        // }
        setError(foundError);
        return valid;
    }

    const handleSubmit = (e) => {
        if (!validateSubmit()) {
            return;
        }
        const fileList = files.map(f => f.path?.split('\\')?.pop()?.split('/')?.pop());
        if (hasDuplicates(fileList)) {
            setError({
                ...error,
                fileCount: "There seem to be a duplicate file in files selected. Please remove/rename file to proceed"
            });
            return;
        }
        let formData = {
            name: batchName,
            file_count: files.length,
            files: fileList
        }
        if(tenant === 'ANGELOAK') {
            formData['income_period'] = incomePeriod;
        }
        Batch.createBatch(formData);
    }

    const uploadFiles = async (batchId) => {
        const fileUploadUrls = currentSelection?.files;
        for (let i = 0; i < files.length; i++) {
            const uploadDetails = fileUploadUrls.find(f => f.name === files[i].path?.split('\\')?.pop()?.split('/')?.pop());
            if (uploadDetails) {
                const url = uploadDetails?.uri?.url;
                const formData = new FormData();
                Object.keys(uploadDetails?.uri?.fields).forEach(key => {
                    formData.append(key, uploadDetails?.uri?.fields[key]);
                });
                formData.append("file", files[i]);
                const res = await BatchFilesClient.createBatchFile(url, formData);
                if (!res?.success) {
                    setMessage({type: "error", content: `Error uploading file - ${files[i]['path']}. Try again`})
                }
                setUploadCount(i + 1);
                if (i === files.length - 1) {
                    setMessage({
                        type: "info",
                        content: "Files uploaded successfully. Placing a request to process the batch"
                    })
                    BatchClient.processBatch(batchId, {id: batchId}).then((resp) => {
                        if (resp.success) {
                            setMessage({type: "success", content: "Batch successfully queued for processing"})
                            handleCancel();
                        }
                    }).catch((e) => {
                        setMessage({
                            type: "error",
                            content: Array.isArray(e.message) ? e.message?.join(", ") : "Something went wrong. Try again"
                        });
                        handleCancel();
                    })
                }
            }
        }
    }

    const selectedFilesList = files.map(file => (
        <FileList key={file.path} file={file} onClick={removeFile(file)}/>
    ))

    const incomePeriodOptions = [
        {
            label: '12 Month Statements',
            value: 'MONTHS_12'
        },
        {
            label: '24 Month Statements',
            value: 'MONTHS_24'
        },
    ];

    return (
        <>
            <div className={classes.root}>
                <Typography style={{fontSize: '1.2em'}} component={"div"} variant={"subtitle2"} gutterBottom>
                    Create Batch
                </Typography>
                <div>
                    <TextField
                        style={{width: '300px', marginTop: '16px'}}
                        name={"name"}
                        label={"Batch Name"}
                        placeholder={"eg: Q1-ABC Corp"}
                        value={batchName}
                        required
                        helperText={error.batchName}
                        error={!!error.batchName}
                        color={"primary"}
                        InputLabelProps={{shrink: true, style: {fontWeight: 'bold'}}}
                        onChange={(e) => setBatchName(e.target.value?.replace(/[^\w\s]/gi, ''))}
                    />
                    <br/>
                    {tenant === 'ANGELOAK' && <div style={{paddingTop: '16px'}}>
                        <TextField
                            color="primary"
                            margin="dense"
                            defaultValue={"MONTHS_24"}
                            select
                            variant="standard"
                            value={incomePeriod}
                            onChange={(e) => {
                                setIncomePeriod(e.target.value);
                            }}
                        >
                            {incomePeriodOptions?.map((ip, i) => (
                                <MenuItem key={ip.label} value={ip.value}>
                                    {ip.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>}
                    <div style={{padding: '20px 0'}}>
                        <Typography variant="subtitle2" color="textSecondary" gutterBottom
                                    style={{marginBottom: '8px'}}>
                            Select Statements for this batch
                        </Typography>
                        <>
                            <div className={"corner-aligned clickable"} onClick={() => setShowFileList(!showFileList)}>
                                <Typography component={'div'} variant={"subtitle2"} gutterBottom>Selected
                                    Statements&nbsp;
                                    <Typography component={"span"} variant={"subtitle2"}
                                                color={"primary"}>({files.length})</Typography>
                                </Typography>
                                {showFileList ?
                                    <Typography color={"textPrimary"} variant={"subtitle1"}
                                                className={"center"}>Close&nbsp;<ArrowUpIcon/>
                                    </Typography> :
                                    <Badge anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                           color="secondary" variant="dot" invisible={files?.length < 1}>
                                        <Typography color={"primary"} variant={"subtitle1"} className={"center"}>
                                            View Files
                                            <ArrowDownIcon/>
                                        </Typography>
                                    </Badge>
                                }
                            </div>
                            <Collapse in={showFileList}>
                                <List dense className={classes.list}>
                                    {selectedFilesList}
                                </List>
                            </Collapse>
                        </>
                        <div {...getRootProps({className: 'dropzone'})}>
                            <input {...getInputProps()} />
                            {!isDragActive ? <div>
                                <UploadIcon fontSize={"large"} color={"primary"}/>
                                <Typography color="textSecondary" variant={"subtitle1"}>
                                    Drag and drop Statements here to upload, or click to select Statements<br/>
                                </Typography>
                            </div> : <div>
                                <Typography variant={"subtitle2"}>
                                    Drop Statements here...
                                </Typography>
                            </div>}
                        </div>
                        <div className="negative">
                            <Typography variant={"subtitle1"}>{error.fileCount}</Typography>
                        </div>
                        {fileLimitWarning && <div>
                            <Typography className="warning" variant={"subtitle1"}>
                                Chosen Income period and No. of files selected mismatch
                            </Typography>
                        </div>}
                    </div>
                </div>
                <div className={`${classes.actionButtons}`}>
                    <Typography display="inline" variant="subtitle1" style={{
                        textDecoration: 'underline',
                        cursor: 'pointer'
                    }}
                                gutterBottom onClick={() => setShowBankList(true)}>
                        View Available Banks
                    </Typography>
                    <div>
                        <Button disabled={uploadingFile} onClick={handleCancel}>Cancel</Button>
                        <Button disabled={uploadingFile || !props.allowCreate}
                                variant={files?.length > 0 ? "contained" : "outlined"} color={"primary"}
                                startIcon={uploadingFile && <CircularProgress size={15}/>} onClick={handleSubmit}>
                            {uploadingFile ? `${uploadCount}/${files.length} file uploaded` : "Create"}</Button>
                    </div>
                </div>
                {!props.allowCreate && <div style={{textAlign: 'center', padding: '15px'}}>
                    <Typography variant={"subtitle1"} color={"textSecondary"}>Max count of Batches on Free trial
                        exceeded (3)</Typography>
                </div>}
                <ProgressDialog message={message} type={message?.content} open={uploadingFile && files.length > 0}
                                onClose={() => null}
                                total={files.length}
                                completed={uploadCount}/>
                <Backdrop invisible={true} style={{zIndex: 99999}} open={uploadingFile}/>
            </div>
            <EnabledBanksListDialog open={showBankList} onClose={() => setShowBankList(false)}/>
        </>
    );
}

export default CreateBatch
