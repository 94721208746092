import React, {useContext, useEffect, useState} from 'react';
import Typography from "@material-ui/core/Typography";
import tableIcons, {tableOptions} from "../../components/universal/table_attributes";
import MaterialTable from "material-table";
import CheckIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Cancel";
import DownloadIcon from "@material-ui/icons/GetApp";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import BatchFilesClient from "../../lib/services/api/files";
import {ToastMessageContext} from "../../lib/contexts/message_context";
import moment from "moment";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {sleepFor, unique} from "../../lib/utils/common_utils";
import Tooltip from "@material-ui/core/Tooltip";
import {EventsContext} from "../../lib/contexts/events_context";
import {FullWidthRightDrawer} from "../../components/universal/halfwide_right_drawer";
import UploadManualOutputFile from "./upload_output_file";
import FileStatus from "../Batches/file_status_info";
import {
    ConfirmCancelDialog,
    FileStatusUpdate,
    ManualQuickResponseDialog
} from "../../components/universal/confirm_dialog";
import Alert from "@material-ui/lab/Alert";
import SLACountDown from "./sla_countdown";
import BatchFileManualErrorWarning from "../Batches/batch_file_manual_error_warnings";


const useStyles = makeStyles(theme => ({
    totalFileStatusText: {
        fontSize: '0.8em',
        display: 'inline-flex',
        alignItems: 'center',
        paddingLeft: '20px'
    },
    tableWrapper: {
        padding: '20px',
        [theme.breakpoints.down('md')]: {
            padding: '25px 5px'
        }
    },
    isMobile: {
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    },
    mobileBtnPadding: {
        [theme.breakpoints.down('md')]: {
            paddingLeft: '3px',
            paddingRight: '3px',
            minWidth: '75px !important',
            marginRight: '20px'
        }
    }
}))

function AdminBatchDetails(props) {

    const classes = useStyles();

    const {open, handleClose, currentSelection} = props;
    const [batchDetail, setBatchDetail] = useState({});
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [files, setFiles] = useState([]);
    const [bankName, setBankName] = useState('...');
    const [status, setStatus] = useState("");
    const ToastMessage = useContext(ToastMessageContext);
    const user = JSON.parse(sessionStorage.getItem('user'));
    const [showConfirm, setShowConfirm] = useState(false);
    const [showFileUpdate, setShowFileUpdate] = useState(false);
    const [showQuickResponse, setShowQuickResponse] = useState(false);
    const [fileOnUpdate, setFileOnUpdate] = useState();
    const Event = useContext(EventsContext);

    useEffect(() => {
        if (currentSelection.id) {
            setBankName("-");
            setLoading(true);
            BatchFilesClient.getBatchDetails(currentSelection.id).then((res) => {
                setBatchDetail(res?.data?.batch || {});
                if (res?.data?.files?.length > 0) {
                    setFiles(res?.data?.files);
                    setStatus(res?.data?.batch?.status);
                    setBankName(unique(res?.data?.files?.map(f => f?.bank_name))?.filter(f => (f !== 'N/A'))?.join(", "));
                }
            }).catch((e) => {
                ToastMessage.showError("Something went wrong while fetching files");
            }).finally(() => {
                setLoading(false);
            })
        } else {
            setFiles([]);
        }
    }, [currentSelection, refresh]);


    const downloadJsonFile = (fileId) => {
        ToastMessage.showSuccess("File(s) download initiated.");
        BatchFilesClient.downloadJsonFile(currentSelection.id, fileId).then((res) => {
            if (res.success) {
                openDownloadWindow(res).then((res) => {
                    if (res) {
                        ToastMessage.showSuccess("File(s) downloaded successfully");
                    }
                });
            }
        }).catch((e) => {
            ToastMessage.showError("Something went wrong. Try again!");
        })
    }

    const downloadJsonFileBulk = async () => {
        const fileIds = files.map(file => file.id);
        if (user.role !== 'USER') {
            for (let i = 0; i < fileIds.length; i++) {
                await downloadJsonFile(fileIds[i])
                await sleepFor(2000);
            }
        }
    }

    const MITableOptions = {
        ...tableOptions,
        showTitle: true,
        paging: true,
        filter: true,
        selectableRowsOnClick: true,
        pageSize: 30,
        pageSizeOptions: [10, 20, 30, 40],
        searchFieldStyle: {
            width: '450px'
        },
    }


    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        handleClose(open);
    };

    const downloadBatchResultFile = (batchId, params) => {
        ToastMessage.showSuccess("File(s) download initiated.");
        BatchFilesClient.downloadBatchResultFile(batchId, params).then((res) => {
            if (res.success) {
                openDownloadWindow(res).then((res) => {
                    if (res) {
                        ToastMessage.showSuccess("File(s) downloaded successfully");
                    }
                });
            }
        }).catch((e) => {
            ToastMessage.showError("Something went wrong. Try again!");
        })
    }

    const openDownloadWindow = async (res) => {
        for (let i = 0; i < res.data.length; i++) {
            try {
                window.location.href = res?.data?.[i]?.uri;
            } catch (e) {
                continue;
            }
            await sleepFor(2000);
        }
        return true;
    }

    const downloadFilesBulk = async () => {
        const fileIds = files.map(file => file.id);
        for (let i = 0; i < fileIds.length; i++) {
            await downloadUploadedFile(fileIds[i])
            await sleepFor(2000);
        }
        return true;
    }

    const downloadUploadedFile = (fileId) => {
        ToastMessage.showSuccess("File download initiated");
        Event.recordEvent("Batch File", "Download", "Download Uploaded File", fileId);
        BatchFilesClient.downloadBatchFile(currentSelection?.id, fileId).then((res) => {
            if (res.success) {
                window.location.href = res?.data?.uri;
            }
        }).catch((e) => {
            ToastMessage.showError("Something went wrong. Try again later");
        })
    }

    const cancelManualRequest = (val) => {
        const formData = {
            'internal_admin_comments': val
        }
        BatchFilesClient.cancelManualRequest(currentSelection.id, formData).then((res) => {
            setShowConfirm(false);
        }).catch((e) => {
            ToastMessage.showError("Something went wrong. Try again later");
        }).finally((e) => {
            setShowConfirm(false);
        });
    }

    const updateFileStatus = (formData) => {
        BatchFilesClient.updateFileStatus(currentSelection?.id, fileOnUpdate?.id, formData).then((res) => {
            setShowFileUpdate(false);
            setRefresh(!refresh);
        }).catch((e) => {
            ToastMessage.showError("Something went wrong. Try again later");
        }).finally((e) => {
            setShowFileUpdate(false);
        });
    }

    const submitQuickResponse = (formData) => {
        BatchFilesClient.submitManualQuickResponse(currentSelection?.id,formData).then((res) => {
            setShowQuickResponse(false);
            setRefresh(!refresh);
        }).catch((e) => {
            ToastMessage.showError("Something went wrong. Try again later");
        }).finally((e) => {
            setShowQuickResponse(false);
        });
    }

    const handleFileUpdate = (fileDetail) => {
        setFileOnUpdate(fileDetail);
        setShowFileUpdate(true);
    }

    const columns = [
        {
            title: 'Statement',
            field: 'name',
            headerStyle: {
                textAlign: 'left'
            },
            cellStyle: {
                textAlign: 'left',
                paddingLeft: '5px !important'
            },
            render: rowData => {
                return <Tooltip title={`Download : ${rowData.name}`} arrow>
                    <Typography className={"clickable"} variant={"subtitle1"}
                                noWrap
                                style={{
                                    fontSize: '12px',
                                    width: '500px',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflowWrap: 'break-word'
                                }}
                                onClick={() => downloadUploadedFile(rowData.id)}>
                        {rowData.name}
                    </Typography>
                </Tooltip>
            }
        },
        {
            title: 'Dep. Amount',
            field: 'total_additions',
            headerStyle: {
                textAlign: 'right'
            },
            width: '250px',
            cellStyle: {
                textAlign: 'right',
                paddingLeft: '10px !important'
            },
            render: rowData => {
                return (<>
                    {isNaN(+rowData?.total_additions) ? "N/A" :
                        <strong>$ {parseFloat(rowData.total_additions)?.toFixed(2)}</strong>}
                </>)
            }
        },
        {
            title: 'Bank Name',
            field: 'bank_name',
            width: '200px',
            headerStyle: {
                textAlign: 'center'
            },
            emptyValue: "N/A",
            cellStyle: {
                textAlign: 'center',
                paddingLeft: '10px !important',
                textTransform: 'capitalize'
            },
            render: rowData => <span>{rowData.bank_name?.replace(/_/g, ' ')}</span>
        },
        {
            title: 'Status',
            field: 'status',
            width: '200px',
            headerStyle: {
                textAlign: 'left'
            },
            render: rowData => {
                return (
                    <div className={"clickable"} onClick={() => handleFileUpdate(rowData)}>
                        <FileStatus data={rowData}/>
                    </div>
                )
            }
        },
        {
            title: 'Action',
            field: 'Download',
            width: '200px',
            headerStyle: {
                textAlign: 'center'
            },
            render: rowData =>
                <Button fullWidth variant={"outlined"} size={"small"}
                        onClick={(e) => {
                            e.stopPropagation();
                            downloadJsonFile(rowData.id)
                        }}
                        color={"primary"} startIcon={<DownloadIcon/>}>Get JSON File</Button>
        },


    ];

    return (
        <FullWidthRightDrawer open={open} onClose={toggleDrawer(false)} transitionDuration={600} elevation={0}
                              anchor={"right"}>
            <div style={{padding: '3%'}}>
                <div style={{textAlign: 'right', marginBottom: '-30px'}}>
                    <Button size={"small"} onClick={() => handleClose(false)}>Close</Button>
                </div>
                <Typography variant={"subtitle2"} style={{fontSize: '1.2em', paddingLeft: '20px'}}
                            className={"capitalize"}>{currentSelection.name}</Typography>
                <Grid container style={{paddingLeft: '20px'}} alignItems={"flex-start"} justify={"flex-start"}>
                    <Grid item xs={4}>
                        <Typography variant={"subtitle1"} color={"textSecondary"} style={{fontSize: '0.9em'}}
                                    gutterBottom>
                            Created at: {moment(currentSelection.created_at).format("YYYY-MM-DD HH:mm A")}</Typography>
                        <Typography variant={"subtitle1"} color={"textSecondary"} style={{fontSize: '0.9em'}}
                                    gutterBottom>
                            Bank : <Typography component={"span"} color={"primary"} variant={"subtitle2"}
                                               display={"inline"}>{bankName?.replace(/_/g, ' ') || "-"}</Typography>
                        </Typography>
                        <Typography variant={"subtitle1"} color={"textSecondary"} style={{fontSize: '0.9em'}}
                                    gutterBottom>
                            Batch Id: {currentSelection.id}
                        </Typography>
                        <Typography variant={"subtitle1"} color={"textSecondary"} style={{fontSize: '0.9em'}}
                                    gutterBottom>
                            Organisation: {currentSelection.tenant_name}
                        </Typography>
                        {batchDetail?.manual_eta?.length > 5 && <div className={"center"} style={{width:'50vw', justifyContent:"space-between"}}><Typography variant={"subtitle1"} color={"error"} style={{fontSize: '0.9em'}}
                                    gutterBottom>
                            Manual ETA (UTC): <SLACountDown status={batchDetail?.status} sla={moment(batchDetail.manual_eta)}/>
                        </Typography>
                        <Typography variant={"subtitle1"} color={"error"} style={{fontSize: '0.9em'}}
                                    gutterBottom>
                            Complexity: {batchDetail.manual_reason}
                        </Typography>
                        <Typography variant={"subtitle1"} color={"error"} style={{fontSize: '0.9em'}}
                                    gutterBottom>
                            Scan Quality: {batchDetail.scan_quality}
                        </Typography>
                        </div>}
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant={"subtitle2"} gutterBottom> Total processed
                            : {currentSelection.uploaded_file_count}</Typography>
                        <Typography className={classes.fileStatusText}
                                    gutterBottom><CheckIcon className={"positive"}/>&nbsp;
                            <span>{currentSelection.valid_files || 0} Processed</span></Typography>
                        <Typography className={classes.fileStatusText} gutterBottom><ErrorIcon
                            className={"negative"}/>&nbsp;
                            <span> {currentSelection.invalid_files || 0} Rejected</span></Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <UploadManualOutputFile batchId={currentSelection.id} setRefresh={setRefresh} cancelManualRequest={cancelManualRequest}/>
                    </Grid>
                    {user.role !== "USER" && (<>
                            <Grid item xs={3}>
                                <Button startIcon={<DownloadIcon/>} style={{marginTop: '16px'}}
                                        onClick={downloadFilesBulk} color={"primary"}
                                        size="small"
                                        variant="contained">PDF</Button>
                                <Button startIcon={<DownloadIcon/>} style={{marginTop: '16px', marginLeft: '16px'}}
                                        onClick={downloadJsonFileBulk} color={"primary"}
                                        size="small"
                                        variant="contained">JSON</Button>
                            </Grid>
                        </>
                    )}
                    <Grid item xs={9}>
                        {(!batchDetail?.manual_eta || batchDetail?.manual_eta?.length < 5) && <Button color="primary" style={{marginTop: '16px'}} size="small" variant="outlined"
                                onClick={() => setShowQuickResponse(true)}>Submit Quick Response</Button>}
                    </Grid>
                    <Grid item md={12} style={{marginTop:'10px'}}>
                        <Alert severity="info" variant={"outlined"} style={{marginBottom: '12px'}}>{currentSelection?.additional_info?.description}</Alert>
                    </Grid>
                    <Grid item md={12}>
                        <BatchFileManualErrorWarning errorFlags={currentSelection?.error_flags} />
                    </Grid>
                </Grid>

                <div className={classes.tableWrapper} style={{paddingTop:0}}>
                    <MaterialTable
                        title={<div>
                            <Button className={classes.mobileBtnPadding} variant={"outlined"}
                                       onClick={(e) => {
                                           e.stopPropagation();
                                           downloadBatchResultFile(currentSelection.id);
                                       }}
                                       size={"small"}
                                       color={"primary"} startIcon={<DownloadIcon/>}><span
                                className={classes.isMobile}>Download</span>.xlsx</Button>&nbsp;&nbsp;
                            <Button className={classes.mobileBtnPadding} variant={"outlined"}
                                       onClick={(e) => {
                                           e.stopPropagation();
                                           downloadBatchResultFile(currentSelection.id, {output: "UPDATE"});
                                       }}
                                       size={"small"}
                                       color={"primary"} startIcon={<DownloadIcon/>}><span
                                className={classes.isMobile}>Manual Update</span>.xlsx</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                        </div>}
                        style={{boxShadow: 'unset'}}
                        columns={columns}
                        data={files || []}
                        isLoading={loading}
                        options={MITableOptions}
                        icons={tableIcons}
                        localization={{
                            body: {
                                emptyDataSourceMessage: 'No Statements found',
                            }
                        }}
                    />
                </div>
            </div>
            <ConfirmCancelDialog open={showConfirm} onConfirm={(val) => cancelManualRequest(val)}
                                 onClose={() => setShowConfirm(false)}/>
            <FileStatusUpdate open={showFileUpdate} onConfirm={(val) => updateFileStatus(val)}
                              onClose={() => setShowFileUpdate(false)}/>
            <ManualQuickResponseDialog open={showQuickResponse} onConfirm={(val) => submitQuickResponse(val)}
                                       onClose={() => setShowQuickResponse(false)}>

            </ManualQuickResponseDialog>
        </FullWidthRightDrawer>
    );
}

export default AdminBatchDetails;
