/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useEffect, useRef, useState} from 'react';
import tableIcons, {tableOptions} from "../../../components/universal/table_attributes";
import Typography from "@material-ui/core/Typography";
import MaterialTable from "material-table";
import {makeStyles} from "@material-ui/core/styles";
import {getCurrencyFormat} from "../../../lib/utils/common_utils";
import AnalyticsClient from "../../../lib/services/api/analytics";
import TransactionFilters from "./filter";
import {TransactionFilterContext} from "../../../lib/contexts/transaction_filter_context";
import {ToastMessageContext} from "../../../lib/contexts/message_context";
import Button from "@material-ui/core/Button";
import ExcludeIcon from "@material-ui/icons/Backspace";
import RenameCategory from "../Overall/rename_category";
import moment from "moment";
import Chip from "@material-ui/core/Chip";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import RepeatIcon from "@material-ui/icons/Repeat";
import scrollToTargetAdjusted from "../../../lib/utils/scroll";

export const useStyles = makeStyles(theme => ({
    root: {
        borderRadius: '10px',
        boxShadow: 'inset 0 0 2px 0px #f5f5f5, 0 0 2px 1px #f5f5f5'
    },
    tableWrapper: {
        marginBottom: '25px',
        padding: '0 12px'
    },
    selectOptionsWrapper: {
        padding: '16px',
        color: '#fff',
        fontWeight: 'bold',
        width: '97%',
        background: theme.palette.primary.dark
    },
    actionButton: {
        backgroundColor: 'rgba(255,255,255,0.25)',
        padding: '5px 16px',
        marginRight: '10px',
        '&:hover': {
            backgroundColor: 'rgba(255,255,255,0.25)'
        }
    }
}))

function FileTransactionListDetails(props) {
    const tableRef = useRef("txn-list");
    const classes = useStyles();
    const {
        categoryData,
        batchId,
        refresh,
        setRefresh,
        allFilesProcessed,
        batchFiles,
        transactions,
        loading,
        txnTypeContext
    } = props;
    const [updating, setUpdating] = useState(false);
    const [showRename, setShowRename] = useState(false);
    const TxnData = useContext(TransactionFilterContext);
    const Toast = useContext(ToastMessageContext);
    const tenant = JSON.parse(sessionStorage.getItem('user'))?.["tenant_name"]?.toUpperCase();

    useEffect(() => {
        if(txnTypeContext === "expense"){
            TxnData.setTxnList(transactions?.withdrawals || []);
            TxnData.setFilters({...TxnData.filters, type:2});
        }
        else {
            TxnData.setTxnList(transactions?.deposits || []);
            TxnData.setFilters({...TxnData.filters, type:1});
        }

    }, [transactions, txnTypeContext])
    let categories = "";
    if(TxnData?.filters?.type === 1){
        categories = categoryData?.all_categories;
    }
    else {
        categories = categoryData?.wd_all_categories;
    }


    const flagTransactions = (transactions, status) => {
        setUpdating(true);
        const flagged_txn_ids = transactions.map(txn => {
            return ({
                "index": txn?.index,
                "txn_id": txn?.hash_key || txn?.txn_id,
                "status": status,
                "type": TxnData?.filters?.type === 1 ? "deposit": "withdrawal"
            })
        })
        const formData = {
            "flag_index": flagged_txn_ids
        }
        AnalyticsClient.updateTransactions(batchId, formData).then((res) => {
            if (res?.success) {
                setRefresh(!refresh);
                Toast.showSuccess("Transactions flag updated successfully");
            }
        }).catch((e) => {
            Toast.showError(e.message || "Something went wrong. Try again");
        }).finally(() => {
            setUpdating(false);
        })
    }

    const handleRenameClose = (r) => {
        if(!r){
            setRefresh(true);
        }
        setShowRename(false);
    }

    let MITableOptions = {
        ...tableOptions,
        padding: "dense",
        showTitle: false,
        paging: true,
        filtering: false,
        searchable: true,
        selection: true,
        searchFieldStyle: {
            width: '300px'
        }
    }

    if (["ANGELOAK","ARCHOME"].includes(tenant)) {
        MITableOptions.pageSize = 100
    }

    if(["NEWFI"].includes(tenant)) {
        MITableOptions.paginationPosition = "both"
    }

    const monthName = {
        1: "Jan",
        2: "Feb",
        3: "Mar",
        4: "Apr",
        5: "May",
        6: "Jun",
        7: "Jul",
        8: "Aug",
        9: "Sep",
        10: "Oct",
        11: "Nov",
        12: "Dec"
    }

    const flagReason = {
        "Category flag": "Excluded Category",
        "Transaction flag": "Excluded Transaction",
        "User flag": "Manually Excluded",
        "User Included": "Manually Included",
        "Account Type flag": "Account Type Excluded"
    }

    const applyFilter = (type) => {
        if(TxnData?.filters?.flag === type) {
            let applyFil = TxnData?.setFilters({...TxnData.filters, flag: 0});
        }
        else {
            let applyFil = TxnData?.setFilters({...TxnData.filters, flag: type});
        }
    }

    const columns = [
        {
            title: 'Account',
            field: 'account_type',
            width: '8%',
            render: rowData => <Typography component={"span"} variant="body1">
                {rowData?.account_type}
                <><br/>
                    (<b style={{fontSize:'10px'}}>{rowData?.captured_account_number ? rowData?.captured_account_number : rowData?.account_number || "NA"}</b>)
                </>
            </Typography>

        },
        {
            title: 'Stmt.Period',
            field: 'month',
            headerStyle: {
                textAlign: 'left'
            },
            cellStyle: {
                textAlign: 'left',
            },
            width: '5%',
            defaultSort: 'desc',
            customSort: (a, b) => moment(`${monthName[+a['month']]},${a['year']}`, "MMM,YYYY") - moment(`${monthName[+b['month']]},${b['year']}`, "MMM,YYYY"),
            render: rowData => <Typography component={"span"} variant="body1">
                {monthName[+rowData?.month]}, {rowData?.year}
            </Typography>
        },
        {
            title: 'Txn.Date',
            field: 'date',
            sorting: false,
            headerStyle: {
                textAlign: 'left'
            },
            cellStyle: {
                textAlign: 'left',
            },
            width: '10%',
            render: rowData => <Typography component={"span"} variant="body1">{rowData?.date}</Typography>
        },
        {
            title: 'Description',
            field: 'description',
            width: '45%',
            render: rowData =>
                <Typography component={"span"} variant="body1">
                    {rowData?.description}
                    {rowData?.comingle_txn && <div className={"clickable"}><Chip className={"clickable"} color={"default"} size={"small"} icon={<SwapHorizIcon/>} label={"Comingling"} onDoubleClick={() => applyFilter(5)}/></div>}
                    {rowData?.reversal_txn && <div className={"clickable"}><Chip className={"clickable"} color={"default"} size={"small"} icon={<RepeatIcon/>} label={"Reversal"} onDoubleClick={() => applyFilter(6)}/></div>}
                </Typography>
        },
        {
            title: 'Category',
            field: 'category',
            width: '20%',
            render: rowData => <Typography component={"span"} variant="body1">{rowData?.category}</Typography>

        },
        {
            title: 'Amount',
            field: 'amount',
            headerStyle: {
                textAlign: 'center'
            },
            cellStyle: {
                textAlign: 'right'
            },
            width: '10%',
            render: rowData => {
                return (
                    <div style={{display: 'flex', justifyContent:'flex-end'}} className={"center clickable"}>
                        <Typography
                            style={{marginRight: '32px', letterSpacing: '1px'}}
                            component={"div"}
                            variant={"subtitle2"}
                            className={(rowData.amount < 0 || rowData.flag) ? "negative" : (rowData.amount === 0 ? "warning" : "positive")}>
                            {getCurrencyFormat(rowData.amount, 2)}
                        </Typography>
                    </div>
                )
            }
        },
        {
            title: 'Sys. Notes',
            field: 'flag_reason',
            width: '20%',
            headerStyle: {
                textAlign: 'left',
                paddingLeft: '0px'
            },
            cellStyle: {
                textAlign: 'left',
                paddingLeft: '0px',
                paddingRight: '0px'
            },
            render: rowData => (
                <Typography component="div" variant="body1"
                            style={{
                                display: 'flex',
                                padding: '8px 0'
                            }}>
                    {rowData?.flag ? (<ExcludeIcon htmlColor={"red"} style={{marginRight: '3px'}}/>) : null}
                    {!["ANGELOAK"].includes(tenant) && rowData?.other_flag?.length > 1 && "Large Deposit, "}
                    {["ANGELOAK"].includes(tenant) && rowData?.atypical?.length > 1 && "Atypical Deposit, "}
                    &nbsp;{flagReason[rowData?.flag_reason] || (!rowData?.flag ? (TxnData?.filters?.type === 1 ?"Income" : "Expense") :(TxnData?.filters?.type === 1 ? "Excluded" : "Non Expense"))}
                </Typography>)
        },
    ];

    return (<div className={classes.root}>
        <TransactionFilters categoryData={categoryData} batchFiles={batchFiles}/>
        <div className={classes.tableWrapper} id={"txnList"}>
            <MaterialTable
                style={{boxShadow: 'unset'}}
                columns={columns}
                isLoading={loading || updating}
                data={TxnData?.filteredTransaction || []}
                options={MITableOptions}
                onChangePage={() => {
                    scrollToTargetAdjusted('txnList',150, "auto")
                }}
                icons={tableIcons}
                localization={{
                    body: {
                        emptyDataSourceMessage: 'No Transactions found',
                    }
                }}
                components={{
                    Toolbar: ({selectedRows}) => (
                        selectedRows?.length > 0 && (<div className={classes.selectOptionsWrapper}>
                                <div className={"corner-aligned"}>
                                    <Typography variant="subtitle2" color="inherit">
                                        (&nbsp;{selectedRows?.length}&nbsp;) Transactions selected.
                                    </Typography>
                                    <div>
                                        <Button focusRipple size="small" variant="text" color="inherit"
                                                className={classes.actionButton} onClick={() => setShowRename(true)}>
                                            Reclassify Transactions
                                        </Button>
                                        <Button focusRipple size="small" variant="text" color="inherit"
                                                className={classes.actionButton}
                                                onClick={() => flagTransactions(selectedRows, false)}>
                                            Include Selected
                                        </Button>
                                        <Button focusRipple size="small" variant="text" color="inherit"
                                                className={classes.actionButton}
                                                onClick={() => flagTransactions(selectedRows, true)}>
                                            Exclude Selected
                                        </Button>
                                    </div>
                                </div>
                                <RenameCategory
                                    txnCategories={categories}
                                    selectedTransactions={selectedRows?.map(txn => txn?.txn_id || txn?.index)}
                                    showRenameDialog={showRename}
                                    handleClose={(type) => handleRenameClose(type)}
                                    batchId={batchId}
                                    loading={loading}
                                    txnTypeContext={txnTypeContext}
                                />
                            </div>
                        )
                    )
                }}
            />
        </div>
    </div>)
}

export default FileTransactionListDetails;
