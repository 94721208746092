import React, {useContext, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AccountCircle from '@material-ui/icons/AccountCircle';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import PasswordIcon from '@material-ui/icons/VpnKey';
import HelpIcon from '@material-ui/icons/Help';
import NotificationIcon from '@material-ui/icons/NotificationsActive';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import {withRouter} from "react-router-dom";
import ChangePasswordDialog from "../../views/Auth/change_password_dialog";
import IconButton from "@material-ui/core/IconButton";
import {Badge} from "@material-ui/core";
import {EventsContext} from "../../lib/contexts/events_context";
import {ToastMessageContext} from "../../lib/contexts/message_context";
import SearchBatch from "./search_batch";
import AuthClient from "../../lib/services/api/auth";
import Chip from "@material-ui/core/Chip";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar: {
        boxShadow: '0 0 3px 0 rgba(0,0,0,0.22)',
        background: "#00874f"
    },
    toolbar: {
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            flexDirection: 'column',
            padding: '15px 0 25px 0',
            height: '75px'
        }
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    temporaryMenu: {
        flexGrow: 1,
    },
    menu: {
        color: '#fff',
        borderRadius: 0,
        marginRight: '15px',
        '&:hover': {
            borderBottom: `3px solid #fff`,
        }
    },
    menuSelected: {
        borderRadius: 0,
        borderBottom: `3px solid #fff`,
    },
    menuItem: {
        width: '200px',
        padding: '12px 10px'
    },
    partner: {
        fontSize: '16px'
    }
}));

function ConfirmDialog(props) {
    return <Dialog
        maxWidth="xs"
        fullWidth
        open={props.open}
        onClose={props.onClose}
    >
        <DialogContent style={{padding: "24px 32px"}}>
            <Typography variant={"subtitle2"} style={{fontSize: "16px"}} gutterBottom>Confirm
                Logout?</Typography>
            <Typography variant={"subtitle1"}>
                You are about to logout.
                Any unsaved data will be cleared.
                You'll have to login again to use the application.
            </Typography>
        </DialogContent>
        <DialogActions className={"center"} style={{padding: "16px"}}>
            <Button disableElevation onClick={props.onClose} variant={"contained"}>
                Cancel
            </Button>
            <Button onClick={props.onClick} color="primary" variant={"contained"}>
                Logout
            </Button>
        </DialogActions>
    </Dialog>;
}

function Header(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [logoutConfirm, setLogoutConfirm] = useState(false);
    const [changePassword, setChangePassword] = useState(false);
    const open = Boolean(anchorEl);

    const Event = useContext(EventsContext);
    const Toast = useContext(ToastMessageContext);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const currentPath = props.location.pathname;
    const isCurrentSection = (listValue) => {
        return (currentPath.indexOf(listValue) !== -1);
    }

    const isAuth = currentPath.indexOf('auth') >= 0;

    if (isAuth) {
        return null;
    }
    const currentUser = JSON.parse(sessionStorage.getItem('user')) || {};
    return (
        <>
            <div className={classes.root}>
                <AppBar className={classes.appBar} position="fixed" color={"transparent"} elevation={1}>
                    <Toolbar className={classes.toolbar}>
                        <img className={'clickable'} onClick={() => props.history.push('/')}
                             src={'/prudent_logo_white.svg'} alt={"Prudent logo"} height={'30x'}/>
                        <Chip style={{marginLeft: '10px', color:'#fff', backgroundColor:'red'}} label="Internal"/>
                        <div className={classes.temporaryMenu}/>
                        <div className={classes.temporaryMenu}/>
                        <SearchBatch {...props}/>
                        <div>
                            {["SUPERADMIN", "MANAGER"].includes(currentUser?.role) && <Button
                                className={`${classes.menu} ${isCurrentSection('dashboard') ? classes.menuSelected : ''}`}
                                component={'div'}
                                onClick={() => props.history.push('/dashboard')}
                            >
                                Manage Users
                            </Button>}
                            <Button
                                className={`${classes.menu} ${currentPath === '/' ? classes.menuSelected : ''}`}
                                component={'div'}
                                onClick={() => props.history.push('/')}
                            >
                                My Batches
                            </Button>
                            <IconButton
                                className={`${classes.menu} ${isCurrentSection('profile') ? classes.menuSelected : ''}`}
                                onClick={handleMenu}
                            >
                                <AccountCircle/>
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                open={open}
                                onClose={handleClose}
                                getContentAnchorEl={null}
                            >
                                <MenuItem className={classes.menuItem}
                                          onClick={() => {
                                              setChangePassword(true);
                                          }}><PasswordIcon
                                    color={"primary"}/> &nbsp;Change Password</MenuItem>
                                <MenuItem className={classes.menuItem}
                                          onClick={() => {
                                              setLogoutConfirm(true);
                                              handleClose()
                                          }}><LogoutIcon
                                    color={"primary"}/> &nbsp;Logout</MenuItem>
                            </Menu>
                        </div>
                    </Toolbar>
                </AppBar>
            </div>
            <ConfirmDialog open={logoutConfirm} onClose={() => setLogoutConfirm(false)}
                           onClick={() => AuthClient.logout()}/>
            <ChangePasswordDialog open={changePassword} onClose={() => setChangePassword(false)}/>
        </>
    );
}

export default withRouter(Header);
