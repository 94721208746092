import React, {useContext} from "react";
import {TransactionFilterContext} from "../../../../lib/contexts/transaction_filter_context";
import Typography from "@material-ui/core/Typography";
import {TextField} from "@material-ui/core";

function AmountRange(props) {
    const TxnData = useContext(TransactionFilterContext);

    const handleAmountChange = (amount, type = 'min') => {
        if (type === 'min') {
            TxnData.setFilters({...TxnData.filters, minAmount: amount});
        }
        if (type === 'max') {
            TxnData.setFilters({...TxnData.filters, maxAmount: amount});
        }
    }

    return (
        <div>
            <Typography variant="subtitle2" color="textSecondary">
                Amount Range:
            </Typography>
            <div className="corner-aligned">
                <TextField
                    variant="outlined"
                    size="small"
                    value={+TxnData?.filters?.minAmount > 0 && +TxnData?.filters?.minAmount}
                    style={{marginRight: '8px'}}
                    placeholder={"Min"}
                    type="number"
                    inputProps={{
                        min: 0,
                        step: 1
                    }}
                    onChange={(e) => handleAmountChange(e.target.value, 'min')}
                />
                <TextField
                    variant="outlined"
                    type="number"
                    size="small"
                    placeholder={"Max"}
                    value={+TxnData?.filters?.maxAmount > 0 &&  +TxnData?.filters?.maxAmount}
                    style={{marginLeft: '8px'}}
                    inputProps={{
                        min: 1,
                        step: 1
                    }}
                    onChange={(e) => handleAmountChange(e.target.value, 'max')}
                />
            </div>

        </div>
    )
}

export default AmountRange;